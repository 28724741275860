import { useState } from 'react';
import { Link } from 'react-router-dom';

import View from '@components/view/View';
import Alert from '@components/alert/Alert';
import RegisterForm from '@components/registerForm/RegisterForm';


export default function RegisterTrialAccount() {
	const [success, setSuccess] = useState(false);

	return (
		<View maxWidth={550} title="Register trial account">

			{success ? (
				<Alert variant="success" heading="Registration successful!">
					<p>We've sent a verification email to your account. Please check your inbox.</p>
					<p>Please note, an administrator must approve your trial account before you can access our services.</p>
				</Alert>
			) : (
				<>
					<RegisterForm onSuccess={() => setSuccess(true)} tier="trial" submitText="Register for a trial " />

					<p className="mt-5">
						Already have an account? <Link to="/login" className="inherit">Log in</Link>
					</p>
				</>
			)}
		</View>
	);
}
