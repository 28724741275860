import Card from "react-bootstrap/Card";

import AdminView from "@components/view/AdminView";
import AreaTransactionsList from "@components/area/AreaTransactionsList";
import AreaBalance from "@components/area/AreaBalance";
import StripeProducts from "@components/products/StripeProducts";
import ProductsFooter from '@components/products/ProductsFooter';

const AreaTransactions = () => {

	return (
		<AdminView title="Area">
			<Card className="d-inline-block mb-5" body>
				<Card.Text>
					<span className="text-muted">Your current area balance</span><br/>
					<strong className="h4"><AreaBalance /></strong>
				</Card.Text>
			</Card>

			<section className="mb-5">
				<h2>Buy additional area</h2>
				<p>Area is used for generating all assets for new projects.</p>

				<StripeProducts stripeLookupKeys={["area_small", "area_medium", "area_large"]} />
				<ProductsFooter />
			</section>

			<section>
				<h2>Latest area transactions</h2>
				<Card bg="none" body>
					<AreaTransactionsList />
				</Card>
			</section>

		</AdminView>
	);
}
export default AreaTransactions;
