// React imports
import { useEffect, useState, useRef, useCallback } from "react";

// Third-party library imports
import { Tooltip } from 'react-tooltip';
import ImageLayer from "ol/layer/Image";
import Static from "ol/source/ImageStatic";
import { Projection } from "ol/proj";
import { unByKey } from "ol/Observable";
import _ from "lodash";

import ButtonGroup from "react-bootstrap/ButtonGroup";
import { VscRecord } from "react-icons/vsc";
import { BiShapePolygon } from "react-icons/bi";
import { BsFillEraserFill } from "react-icons/bs";

// Contexts
import { useProject } from "@contexts/Project.context";
import { useToast } from "@contexts/Toast.context";

// Hooks
import useNotify from '@components/notifictions/notify';
import useKeyboardShortcut from "@hooks/useKeyboardShortcut.hook";

// Utils
import { deleteLayerByCustomId, setLayersVisibilityByCustomIds } from "@utils/map/helpers";
import { addMapLoadingSpinner, removeMapLoadingSpinner } from "@utils/map/map.helpers";
import { addDrawLayer, removeDrawLayer, drawEndInteracton, drawStartInteraction, deleteAnnotation } from "@utils/map/singleImage.draw";
import { getSingleImage } from "@utils/api";

// Components
import { AnnotationToolBar, KeyboardShortcut } from "@components/map/sharedStyles.component";
import UtilityButton from "./utilityButtons/UtilityButton";

// API
import { postTileData, initTraining } from "@api";

const customLayerId = 'annotatePhotosLayer';


export default function AnnotateSingleImages({layersToShow}) {

	const {
		mapObject,
		project,
		pickedTask,
		annotationType,
		annotationMode,
		annotationSidebarData,
		toolBarVisible,
		confirmModalContent,
		colorOptions,
		dispatch,
	} = useProject();

	const {gridFeatures: imagesInAreaOfInterest} = annotationMode;

	const { checkForJobs } = useNotify();
	const { addToast } = useToast();

	// States to show loading spinner and disable tools
	const [loading, setLoading] = useState(false);
	const [disableTools, setDisableTools] = useState(false);

	// State to keep track of the active image
    const [activeImageIndex, setActiveImageIndex] = useState(0);

	// Layers added to the map
	const [imageLayer, setImageLayer] = useState(null);
	const [drawLayer, setDrawLayer] = useState(null);

	// State to keep track of the drawing or erasing
	const [drawing, setDrawing] = useState(true);

	//Refs
	const isInitiated = useRef(false); // To prevent re-rendering
	const addedAnnotations = useRef([]); // To keep track of the annotations added to the map
	const mapListenerKeys = useRef(null); // To keep track of the listeners added to the map and be able to remove them
	const activeClassId = useRef(annotationSidebarData?.activeClassId ?? 0);
	const activeTileName = useRef(imagesInAreaOfInterest[0]?.properties?.tile_name ?? null);

	const DrawingIcon = () => {
		if(annotationType === "Circle"){
			return <VscRecord style={{ marginTop: "-4px", marginRight: "5px" }} />
		}
		return <BiShapePolygon style={{ marginTop: "-4px", marginRight: "5px" }} />;
	}

	// End annotation session and start training
	const endAnnotation = async () => {
		const confirmContinue = await saveTileData();

		if(confirmContinue){
			initTraining(project?.uuid, pickedTask.model_uuid).then((res) => {
				console.log('init training', res);
				if(res?.data?.warning){
					addToast({
						id: `training_warning-${new Date().getTime()}`,
						className: 'bg-warning',
						title: `Training model '${pickedTask.description}' started with warnings:`,
						message: res.data.warning,
						autohide: false,
					});
				} else if(res?.data?.error || res?.data?.detail){
					addToast({
						id: `training_error-${new Date().getTime()}`,
						className: 'bg-danger',
						title: `Training model '${pickedTask.description}' failed:`,
						message: (res?.data?.error || res?.data?.detail) ?? 'Unknown error starting training.',
						autohide: false,
					});
				} else if(res?.data?.status === 'success'){
					addToast({
						id: `training_success-${new Date().getTime()}`,
						className: 'bg-success',
						title: `Training model '${pickedTask.description}' started successfully`,
						autohide: false,
					});
				}
			}).finally(() => {

				stopAnnotation();
				setTimeout(() => {
					checkForJobs();
				}, 5000);
			});
		}
	};

	// Stop annotation session
	const stopAnnotation = () => {
		console.log("stopping annotation");

		if(mapObject){
			// Remove the listeners
			for (let key of mapListenerKeys.current) {
				unByKey(key);
			}
			mapListenerKeys.current = null;

			// Remove the image layer
			deleteLayerByCustomId(mapObject, customLayerId);

			// Remove the draw layer
			removeDrawLayer(mapObject, drawLayer);
		}

		setActiveImageIndex(null);
		setImageLayer(null);
		setDrawLayer(null);

		dispatch({
			type: 'setDialogue',
			payload: null,
		});
		dispatch({
			type: 'setToolBarVisible',
			payload: true,
		});
		dispatch({
			type: 'setAnnotationMode',
			payload: null,
		});

		const addToShow = ['singlePhotosLayer', 'mapTilesLayer'];
		setLayersVisibilityByCustomIds(mapObject, [...layersToShow, ...addToShow], true);

	};

	// Save the annotations in the current square
	const saveTileData = async () => {
		setLoading(true);

		if(addedAnnotations.current.length === 0){
			if(!window.confirm("No new annotations were made in this image. It could affect the model if something's missed. Still good to go?")){
				// Cancel saving data to annotate more
				setLoading(false);
				return false;
			}
		}

		// Remove ol_uid from the circles, bc backend does not need it
		const annotations = addedAnnotations.current.map(c => _.omit(c, 'ol_uid'));
		const currentTileName = activeTileName.current;

		const res = await postTileData(project.uuid, pickedTask.model_uuid, currentTileName, annotations)
			.then(() => {
				return true;
			})
			.catch((error) => {
				console.error(error);
				return false;
			}).finally(() => {
				setLoading(false);
			}
		);

		return res;
	}

	// change the image to annotate
	const onChangeImage = async (layer, index) => {
		if(index < 0 || index >= imagesInAreaOfInterest.length) return;

		dispatch({
			type: 'setDialogue',
			payload: {
				header: "Loading Image",
				body: "Load time depends on the image size and resolution. Please wait..."
			},
		});

		setActiveImageIndex(index);
		setDisableTools(true);

		const feature = imagesInAreaOfInterest[index];

		try{
			const {source: drawSource} = drawLayer || {};
			if(drawSource){
				drawSource.clear();
				addedAnnotations.current = [];
			}

			layer.setSource(null);

			const {tile_name: image_name, image_height_px: height, image_width_px: width} = feature.properties;
			//const imageUrl = await getSingleImage(`filelink?key=${project.uuid}/images/${image_name}`);
			const imageUrl = await getSingleImage(`filelink?key=${project.uuid}/images/thumbnails/${image_name}`);

			activeTileName.current = image_name;

			if(!imageUrl){
				console.error('Could not get images from s3. Trying the next image.');
				onChangeImage(layer, index + 1);
			}

			// Get the view and the current center
			const view = mapObject.getView();
			const center = view.getCenter();

			// Calculate the new extent based on the center
			const imageExtent = [center[0] - width / 2, center[1] - height / 2, center[0] + width / 2, center[1] + height / 2];

			const projection = new Projection
			({
				code: 'xkcd-image',
				units: 'pixels',
				extent: imageExtent,
			});
			const source = new Static({
				url: imageUrl,
				imageExtent: imageExtent,
				projection: projection,
			});

			source.once('imageloadend', () => {
				dispatch({
					type: 'setDialogue',
					payload: {
						header: "Annotating",
						body: "To maintain the accuracy of annotations, please make sure to annotate all relevant objects within the image before clicking 'Next Image'."
					},
				});
			});

			source.once('imageloaderror', () => {
				console.error('Could not add image to layer. Trying the next image.');
				onChangeImage(layer, index + 1);
			});

			layer.setSource(source);

			// Fit the view to the extent
			view.fit(imageExtent, {
				duration: 0,
			});

		}
		catch(e){
			console.error('Could not get images from s3', e);
			return null;
		}

	};

	const addDrawingInteraction = (layer) => {

		// Create the draw layer and interaction
		const drawSetup = addDrawLayer({map: mapObject, drawType: annotationType, colorOptions});
		setDrawLayer(drawSetup);

		// Get the elements from the draw setup directly, to be able to use it before the state is updated
		const { source, draw, select } = drawSetup;

		// Add drawstart interaction
		draw?.on('drawstart', (e) => {
			drawStartInteraction({e, layer, draw, classId: activeClassId.current});
		});

		// Add drawend interaction
		draw?.on('drawend', (e) => drawEndInteracton({
			e,
			layer,
			annotationType,
			tile_name: activeTileName.current,
			addedAnnotations: addedAnnotations.current,
			taskId: pickedTask.model_uuid,
			classId: activeClassId.current,
		}));

		// Add select interaction
		select?.on('select', (e) => {
			// Remove the selected feature
			const selected = e.selected?.[0];
			if (selected) {
				deleteAnnotation({addedAnnotations: addedAnnotations.current, feature: selected, source});
			}
		});

	}

	// Move to the next image
	const nextImage = async () => {
        if(loading) return;

        if (activeImageIndex < imagesInAreaOfInterest.length - 1) {
            const confirmContinue = await saveTileData();
            if(confirmContinue){
				onChangeImage(imageLayer, activeImageIndex + 1);
            }
        } else if (activeImageIndex === imagesInAreaOfInterest.length - 1) {
            //endAnnotation();
			console.log('last image');
        }
    };

	// Start drawing
	const startDraw = () => {
		setDrawing(true);
		const { draw, select, pointerMoveRef } = drawLayer;
		draw.setActive(true);
		select.setActive(false);
		mapObject.un('pointermove', pointerMoveRef);
	};

	// Start erasing
	const startErase = () => {
		setDrawing(false);
		const { draw, select, pointerMoveRef } = drawLayer;
		draw.setActive(false);
		select.setActive(true);
		mapObject.on('pointermove', pointerMoveRef);
	};

	// Creates a confirm modal to cancel the annotation session
	const cancel = () => {
		dispatch({
			type: 'setConfirmModalContent',
			payload: {
				title: "Cancel annotation session",
				message: "Are you sure you want to cancel the annotation mode and exit?",
				onConfirm: stopAnnotation,
				onCancel: () => {},
			},
		});
	};

	// Keyboard shortcuts
	useKeyboardShortcut('Enter', (!!annotationMode && confirmModalContent === null), nextImage);
	useKeyboardShortcut('Escape', (!toolBarVisible && confirmModalContent === null), () => cancel() );
	useKeyboardShortcut('D', !!annotationMode, () => startDraw() );
	useKeyboardShortcut('E', !!annotationMode, () => startErase() );

	const initSingleImageLayer = useCallback(async () => {

		if(activeImageIndex === null || isInitiated.current) return;

		isInitiated.current = true;
		console.log("Starting annotation mode");

		const layer = new ImageLayer({
			source: null,
			visible: true,
			zIndex: 20,
			name: 'Annotate photos layer',
			properties: {
				customLayerId: customLayerId,
			},
		});
		mapObject.addLayer(layer);
		setImageLayer(layer);

		addDrawingInteraction(layer);
		onChangeImage(layer, activeImageIndex);

		setLayersVisibilityByCustomIds(mapObject, ['singlePhotosLayer', 'mapTilesLayer'], false);

		// Add loading spinner
		const startLoadKey = mapObject.on('loadstart', function () {
			addMapLoadingSpinner(mapObject);
			setDisableTools(true);
		});
		// Remove loading spinner
		const endLoadKey = mapObject.on('loadend', function () {
			removeMapLoadingSpinner(mapObject);
			setDisableTools(false);
		});

		// Save the listener keys to remove them later
		mapListenerKeys.current = [startLoadKey, endLoadKey];

	}, [mapObject, imagesInAreaOfInterest, activeImageIndex]);

	// Starts the right annotation type
	useEffect(() => {
		initSingleImageLayer();
	}, [initSingleImageLayer]);

	useEffect(() => {
		if(annotationSidebarData){
			// Update the active class id ref
			activeClassId.current = annotationSidebarData?.activeClassId ?? 0;
		}
	}, [annotationSidebarData]);

	return (
		<AnnotationToolBar aria-label="Annotation tools">

			<Tooltip
				id="annotate-tip"
				variant="light"
				render={({ content, activeAnchor }) => (
					<span>
						{content} <KeyboardShortcut>{activeAnchor?.getAttribute('data-tooltip-keyboardshortcut')}</KeyboardShortcut>
					</span>
					)}
			/>


			<div>
				<UtilityButton
					label="Cancel"
					tooltip={{
						id: "annotate-tip",
						content: "Cancel the annotation mode and exit",
						place: "top",
					}}
					onClick={cancel}
					variant="danger"
					keyboardShortcutLabel="ESC"
				/>
			</div>

			<ButtonGroup>
				<UtilityButton
					label="Draw"
					tooltip={{
						id: "annotate-tip",
						content: "Draw annotations on the current square",
						place: "top",
					}}
					onClick={startDraw}
					disabled={loading || disableTools}
					variant={drawing ? "success" : "dark"}
					keyboardShortcutLabel="D"
					icon={() => <DrawingIcon />}
				/>

				<UtilityButton
					label="Erase"
					tooltip={{
						id: "annotate-tip",
						content: "Erase annotations from the current square",
						place: "top",
					}}
					onClick={startErase}
					disabled={loading || disableTools}
					variant={!drawing ? "success" : "dark"}
					keyboardShortcutLabel="E"
					icon={() => <BsFillEraserFill style={{ marginTop: "-4px", marginRight: "5px" }} />}
				/>
			</ButtonGroup>

			<div className="d-flex gap-3 align-items-center">
				<div>
					Annotating {activeImageIndex + 1} / {imagesInAreaOfInterest.length}
				</div>
				<ButtonGroup>
					<UtilityButton
						label="Start AI-training"
						tooltip={{
							id: "annotate-tip",
							content: "End annotation session and start AI-training",
							place: "top",
						}}
						onClick={() => {
							dispatch({
								type: 'setConfirmModalContent',
								payload: {
									title: "End annotation session",
									message: "Are you sure you want end the annotation session? Your data will be saved and AI-training will start.",
									onConfirm: endAnnotation,
									onCancel: () => {},
								},
							});
						}}
						disabled={loading}
						variant="dark"
					/>

					{
						activeImageIndex <= imagesInAreaOfInterest.length - 1 ? (
							<UtilityButton
								label="Next Image"
								tooltip={{
									id: "annotate-tip",
									content: "Save the annotations in the current image and move to the next tile",
									place: "top",
								}}
								disabled={loading || disableTools}
								loading={loading}
								onClick={nextImage}
								variant="success"
								keyboardShortcutLabel="↵"
							/>
						) : null
					}
				</ButtonGroup>
			</div>

		</AnnotationToolBar>
	);
}
