import styled from 'styled-components';
import Dropdown from 'react-bootstrap/Dropdown';
import { useState, useEffect } from 'react';
import { AiOutlineCloudDownload } from 'react-icons/ai';

import { fileApiUrl } from '@utils/api';
import { downloadFile } from '@utils/files';
import { getToken } from '@contexts/Token.context';

const ButtonContainer = styled.div`
	position: absolute;
	top: 8px;
	right: 8px;
	z-index: 2;
`;

const StyledDropdownItem = styled(Dropdown.Item)`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
`;

const Export = ({ exportData, annotationMode, project, isDemo }) => {
	const [orthophotoUrl, setOrthophotoUrl] = useState(null);
	const projectID = project?.uuid;

	const downloadGeoJSON = () => {
		const dataStr =
			'data:text/json;charset=utf-8,' +
			encodeURIComponent(JSON.stringify(exportData));
		downloadFile(dataStr, project?.title, 'geojson');
	};

	const downloadOrthophoto = async () => {
		if (!orthophotoUrl) return;
		downloadFile(orthophotoUrl, `${project?.title}_orthophoto`, 'tif');
	};

	useEffect(() => {
		if (!projectID) return;

		const getOrthophotoSignedUrl = async () => {
			const url = `${fileApiUrl}fileLink?key=${projectID}/odm_orthophoto/odm_orthophoto.tif`;
			const token = getToken();
			const response = await fetch(url, {
				method: 'GET',
				headers: {
					authorization: `Bearer ${token}`,
				},
			});

			if (!response.ok || response.status === 204) return;

			const signedUrl = await response.json();
			if (signedUrl?.url) {
				setOrthophotoUrl(signedUrl.url);
			}
		};
		getOrthophotoSignedUrl();
	}, [project]);

	return !annotationMode && projectID ? (
		<ButtonContainer>
			<Dropdown>
				<Dropdown.Toggle variant="success" id="export-downloads-toggle">
					Export
				</Dropdown.Toggle>

				<Dropdown.Menu variant="dark">
					<StyledDropdownItem
						disabled={!exportData || isDemo}
						onClick={() => {
							downloadGeoJSON();
						}}
					>
						<AiOutlineCloudDownload /> <span>GeoJSON</span>
					</StyledDropdownItem>
					<StyledDropdownItem
						disabled={!orthophotoUrl || isDemo}
						onClick={() => {
							downloadOrthophoto();
						}}
					>
						<AiOutlineCloudDownload /> <span>Orthophoto</span>
					</StyledDropdownItem>
				</Dropdown.Menu>
			</Dropdown>
		</ButtonContainer>
	) : null;
};

export default Export;
