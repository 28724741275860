import styled from "styled-components";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useState } from "react";

const StyledButton = styled(Button)`
    background: none;
    border: none;
    padding: 0;
    width: 100%;
    text-align: left;
    span {
        font-weight: 400;
    }
    &:hover {
        background: none;
    }
`;


const CopyButton = ({children, value, props}) => {

    const [copyText, setCopyText] = useState('Copy');
    const handleCopy = async (val) => {
        try{
            await navigator.clipboard.writeText(val);
            console.log('Copied to clipboard', val);
            setCopyText('Copied');
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };

    const renderTooltip = (p) => (
        <Tooltip id="button-tooltip" {...p}>
          {copyText}
        </Tooltip>
    );

    return (
        <OverlayTrigger
            placement="left"
            overlay={renderTooltip}
        >
                <StyledButton onClick={() => handleCopy(value)} {...props}>
                    <span>{children}</span>
                </StyledButton>
        </OverlayTrigger>
    );

}

export default CopyButton;