import useSetSearchParams from "../../../hooks/useSetSearchParams.hook";
import SearchField from "../../filters/searchField.component";
import SelectField from "../../filters/selectField";
import { FilterWrapper } from "../shared/SharedComponents";

import { ROLES, TIERS } from "../../../contexts/User.context";

const UsersFilter = () => {

    const customSetSearchParams = useSetSearchParams();


    return (
        <FilterWrapper>
            <SearchField
                setSearch={val => {
                    customSetSearchParams({ search: val });
                }}
                label="Search by email or uuid"
                searchParamsId="search"
            />

            <SelectField
                label="Filter by tier"
                setValue={val => {
                    customSetSearchParams({ tier: val });
                }}
                searchParamsId="tier"
                options={Object.keys(TIERS).map(tier => {
                    return {label: tier, value: TIERS[tier]}
                })}
            />

            <SelectField
                label="Filter by role"
                setValue={val => {
                    customSetSearchParams({ role: val });
                }}
                searchParamsId="role"
                options={Object.keys(ROLES).map(role => {
                    return {label: role, value: ROLES[role]}
                })}
            />
        </FilterWrapper>
    );
}
export default UsersFilter;