import { useSearchParams } from 'react-router-dom';
import Select from "../form/Select";

const SelectField = ({ label = 'Label', setValue, searchParamsId = null, allOption = true, options = [], ...props }) => {

    const [searchParams] = useSearchParams();

    return (
        <div>
            <Select
                label={label}
                onChange={e => {
                    setValue(e.target.value);
                }}
                defaultValue={ searchParamsId ? searchParams.get(searchParamsId) : ''}
                options={allOption ? [{label: 'All', value: ''}].concat(options) : options}
                {...props}
            />
        </div>
    )
};

export default SelectField;
