import { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';

import { useAuth } from '@contexts/User.context';
import { loginUser } from '@api';

import View from '@components/view/View';
import { useToken } from '@contexts/Token.context';
import Input from '@components/form/Input';

export default function Login() {
	const { currentUser } = useAuth();

	if (currentUser?.loggedIn) {
		return <Navigate to="/projects" />;
	}

	return (
		<View maxWidth={550} title="Log in">
			<LoginForm />
		</View>
	);
}

function LoginForm() {
	const { refetchCurrentUser } = useAuth();
	const { startWorker } = useToken();

	const [errorMessage, setErrorMessage] = useState(null);
	const [loading, setLoading] = useState(false);

	const handleSubmit = async e => {
		try {
			e.preventDefault();
			setLoading(true);
			setErrorMessage(null);

			const form = new FormData(e.currentTarget);
			const email = form.get('email');
			const password = form.get('password');

			await loginUser(email, password);

			const userResult = await refetchCurrentUser();

			if (userResult.isError) {
				console.error(userResult);

				setErrorMessage(
					userResult?.error?.response?.data?.detail ?? 'Unknown error'
				);

				return;
			}
			startWorker();
			setLoading(false);
		} catch (err) {
			console.error(err);
			setErrorMessage(err.message ?? 'Unknown error');
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			{errorMessage && (
				<Alert variant="danger">
					<p className="mb-0">{errorMessage}</p>
				</Alert>
			)}


			<Card bg="dark" border="dark" body className="p-2">

				<Form onSubmit={handleSubmit}>
					<Form.Group controlId="formBasicEmail">
						<Input
							name="email"
							label="Email address"
							type="email"
							placeholder="Enter email"
							autoComplete="username"
							required
						/>
					</Form.Group>

					<Form.Group controlId="formBasicPassword">
						<Input
							name="password"
							label="Password"
							type="password"
							placeholder="Password"
							autoComplete="password"
							required
						/>
					</Form.Group>

					<Button
						disabled={loading}
						className="mt-3"
						variant="success"
						id="submitLogin"
						type="submit">
						Log in
					</Button>
				</Form>
			</Card>

			<div className="mt-5">
				<p>
					Don't have an account?{' '}
					<Link to="/register">Create an account</Link>
				</p>

				<p>
					Forgot your password?{' '}
					<Link to="/request-password-reset">
						Request a reset link
					</Link>
				</p>
			</div>
		</>
	);
}
