import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Fill, Stroke, Style } from 'ol/style.js';

import Draw from 'ol/interaction/Draw';
import {
	activeSquareRef,
	isPointInsideSquare,
	annotationClassId,
} from './annotation.interaction';
import { getSquareCenter } from './freeHand.draw.js';

let polygonInteraction, polygonLayer, polygonSource, squares;
let anntations = {};

const addStyleToPolygon = (polygon, color) => {
	const { r, g, b } = color;

	polygon.setStyle(
		new Style({
			stroke: new Stroke({
				color: `rgb(${r}, ${g}, ${b})`,
				width: 4,
			}),
			fill: new Fill({
				color: 'rgba(255, 255, 255, 0.2)',
			}),
		})
	);
};

export const startPolygonAnnotation = (
	colorOptions,
	existingPolygonLayer,
	squareList,
	mapReference
) => {
	squares = squareList;
	addSquareNames(squares);

	polygonSource = new VectorSource({ wrapX: false });
	polygonLayer = new VectorLayer({ source: polygonSource, zIndex: 25 });
	polygonInteraction = new Draw({
		source: polygonSource,
		type: 'Polygon',
		freehand: true, //!window.confirm("Do you want to try the snappy polygon drawing mode?"),
	});

	loadPolygons(existingPolygonLayer, colorOptions);
	mapReference.addLayer(polygonLayer);
	mapReference.addInteraction(polygonInteraction);

	polygonInteraction.on('drawstart', e => {
		const addedPolygon = e.feature;
		const polygonCoordinates = e.feature.values_.geometry.flatCoordinates;
		const polygonCenter = polygonCoordinates.slice(
			polygonCoordinates.length / 2
		);
		const isInside = isPointInsideSquare(polygonCenter, activeSquareRef);

		if (!isInside) {
			console.log('you should not be drawing circles outside the square');
			polygonInteraction.removeLastPoint();
			mapReference.getView().animate({
				center: getSquareCenter(
					activeSquareRef.geometry.coordinates[0]
				),
				zoom: 21.5,
				duration: 300,
			});
		}

		addedPolygon.values_.classId = annotationClassId;
		addStyleToPolygon(addedPolygon, colorOptions[annotationClassId]);
	});

	polygonInteraction.on('drawend', e => {
		const addedPolygon = e.feature;
		const squareList = allSquaresWithPolygon(addedPolygon);
		addPolygonToSquares(addedPolygon, squareList);
	});
};

export const deletePolygon = feature => {
	console.log('deleting this polygon:', feature);
	const squares = feature.values_.squareList;
	const deletedFeatureId = feature.values_.id;

	deletedFeatureId && console.log('deleted a machione drawn polygon'); //todo: do something with this info

	polygonSource.removeFeature(feature);

	console.log('the polygon was in these squares: ', squares);
	squares.forEach(square => {
		anntations[square].forEach((polygon, index) => {
			if (polygon.ol_uid === feature.ol_uid) {
				console.log('deleting from annotations');
				anntations[square].splice(index, 1);
				console.log(anntations);
			}
		});
	});

	console.log('anntations', anntations);
};

export const getPolygonEraseData = () => {
	return {
		interactionsToDeactivate: [polygonInteraction],
		selectedLayer: polygonLayer,
		deleteFunction: deletePolygon,
	};
};

export const getPolygonsInSquare = square => {
	const squareName = square.properties.tile_name;
	const features = anntations[squareName];
	const preparedPolygons = [];

	if (features) {
		features.forEach(feature => {
			const polygonData = {
				human_input: true,
				tile_name: squareName,
				classid: feature.values_.classId,
				coordinates: feature.getGeometry().getCoordinates()[0],
				machine_annotation: false,
				annotation_type: 'polygon',
			};
			preparedPolygons.push(polygonData);
		});
	}

	// hasOverlappedAnnotations is always false for polygons
	return { annotations: preparedPolygons, hasOverlappedAnnotations: false };
};

const isPolygonInsideSquare = (polygon, square) => {
	const polygonCoordinates = polygon.getGeometry().getCoordinates()[0];
	let isInside = false;
	for (let i = 0; i < polygonCoordinates.length; i++) {
		const point = polygonCoordinates[i];
		if (isPointInsideSquare(point, square)) {
			isInside = true;
			break;
		}
	}
	return isInside;
};

const allSquaresWithPolygon = polygon => {
	const squaresWithPolygon = [];
	squares.forEach(square => {
		let isInside = isPolygonInsideSquare(polygon, square);
		isInside && squaresWithPolygon.push(square.properties.tile_name);
	});

	return squaresWithPolygon;
};

const loadPolygons = (existingPolygonLayer, colorOptions) => {
	if (existingPolygonLayer) {
		const features = existingPolygonLayer.getSource().getFeatures();

		features.forEach(feature => {
			const squareNames = allSquaresWithPolygon(feature);

			if (squareNames.length > 0) {
				addPolygonToSquares(feature, squareNames);
			}
		});

		//add style to polygons
		features.forEach(feature => {
			const classId = feature.values_.classId;
			addStyleToPolygon(feature, colorOptions[classId]);
		});
		polygonSource.addFeatures(features);
	}
};

const addSquareNames = squares => {
	squares.forEach(square => {
		const squareName = square.properties.tile_name;
		anntations[squareName] = [];
	});
};

const addPolygonToSquares = (polygon, squareNames) => {
	polygon.values_.squareList = squareNames;
	squareNames.forEach(squareName => {
		anntations[squareName].push(polygon);
	});
};

export const stopDrawingPolygons = mapReference => {
	mapReference.removeInteraction(polygonInteraction);
	mapReference.removeLayer(polygonLayer);

	// zooms out to the default zoom level
	mapReference.getView().animate({
		zoom: 17,
		duration: 300,
	});

	polygonInteraction = null;
	polygonLayer = null;
	polygonSource = null;

	anntations = {};

	console.log('stopped drawing polygons');
};
