import styled from "styled-components";
import Accordion from 'react-bootstrap/Accordion';
import { formatAreaNumber } from "@utils/helpers";

const AccordionItem = styled(Accordion.Item)`
    border-color: ${(props) => props.theme.colors.border};
`;
const AccordionHeader = styled(Accordion.Header)`
    .accordion-button {
        background-color: ${(props) => props.theme.colors.modal};
        color: ${(props) => props.theme.colors.textColor};
        margin-bottom: 0;
        box-shadow: none;

        &:after {
            background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
        }
    }
`;
const AccordionBody = styled(Accordion.Body)`
    padding: 0;
    margin: 0;
    background-color: ${(props) => props.theme.colors.background};
`;
const DetectionsDetail = styled.div`
    font-weight: normal;
    line-height: 1.2;
    color: ${p => p.theme.colors.textColor};

    padding: 20px;
    p{
        margin-bottom: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        &:last-of-type{
            margin-bottom: 0;
        }
    }
`;
const Name = styled.span`
    text-transform: capitalize;
    color: ${p => p.theme.colors.textAccent};
    color: ${(props) => props.theme.colors.textColor};
    font-size: 0.9em;
    font-weight: 700;
`;

const Details = styled.div`
	padding: 0 20px 20px;
	span{
		text-transform: capitalize;
    	color: ${p => p.theme.colors.textAccent};
	}
    p{
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
		font-size: 0.9em;
		font-weight: normal;
		line-height: 1.1;
        &:last-of-type{
            margin-bottom: 0;
        }
    }
`;

const getLabel = (key) => {
    const labels = {
        count: { name: "Count", unit: "" },
        mean_height: { name: "Mean Height", unit: "m" },
        mean_confidence: { name: "Mean Confidence", unit: "" },
        height_std: { name: "Height STD", unit: "m" },
        min_height: { name: "Min Height", unit: "m" },
        max_height: { name: "Max Height", unit: "m" },
        mean_object_area: { name: "Mean Object Area", unit: "m²" },
    };

    let result = '';

    if (labels.hasOwnProperty(key)) {
        result = {name: labels[key].name, unit: labels[key].unit};
    } else {
		result = {name: key, unit: ""}; // If a label is not found, use the original key as the label
    }

    return result;
}

export const HexagonAccordion = ({hexagonData}) => {
    if(!hexagonData && !hexagonData.detections) return null;

    const detections = hexagonData.detections;

    // Iterate over each key in the detections object to make the values more readable
	for (let key in detections) {
		// Iterate over each property in the current detection object
		for (let prop in detections[key]) {
			// Check if the property value can be converted to a float
			if (!isNaN(parseFloat(detections[key][prop]))) {
				// If it can, convert it to a float, round it to two decimal places,
				// and remove the decimal part if it's ".00"
				detections[key][prop] = parseFloat(detections[key][prop]).toFixed(2).replace('.00', '');
			}
		}
	}

    return (
		<>
			<Details>
				<p><span>Hexagon Count</span> {formatAreaNumber(hexagonData.metadata.count, 0)}</p>
				<p><span>Hexagon Mean height</span> {hexagonData.metadata.mean_height?.toFixed(2) ?? '-'} m</p>
			</Details>
			<Accordion flush>
				{Object.keys(detections).map((key) => {
					return (

						<AccordionItem eventKey={`hexagon-detections-item-event-${key}`} key={`hexagon-detections-item-${key}`}>
							<AccordionHeader>{key} ({formatAreaNumber(detections[key].count, 0)})</AccordionHeader>
							<AccordionBody>
								<DetectionsDetail>
									{
										Object.keys(detections[key]).map((key2) => {
											const label = getLabel(key2);
											let value = detections[key][key2];

											if(key2 === 'count'){
												value = formatAreaNumber(value, 0);
											}
											return <p key={ hexagonData.id ? `key-${hexagonData.id}-${key}-${key2}` : `key-total-${key}-${key2}`}>
												<Name>{label?.name}</Name>
												<span>{value} {label?.unit}</span>
											</p>
										})
									}
								</DetectionsDetail>
							</AccordionBody>
						</AccordionItem>

					)
				})}
			</Accordion>
		</>
    )
}

export default HexagonAccordion;
