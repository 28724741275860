import { useState } from 'react';
import Button from 'react-bootstrap/Button';

import Alert from '@components/alert/Alert';
import Loader from '@components/common/Loader';

import StripeProducts from '@components/products/StripeProducts';
import ProductsFooter from '@components/products/ProductsFooter';

import { convertArea } from "@utils/helpers";
import { useAuth } from '@contexts/User.context';
import AreaStatus from '@components/area/AreaStatus';
import StripeWarning from './StripeWarning';

/**
 *  Component for displaying the estimated area of the project
 *
 * @param {object} areaBalanceQuery - UseQuery object for the users available area
 * @param {number} estimatedArea - the estimated number of area the project will use
 * @param {boolean} calculatingArea - whether the area are currently being calculated
 * @param {boolean} lowAreaAvailable - whether the user has low are available
 */
const Area = ({
    areaBalanceQuery,
    estimatedArea,
    calculatingArea,
    lowAreaAvailable,
}) => {

    const { tierPro } = useAuth();

    // Whether to show the products component
    const [showProducts, setShowProducts] = useState(false);

	// Get the available area
	const availableArea = areaBalanceQuery?.data?.area;

    if(!estimatedArea && !tierPro ) return null; // Only tier pro users needs this all the time

    return (
        <Alert variant={tierPro && lowAreaAvailable ? 'warning' : 'dark'} heading="Area">
            {calculatingArea && (
                <Loader inline message={<>Calculating area...<br /><small>Duration varies based on the number of images.</small></>} />
            )}

            {!calculatingArea && (
                <>
                    {(estimatedArea > 0) && (
						<>
							{tierPro ? (
								<>
									<p className="mb-2">
										Estimated {convertArea(estimatedArea)} hectares to be used for creating this project.
									</p>
									<p className="small text-muted">
										The estimated area will be temporarily subtracted from your area balance
										and updated with the actual area after project processing is finished.
									</p>
								</>
							):(
								<>
									<p className="mb-2">
										Estimated {convertArea(estimatedArea)} hectares for this project.
									</p>
									<p className="small text-muted">
										Actual area will be calculated after the projects is processed.
									</p>
								</>
							)}
						</>
                    )}

                    {tierPro && (
                        <>

                            {areaBalanceQuery?.isLoading && (
                                <Loader inline message="Loading your available area..." />
                            )}

                            {areaBalanceQuery?.isError && (
                                <p>
                                    Failed to load your available area. Please try again later.
                                </p>
                            )}

                            {(!areaBalanceQuery?.isLoading && !areaBalanceQuery?.isError) && (
                                <>
                                    {availableArea ? (
                                        <AreaStatus available={availableArea} toUse={estimatedArea} />
                                    ) : (
                                        <h2>Available area could not be loaded</h2>
                                    )}

                                    {(!showProducts && !lowAreaAvailable) && (
                                        <p className="mb-0 mt-4">
                                            <Button
                                                variant="link"
                                                className="text-left p-0"
                                                onClick={() => setShowProducts(!showProducts)}
                                            >
                                                Need more area?
                                            </Button>
                                        </p>
                                    )}

                                    {lowAreaAvailable && (
                                        <>
                                            {estimatedArea ?
                                            (
                                                <>
                                                    <p className="h5 mt-4">
                                                        Not enough area to start this project.
                                                    </p>
                                                    <p className="h5">
                                                        Buy more area or remove some files to continue.
                                                    </p>
                                                </>
                                            )
                                            :
                                            (
												<>
													{availableArea && (
														<p className="h5 pt-3">
															{availableArea <= 0 ? (
																<>
																You have no area available.<br />
																You need to buy more before trying to create a project.
																</>

															) : (
																<>
																You are running low on area.<br />
																We recommend buying more before trying to create a project.
																</>
															)}

														</p>
													)}
												</>
                                            )}
                                        </>
                                    )}

                                    {(lowAreaAvailable || showProducts) && (
                                        <>
                                            <StripeWarning />
                                            <StripeProducts stripeLookupKeys={["area_small", "area_medium", "area_large"]} />
                                            <ProductsFooter />
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </>
            )}
        </Alert>
    )
}
export default Area;
