import RangeSlider from '@components/rangeSlider/RangeSlider';

const RangeSliders = ({
	data,
	updateFilterBox,
	hexagonRanges,
}) =>{

	const heightRange = hexagonRanges?.height;
	const countRange = hexagonRanges?.count;

	// Function to get the range values
	const getRangeValues = (data, range) => [
		// Check the minimum value
		// If it's undefined or less than the minimum allowed range, use the minimum allowed range
		// If it's greater than the maximum allowed range, use the maximum allowed range
		// Otherwise, use the actual value
		(data.min === undefined) || (data.min < range.min)
			? range.min
			: (data.min > range.max)
				? range.max
				: data.min,

		// Check the maximum value
		// If it's undefined or greater than the maximum allowed range, use the maximum allowed range
		// If the maximum value is less than the minimum allowed range, use the minimum allowed range
		// Otherwise, use the actual maximum value
		(data.max === undefined) || (data.max > range.max)
			? range.max
			: (data.max < range.min)
				? range.min
				: data.max
	];

	// Use the function to get the height and count values
	const heightValues = getRangeValues(data.height, heightRange);
	const countValues = getRangeValues(data.count, countRange);

	return(
		<>
			{(heightRange?.min != null && heightRange?.max != null)  && (
				<RangeSlider
					label={<>Mean Height <span className="small text-muted">(m)</span></>}
					step={0.01}
					min={heightRange.min}
					max={heightRange.max}
					values={heightValues}
					onChange={(values) => {
						// Update the height values in the filter box
						updateFilterBox('height', { min: values[0], max: values[1] });
					}}
				/>
			)}

			{(countRange.min !=null && countRange.max != null) && (
				<RangeSlider
					label="Count"
					step={1}
					min={countRange.min}
					max={countRange.max}
					values={countValues}
					onChange={(values) => {
						updateFilterBox('count', { min: values[0], max: values[1] });
					}}
				/>
			)}
		</>
	);
}

export default RangeSliders;
