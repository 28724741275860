import { useRef } from "react";
import { useSearchParams } from 'react-router-dom';
import styled from "styled-components";
import Form from 'react-bootstrap/Form';
import { AiOutlineSearch } from 'react-icons/ai';

const StyledSearchLabel = styled.label`
	max-width: 100%;
    width: 400px;
	position: relative;

	> div{
		position: relative;
		color: var(--bs-gray-600, #6c757d);
	}

	svg{
		position: absolute;
		left: 8px;
		bottom: 4px;
	}
`;

const StyledSearchInput = styled(Form.Control)`
 	background-color: ${(props) => props.theme.colors.modal};
    border: 1px solid hsl(0,0%,30%);
    color: ${(props) => props.theme.colors.textColor};
	padding-left: 40px;
	&:focus{
		background-color: ${(props) => props.theme.colors.modal};
		border: 1px solid hsl(0,0%,30%);
		color: ${(props) => props.theme.colors.textColor};
	}
	&::-webkit-search-cancel-button {
		color: #ff0000;
		background-color: #ff0000; /* Change this to the desired color */
	}
`;

const SearchField = ({ label = 'Search', placeholder = 'Search...', setSearch, searchParamsId = null, ...props }) => {

    const [searchParams] = useSearchParams();
    const timeoutRef = useRef(null);

	const handleSearch = (e) => {
		const value = e.target.value;
		
		clearTimeout(timeoutRef.current);
		timeoutRef.current = setTimeout(() => {
			// Send search request after 500ms
			setSearch(value);
		}, 500);
	}
    
    return (
        <StyledSearchLabel>
            <span>{label}</span>
            <div>
                <AiOutlineSearch className="m-1 mb-2" />
                <StyledSearchInput
                    type="search"
                    placeholder={placeholder}
                    aria-label={placeholder}
                    name="search"
                    onChange={handleSearch}
                    autoComplete="off"
                    defaultValue={ searchParamsId ? searchParams.get(searchParamsId) : null}
					{...props}
                />
            </div>
        </StyledSearchLabel>
    )
};

export default SearchField;