import { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Card from 'react-bootstrap/Card';

import SplitView from '@components/view/SplitView';
import RegisterForm from '@components/registerForm/RegisterForm';
import StripeProducts from '@components/products/StripeProducts';
import Alert from '@components/alert/Alert';

const Outer = styled.div`
	padding-top: 3rem;
`;

export default function RegisterAccount() {

	return (
		<Outer>
			<SplitView
				leftContent={<LeftContent />}
				rightContent={<RightContent />}
				bottomContent={<BottomContent />}
			/>
		</Outer>
	);
}

const Header = styled.h1`
	color: ${props => props.theme.colors.green};
`;

const Content = styled.div`
	color: ${props => props.theme.colors.textAccent};
	padding-right: 5vw;
`;

const StyledList = styled.ol`
	display: flex;
	flex-direction: column;
	gap: 2rem;
	padding-top: 1rem;
	h2{
		font-size: calc(1rem + 0.5vw);
		font-weight: 300;
		line-height: 1.2;
		color: ${props => props.theme.colors.textColor};
	}
	li{
		&::marker{
			font-size: calc(1rem + 0.5vw);
			font-weight: 300;
			line-height: 1.2;
			color: ${props => props.theme.colors.textColor};
		}
	}
`;

const LeftContent = () => {

	return(
		<Content>
			<Header className="mb-4 display-6">Get access in 3 easy steps</Header>
			<StyledList>
				<li>
					<h2>Create an account</h2>
					<p>Embark on your journey with us by using the registration form to set up your new account.</p>
				</li>
				<li>
					<h2>Verify your email</h2>
					<p>Check your inbox for an email. It contains a link to verify your email address and begin using our platform.</p>
				</li>
				<li>
					<h2>Choose your subscription plan</h2>
					<p>Log in and unlock the Biodrone portal with a monthly storage subscription. It's your key to a world of advanced features and capabilities.</p>
					<p>Get started on creating new projects by using Area. <br />As a warm welcome to new paying subscribers, we're offering the first 10ha of Area absolutely free.</p>
				</li>
			</StyledList>

		</Content>

	);
}

const StyledCard = styled(Card)`
	.btn{
		width: 100%;
	}
`;
const RightContent = () => {

	const [success, setSuccess] = useState(false);

	return (
		<>
			{success ? (
				<Alert variant="success" heading="Registration successful!">
					<p>We've sent a verification email to your account.<br />Please check your inbox.</p>
				</Alert>
			) : (
				<>
					<StyledCard bg="dark" border="dark" body className="p-3">
						<h2 className="h1 mb-4">Create Your Account</h2>
						<RegisterForm onSuccess={() => setSuccess(true)} tier="pro" />
					</StyledCard>
					<p className="mt-4 mb.0 text-muted text-end">
						Already have an account? <Link to="/login" className="inherit">Log in</Link>
					</p>
				</>
			)}
		</>
	);
}

const CenterContent = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 4rem;
	padding: 4rem 1rem 5rem;
	margin-top: 4rem;
	background-color: ${props => props.theme.colors.modal};
`;
const SubHeader = styled.h2`
	color: ${props => props.theme.colors.textColor};
	text-align: center;
	font-size: 2rem;
    font-weight: 300;
    line-height: 1.2;
	margin-bottom: 1rem;
`;

const PackageDescription = styled.p`
	color: ${props => props.theme.colors.textAccent};
	text-align: center;
	line-height: 1.5;
	width: 100%;
	max-width: 600px;
	margin: 0 auto 2rem;
`;
const BottomContent = () => {
	return (
		<CenterContent>
			<div>
				<SubHeader>Available Subscription Plans</SubHeader>
				<PackageDescription>
					To use the Biodrone Portal, a subscription is required.
					It provides you with advanced AI training and detection tools for your projects, and also offers secure storage of your files.
				</PackageDescription>
				<StripeProducts
					stripeLookupKeys={['small_storage', 'medium_storage']}
					type="present"
				/>
			</div>

			<div>
				<SubHeader>Available Area Packages</SubHeader>
				<PackageDescription>
					Area is needed to start projects.
					The amount of Area required for a project corresponds to the coverage of your images on the map.
				</PackageDescription>
				<StripeProducts
					stripeLookupKeys={["area_small", "area_medium", "area_large"]}
					type="present"
				/>
			</div>
		</CenterContent>
	);
}
