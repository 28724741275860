import Overlay from 'ol/Overlay.js';

/**
 * Creates a new tooltip overlay
 */
export const createTooltip = ({
	mapRef,
	tooltipRef,
	positioning = 'bottom-center',
}) => {
	if (!mapRef || !tooltipRef) {
		console.error('Map and tooltip reference is required');
		return;
	}

	const tooltip = new Overlay({
		element: tooltipRef,
		offset: [0, -15],
		positioning: positioning,
		stopEvent: false,
		insertFirst: false,
	});
	mapRef.addOverlay(tooltip);

	return tooltip;
};

export const clearTooltip = ({ tooltip, tooltipRef }) => {
	if (!tooltip) {
		console.error('Tooltip reference is required');
		return;
	}

	tooltip.setPosition(undefined);
	tooltipRef.innerHTML = '';
};
