import { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';

import { adminUpdateUser } from '@api';
import { ROLES, TIERS } from '@contexts/User.context';

import { StyledModal } from '@components/modal/sharedModalStyles';
import Input from '@components/form/Input';
import Select from '@components/form/Select';
import Toggle from '@components/form/toggle.component'

const EditModal = ({ data, show, setShow }) => {
	const queryClient = useQueryClient();

	const [tier, setTier] = useState(data.tier);
	const access = data.tier !== 'premium' ? [] : data.access;

    const accessOptions = [
        {
			value: 'detect',
			label: 'Detect',
			helperText: 'Allowed to detect objects in a project'
		},
        {
			value: 'train',
			label: 'Train',
			helperText: 'Allowed to train models in a project'
		},
    ];

	const today = new Date();
	today.setDate(today.getDate() - 1);
	const yesterday = today.toISOString().split('T')[0];

	const resetStates = () => {
		setTier(null);
		setShow();
	};

	const mutation = useMutation({
        mutationFn: (data) => {
          return adminUpdateUser(data.uuid, data.updatedUser)
        },
		onSuccess: async () => {
			await queryClient.refetchQueries({ queryKey: ['admin_users'], type: 'active' })
			resetStates();
		}
    })

	const handleEditUser = (e) => {

		const formData = new FormData(e.target);

		const detect = formData.get('access-detect');
		const train = formData.get('access-train');

		const accessData = ['create']; // Create is required for now

		if(detect) accessData.push('detect');
		if(train) accessData.push('train');

		let trialExpiry = data.trial_expiry;
		const selectedExpiry = new Date(formData.get('trial_expiry'));
		if (!isNaN(selectedExpiry.getTime())) {
			selectedExpiry.setHours(20, 0o0, 0);

			// save as unix timestamp in seconds
			trialExpiry = selectedExpiry.getTime() / 1000;
		}

		const updatedUser = {
			role: formData.get('role'),
			tier: formData.get('tier'),
			trial_expiry: trialExpiry,
			access: accessData
		};

		mutation.mutate({uuid: data.uuid, updatedUser});
	};

	return (
		<StyledModal
			variant="dark"
			show={show}
			centered={true}
			onHide={() => {
				resetStates();
			}}>
			<Modal.Header closeButton closeVariant="white">
				<Modal.Title>Edit user</Modal.Title>
			</Modal.Header>

			<Form
				onSubmit={(e) => {
					e.preventDefault();
					handleEditUser(e);
				}}>
				<Modal.Body>
					{mutation.isLoading && <Spinner animation="border" variant="primary" />}

					{!mutation.isLoading && mutation.isError && <p>Error: {mutation.error?.response?.data?.detail ?? mutation.error?.message}</p>}

					{!mutation.isLoading && !mutation.isError && (
						<>
							<p>Email: {data.email}</p>

							<Form.Group className="mb-3">
								<Select
									id="user-role-select"
									label="User role"
									name="role"
									defaultValue={data.role}
									options={Object.keys(ROLES).map(role => {
										return {label: role, value: ROLES[role]}
									})}
								/>
							</Form.Group>

							<Form.Group className="mb-3">
								<Select
									id="user-tier-select"
									label="Tier"
									name="tier"
									defaultValue={tier}
									onChange={e => {
										setTier(e.target.value);
									}}
									options={Object.keys(TIERS).map(t => {
										return {label: t, value: TIERS[t]}
									})}
								/>
							</Form.Group>

							{tier === TIERS.Premium && (
								<Form.Group className="mb-3">
									Access
									<div className="p-3 pb-0 border">
										<Toggle
											type="switch"
											id={`access-default`}
											name="access-create"
											label="Create"
											helperText="Allowed to create a project"
											defaultChecked={true}
											disabled
										/>
										{accessOptions.map((o, i) => (
											<Toggle
												type="switch"
												id={`access-${i}`}
												key={`access-${i}`}
												name={`access-${o.value}`}
												label={o.label}
												helperText={o.helperText}
												defaultChecked={access.some(accessItem => accessItem === o.value)}
											/>
										))}
									</div>
								</Form.Group>
							)}

							{tier === TIERS.Trial && (
								<Input
									id="trial-expiry-input"
									label="Trial expiry"
									type="date"
									name="trial_expiry"
									min={yesterday}
									defaultValue={format(
										new Date(data.trial_expiry*1000),
										'yyyy-MM-dd'
									)}
								/>
							)}

						</>
					)}
				</Modal.Body>

				<Modal.Footer>
					<Button variant="secondary" onClick={() => resetStates()} role="button">
						Cancel
					</Button>
					<Button
						variant="success"
						disabled={mutation.isLoading || mutation.isError}
						type="submit"
					>
						Save
					</Button>
				</Modal.Footer>
			</Form>

		</StyledModal>
	);
};
export default EditModal;
