// a context that holds the state of the project and the functions that change the state

import { createContext, useContext, useReducer } from "react";
import { get as getProjection } from "ol/proj.js";

const ProjectContext = createContext(null);

// Constants as in the backend. Is used to set the project mode
const ImageMode = {
    GENERATE_ORTHOPHOTO: "generate_orthophoto",
	UPLOAD_ORTHOPHOTO: "upload_orthophoto",
    SINGLE_IMAGE: "single_image"
};
// Constants as in the frontend for the project mode
export const ProjectMode = {
	ORTHOPHOTO: "orthophoto",
	SINGLE_IMAGE: "single_image"
};

// Default color options
const colorOptions = [
	{ r: 0, g: 225, b:255, a: 1 },
	{ r: 237, g: 120, b: 203, a: 1 },
	{ r: 244, g: 109, b: 67, a: 1 },
	{ r: 148, g: 137, b: 255, a: 1 },
	{ r: 114, g: 247, b: 255, a: 1 },
	{ r: 158, g: 1, b: 66, a: 1 },
	{ r: 230, g: 245, b: 152, a: 1 },
	{ r: 171, g: 221, b: 164, a: 1 },
	{ r: 255, g: 255, b: 255, a: 1 },
	{ r: 182, g: 138, b: 96, a: 1 },
];

const initialState = {
    project: null,
	projectMode: null,
	isDemo: false,

    features: [],
	singleImageFeatures: [],
	colorScheme: colorOptions.reduce((acc, color, index) => {
		acc[index] = { color, visible: true };
		return acc;
	}, {}),
	trainingDataColorScheme: {},

    mapObject: null,
	mapTooltip: null,

    pointLayer: null,
    hexagonLayer: null,
    polygonLayer: null,

	singleImageLayer: null,
	singleImagePolygonVectorSource: null,
	singleImagePolygonLayer: null,
	singlePhotosLayer: null,

    taskId: null,
    pickedTask: null,
	pickedModelClasses: null,
    tasks: null,
    dialogue: null,
    toolBarVisible: true,

    hexagonInfo: null,
    hexagonSize: 1000,
	selectedHexagon: null,

    extent: null,

	annotationMode: null,
	annotationSidebarData: null,
	annotationType: null,

	confirmModalContent: null,

	activeSidebars: [{sidebarId: 'layerView'}],
};

const actions = {
    setFeatures: (state, payload) => ({ ...state, features: payload }),
	setSingleImageFeatures: (state, payload) => ({ ...state, singleImageFeatures: payload }),
    setColorScheme: (state, payload) => ({ ...state, colorScheme: payload }),
	setTrainingDataColorScheme: (state, payload) => ({ ...state, trainingDataColorScheme: payload }),
    setMapObject: (state, payload) => ({ ...state, mapObject: payload }),
	setMapTooltip: (state, payload) => ({ ...state, mapTooltip: payload }),
    setPointLayer: (state, payload) => ({ ...state, pointLayer: payload }),
    setHexagonLayer: (state, payload) => ({ ...state, hexagonLayer: payload }),
    setPolygonLayer: (state, payload) => ({ ...state, polygonLayer: payload }),
	setSingleImageLayer: (state, payload) => ({ ...state, singleImageLayer: payload }),
	setSingleImagePolygonVectorSource: (state, payload) => ({ ...state, singleImagePolygonVectorSource: payload }),
	setSingleImagePolygonLayer: (state, payload) => ({ ...state, singleImagePolygonLayer: payload }),
	setSinglePhotosLayer: (state, payload) => ({ ...state, singlePhotosLayer: payload }),
    setTaskId: (state, payload) => ({ ...state, taskId: payload }),
    setPickedTask: (state, payload) => ({ ...state, pickedTask: payload }),
	setPickedModelClasses: (state, payload) => ({ ...state, pickedModelClasses: payload }),
    setTasks: (state, payload) => ({ ...state, tasks: payload }),
    setAnnotationType: (state, payload) => ({ ...state, annotationType: payload }),
    setDialogue: (state, payload) => ({ ...state, dialogue: payload }),
    setToolBarVisible: (state, payload) => ({ ...state, toolBarVisible: payload }),
    setHexagonInfo: (state, payload) => ({ ...state, hexagonInfo: payload }),
    setHexagonSize: (state, payload) => ({ ...state, hexagonSize: payload }),
	setSelectedHexagon: (state, payload) => ({ ...state, selectedHexagon: payload }),
    setExtent: (state, payload) => ({ ...state, extent: payload }),
    setIsDemo: (state, payload) => ({ ...state, isDemo: payload }),
	setAnnotationMode: (state, payload) => ({ ...state, annotationMode: payload }),
	setAnnotationSidebarData: (state, payload) => ({ ...state, annotationSidebarData: payload }),
	setConfirmModalContent: (state, payload) => ({ ...state, confirmModalContent: payload }),
	setActiveSidebars: (state, payload) => ({ ...state, activeSidebars: payload }),
};

function projectReducer(state, action) {
	//If action.type is setProject, then also set other related states
	if (action.type === "setProject") {

		if(action.payload === null){
			return { ...state, project: null, projectMode: null };
		}

		let projectMode = '';
		const actionMode = action.payload.image_mode;
		switch (actionMode) {
			case ImageMode.GENERATE_ORTHOPHOTO:
			case ImageMode.UPLOAD_ORTHOPHOTO:
				projectMode = ProjectMode.ORTHOPHOTO;
				break;
			case ImageMode.SINGLE_IMAGE:
				projectMode = ProjectMode.SINGLE_IMAGE;
				break;
			default:
				// Default is orthophoto
				projectMode = ProjectMode.ORTHOPHOTO;
				break;
		}
		return { ...state, project: action.payload, projectMode };
	} else if (action.type === "resetState") {
		return initialState;
	}

    const handler = actions[action.type];
    if (!handler) {
        throw new Error(`Unknown action: ${action.type}`);
    }

    return handler(state, action.payload);
}

export function ProjectProvider({ children }) {
	// How to use:
	// `dispatch({ type: 'setProject', payload: newProject }).`
    const [state, dispatch] = useReducer(projectReducer, initialState);

	const setActiveSidebar = ({sidebarId, parentId}) => {
		const sidebarElement = {sidebarId, parentId};

		// Remove any other active sidebar that is of same parent
		const newActiveSidebars = state.activeSidebars;

		// Check if sidebarId already exists
		const doesExist = newActiveSidebars.some(sidebar => sidebar.sidebarId === sidebarId);

		// If sidebarId does not exist, add the new sidebarElement
		if (!doesExist) {
			newActiveSidebars.push(sidebarElement);
			dispatch({ type: 'setActiveSidebars', payload: newActiveSidebars });
		}
	};

	const removeFromActiveSidebars = (sidebarId) => {
		dispatch({
			type: 'setActiveSidebars',
			payload: state.activeSidebars.filter(sidebar => sidebar.sidebarId !== sidebarId)
		});
	};

    const defaultProjection = getProjection('EPSG:3857');

    return (
        <ProjectContext.Provider value={{ ...state, dispatch, colorOptions, defaultProjection, setActiveSidebar, removeFromActiveSidebars }}>
            {children}
        </ProjectContext.Provider>
    );
};

export function useProject() {
	const context = useContext(ProjectContext);
	if (!context) {
		throw new Error("useProject must be used within a ProjectProvider");
	}
	return context;
}


