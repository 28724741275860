import { useState } from "react";
import { useQuery } from "@tanstack/react-query";

import Button from "react-bootstrap/Button";

import StripeWarning from "./StripeWarning";
import Loader from "@components/common/Loader";
import { StripeProductsButtonGroup, StripeProductButton } from "@components/products/StripeProducts";
import ProductsFooter from "@components/products/ProductsFooter";

import { getBillingPortalUrl } from "@api";
import { useAuth } from "@contexts/User.context";

export default function UpgradeStoragePlan() {
	const [showProducts, setShowProducts] = useState(false);
	const { currentUser } = useAuth();

	// Create Stripe billing portal URL
	const {
		data: StripeBillingPortalUrl,
		error: StripeBillingPortalUrlError,
		isLoading: StripeBillingPortalUrlLoading,
	} = useQuery({
		queryKey: ['billingPortalUrl', currentUser?.uuid, 'subscription_update'],
		queryFn: () => getBillingPortalUrl({subscription_lookup_keys: 'small_storage,medium_storage'}),
		enabled: !!currentUser?.uuid && showProducts,
	});

	if (!showProducts) {
		return (
			<p className="mb-0 mt-4">
				<Button
					variant="link"
					className="text-left p-0"
					onClick={() => setShowProducts(true)}>
					Need more storage?
				</Button>
			</p>
		);
	}

	const availableSubscriptions = StripeBillingPortalUrl?.available_subscriptions ?? null;

	if(StripeBillingPortalUrlLoading) return <Loader />;

	return (
		<>
			{availableSubscriptions?.length > 0 ? (
				<>
					<StripeWarning />

					<h2 className="mt-4">Change your subscription</h2>
					<StripeProductsButtonGroup columns="1fr 1fr">
						{availableSubscriptions.map(p => {
							return (
								<StripeProductButton key={p.id} p={p} overwriteUrl={p.billing_portal_url} basic />
							);
						})}
					</StripeProductsButtonGroup>
					<ProductsFooter />
				</>
			) : (
				<p className="mt-3">No suitable subscriptions available. Contact <a href="mailto:post@biodrone.no">post@biodrone.no</a> for assistance.</p>
			)}

			{StripeBillingPortalUrlError && (
				<p className="text-danger">An error occured and the Storage Subscription options could not be loaded.</p>
			)}

		</>
	);
}
