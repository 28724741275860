import { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import Loader from '@components/common/Loader';
import View from '@components/view/View';
import Input from '@components/form/Input';

import { resetPassword } from '@api';
import { checkPassword } from '@utils/helpers';

const StyledAlert = styled(Alert)`
	p:last-of-type {
		margin-bottom: 0;
	}
`;

const PasswordReset = () => {
	const [email, setEmail] = useState('');
	const [token, setToken] = useState(null);
	const [password, setPassword] = useState('');
	const [passwordConfirm, setPasswordConfirm] = useState('');
	const [errorMessages, setErrorMessages] = useState([]);
	const [resetSucces, setResetSuccess] = useState(false);
	const [loading, setLoading] = useState(false);

	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);
		const t = searchParams.get('token');
		const e = searchParams.get('email');

		if (!t || !e) {
			setErrorMessages(['Invalid reset link']);
			return;
		}

		setToken(t);
		setEmail(e);
	}, [location.search]);

	const renderErrorMessages = () => {
		console.log('errorMessages', errorMessages);
		if (errorMessages.length > 0) {
			return (
				<StyledAlert variant="danger">
					{errorMessages.map((message, index) => (
						<p key={`error-${index}`}>{message}</p>
					))}
				</StyledAlert>
			);
		}
	};

	const handleSubmit = async event => {
		event.preventDefault();

		setErrorMessages([]);

		const passwordCheck = checkPassword(password, passwordConfirm);

		if (passwordCheck.validated) {
			console.log('resetting password');
			setLoading(true);
			resetPassword(token, email, password, passwordConfirm)
				.then(response => {
					if (response.status === 200) {
						// redirect to login page using navigate
						setResetSuccess(true);
						setTimeout(() => {
							navigate('/login');
						}, 5000);
					} else {
						setErrorMessages([
							response?.response?.data?.detail ??
								'Password reset failed!',
						]);
					}
				})
				.catch(error => {
					console.log('error received');

					console.log(error);
					if (error.response.status === 401) {
						setErrorMessages(e => [...e, 'Wrong email address']);
						console.log(error.response);
					}
					if (error.response.status === 400) {
						setErrorMessages(e => [
							...e,
							error.response.data.errors,
						]);
						console.log(error.response);
					}
				})
				.finally(() => {
					setLoading(false);
				});
		} else {
			console.log('form not submitted', passwordCheck);
			setErrorMessages(e => [...e, ...passwordCheck.errors]);
		}
	};

	return (
		<View maxWidth={550} title="Reset your password">
			{renderErrorMessages()}

			{loading ? (
				<Loader />
			) : (
				<>
					{resetSucces ? (
						<StyledAlert variant="success">
							<p className="mb-0">
								Password reset succeeded.
								<br />{' '}
								<Link to={'/login'}>
									Click here to log in
								</Link>{' '}
								or wait to be redirected.
							</p>
						</StyledAlert>
					) : (
						<>
							<Form onSubmit={handleSubmit}>
								<Input
									type="email"
									label="Email address"
									value={email}
									disabled
								/>

								<Input
									type="password"
									label="New Password"
									placeholder="Password"
									value={password}
									onChange={e => setPassword(e.target.value)}
								/>

								<Input
									type="password"
									label="Confirm New Password"
									placeholder="Confirm Password"
									value={passwordConfirm}
									onChange={e =>
										setPasswordConfirm(e.target.value)
									}
								/>

								<Button
									variant="success"
									type="submit"
									disabled={loading}>
									Reset Password
								</Button>
							</Form>

							<p className="mt-5">
								Remember your old password?{' '}
								<Link to="/login">Log in</Link>
							</p>
						</>
					)}
				</>
			)}
		</View>
	);
};
export default PasswordReset;
