import styled from 'styled-components';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { IoCloseSharp } from 'react-icons/io5';

import FormInputs from './parts/FormInputs';
import Results from './parts/Results';

const StyledCard = styled(Card)`
	background-color: var(--bs-card-cap-bg);
`;

const StyledCloseButton = styled(Button)`
	position: absolute;
	top: 0;
	right: 0;
	z-index: 1;
	transform: translate(50%, -50%);
	background-color: ${props => props.theme.colors.backgroundAccent};
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;
	width: 22px;
	height: 22px;
	border: 1px solid rgba(${props => props.theme.colors.borderLightRgb}, 0.2);
`;

const FilterBox = ({
	data,
	setFilters,
	hexagonRanges,
	index,
	totalHexagons,
}) => {

	const removeFilterBox = () => {
		setFilters(boxes => {
			const newBoxes = [...boxes];
			newBoxes.splice(index, 1);
			return newBoxes;
		});
	};

	return (
		<StyledCard>
			<StyledCloseButton title="Remove" size="sm" variant="dark" onClick={removeFilterBox}>
				<IoCloseSharp />
			</StyledCloseButton>

			<Card.Body className="pt-2 my-2">
				<FormInputs
					data={data}
					setFilters={setFilters}
					hexagonRanges={hexagonRanges}
					index={index}
				/>
			</Card.Body>

			<Card.Footer>
				<Results data={data} totalHexagons={totalHexagons} />
			</Card.Footer>
		</StyledCard>
	);
};

export default FilterBox;
