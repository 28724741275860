import Select from 'ol/interaction/Select';

import { getCircleEraseData } from './circle.draw';
import { getPolygonEraseData } from './polygon.draw';
import { pointerMoveListener } from './erasorPointer';

import {
	activeSquareRef,
	isPointOverlappingSquare,
} from './annotation.interaction.js';

let selectInteraction;
let eraseDataFunction;
let pointerMoveRef;

export const startErasor = mapReference => {
	if (!selectInteraction) return;

	// Add trash can cursor
	pointerMoveRef = evt => {
		pointerMoveListener(evt, mapReference);
	};
	mapReference.on('pointermove', pointerMoveRef);

	mapReference.addInteraction(selectInteraction);

	const { interactionsToDeactivate } = eraseDataFunction();

	interactionsToDeactivate.forEach(interaction => {
		interaction.setActive(false);
	});
};

export const stopErasor = mapReference => {
	if (!eraseDataFunction) return;

	const { interactionsToDeactivate } = eraseDataFunction();

	// remove trash can cursor
	mapReference.un('pointermove', pointerMoveRef);
	mapReference.getTargetElement().style.cursor = 'auto';

	if (selectInteraction) {
		mapReference.removeInteraction(selectInteraction);
	}

	interactionsToDeactivate.forEach(interaction => {
		// if interaction has function setActive
		if (interaction && typeof interaction.setActive === 'function') {
			interaction.setActive(true);
		}
	});
};

export const addErasorInteraction = (annotationType, mapReference) => {
	console.log('Creating erasor interaction');
	if (selectInteraction) {
		// Remove existing select interaction
		mapReference.removeInteraction(selectInteraction);
	}

	if (annotationType === 'Circle') {
		eraseDataFunction = getCircleEraseData;
	}
	if (annotationType === 'Polygon') {
		eraseDataFunction = getPolygonEraseData;
	}

	const { selectedLayer, deleteFunction } = eraseDataFunction();

	selectInteraction = new Select({
		layers: [selectedLayer],
	});

	selectInteraction.on('select', function (e) {
		const selected = e.selected?.[0];
		if (selected) {
			const isOverlapping = isPointOverlappingSquare(
				selected,
				activeSquareRef
			);

			isOverlapping && deleteFunction(selected);
			selectInteraction.getFeatures().clear();
		}
	});
};

export const removeErasorInteraction = mapReference => {
	if (selectInteraction) {
		console.log('Removing erasor interaction');
		mapReference.removeInteraction(selectInteraction);

		selectInteraction = null;
		eraseDataFunction = null;
	}

	if (pointerMoveRef) {
		mapReference.un('pointermove', pointerMoveRef);
		pointerMoveRef = null;
	}
};
