import { useQuery } from '@tanstack/react-query';
import {
    flexRender,
    getCoreRowModel,
    useReactTable,
  } from '@tanstack/react-table'

import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import styled from 'styled-components';

import Alert from 'react-bootstrap/Alert';
import { StyledTable, StyledTableHeader } from '../admin/shared/SharedComponents';

import { getAreaTransactions } from '@api';
import { convertArea } from "@utils/helpers";

import Loader from '@components/common/Loader';

const TransactionCreditAmount = styled.div`
	display: flex;
	gap: 5px;
`;

const AreaTransactionsList = ({user = null}) => {
	const { data, isLoading, error } = useQuery({
		queryKey: ['transactions', user?.uuid],
		queryFn: () => getAreaTransactions(user?.uuid),
	});

	function transactionUsage(transaction) {
		if(!!transaction.admin_uuid){
			return <>
				{!!transaction.note && (<>{transaction.note}<br /></>)}
				<small className="text-muted">Admin entry</small>
			</>;
		}

		if(!!transaction.stripe_checkout_session_id){
			return <>
				Area purchase
				{!!transaction.note && (<><br /><small className="text-muted">{transaction.note}</small></>)}
			</>;
		}

        if(transaction.type === 'subtraction'){
			return <>
				Area usage
				{!!transaction.note && (<><br /><small className="text-muted">{transaction.note}</small></>)}
			</>;
        }

		if(transaction.type === 'addition'){
			return <>
				Area addition
				{!!transaction.note && (<><br /><small className="text-muted">{transaction.note}</small></>)}
			</>;
        }

		return '-';
	}

    const columns = [
        {
            header: 'Amount',
            accessorKey: 'amount',
            cell: ({ row, getValue }) => {
                return (
                    <TransactionCreditAmount>
                        <div>{row.original.type === 'addition' ? '+' : '–'}</div>

                        <div>{row.original.amount && (convertArea(getValue()) + ' hectares')}</div>
                    </TransactionCreditAmount>
                );
            }
        },
        {
            header: 'Type',
            accessorKey: 'type',
            cell: ({ row }) => {
                return (
                    <small>{transactionUsage(row.original)}</small>
                );
            }
        },
        {
            header: 'Project',
            accessorKey: 'project_title',
            cell: ({ row, getValue }) => {
                if(!getValue()) return '-';
                return (
                    <Link to={`/projects/${row.original.project_uuid}`}>{row.original.project_title}</Link>
                );
            }
        },
        {
            header: 'Date',
            accessorKey: 'created_at',
            cell: ({ getValue }) => {
                return (
					<div>
						{format(
							new Date(getValue()),
							'LLL d, yy'
						)}
						<br/>
						<small className="text-muted small">
							{format(
								new Date(getValue()),
								'HH:mm'
							)}
						</small>
					</div>
				)
            }
        }

    ];

    const table = useReactTable({
		columns,
		data: data,
		getCoreRowModel: getCoreRowModel(),
	});

	if (isLoading) return <Loader />;

	if (error) return <Alert variant="danger">{error.message}</Alert>;

	if(data?.length === 0) return <p className="mb-0">No transactions found</p>;

	return (
		<>
            <StyledTable
				borderless
				hover
				responsive
				$loading={isLoading}
				>
                    <thead>
					{table.getHeaderGroups().map(headerGroup =>
						<tr key={headerGroup.id}>
							{headerGroup.headers.map(header =>
                                <th key={header.id}>
                                    <StyledTableHeader>
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                        )}
                                    </StyledTableHeader>
                                </th>
							)}
						</tr>
					)}
				</thead>
                <tbody>
					{table.getRowModel().rows.map(row =>
                        <tr key={row.id}>
                            {row.getVisibleCells().map(cell => (
                                <td key={`cell-${cell.id}`}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            ))}
                      </tr>
					)}
				</tbody>
            </StyledTable>
		</>
	);
}
export default AreaTransactionsList;
