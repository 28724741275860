import { forwardRef } from 'react';
import styled from 'styled-components';
import Form from 'react-bootstrap/Form';

const Wrapper = styled.div`
	margin-bottom: 1rem;
`;

export const StyledSelect = styled(Form.Select)`
	width: 100%;
	background-color: ${props => props.theme.colors.backgroundInput};
	border: 1px solid ${props => props.theme.colors.borderInput};
	padding: 1rem .75rem;
	color: ${props => props.theme.colors.textColor};
	color-scheme: dark;
`;

// https://react-bootstrap.github.io/docs/forms/select
const Select = forwardRef((props, ref) => {
	const {
		onChange,
		label,
		labelComponent,
		options,
		value,
		defaultValue,
		id,
		...rest
	} = { ...props };

	if (!options || options.length === 0) {
		console.error('Select component requires options prop');
		return null;
	}

	return (
		<Wrapper>
			{labelComponent && labelComponent}

			{!labelComponent && label && (
				<Form.Label htmlFor={id ? id : null}>{label}</Form.Label>
			)}
			<StyledSelect
				onChange={onChange}
				defaultValue={defaultValue && !value ? defaultValue : null}
				aria-label={label ? label : null}
				id={id ? id : null}
				ref={ref}
				{...rest}>
				{options.map(option => (
					<option key={option.value} value={option.value}>
						{option.label}
					</option>
				))}
			</StyledSelect>
		</Wrapper>
	);
});
export default Select;
