import { useState } from 'react';
import styled from 'styled-components';
import { useSearchParams } from 'react-router-dom';

import Form from "react-bootstrap/Form";

import SearchField from "@components/filters/searchField.component";
import DateRange from "@components/filters/dateRange.component";
import Toggle from "@components/form/toggle.component";

import { FilterWrapper } from "@components/admin/shared/SharedComponents";

import useSetSearchParams from "@hooks/useSetSearchParams.hook";

const StyledToggle = styled(Toggle)`
	min-width: 180px;
`;

const ProjectsFilter = ({ loading }) => {

    const [searchParams] = useSearchParams();
    const customSetSearchParams = useSetSearchParams();

    const [showArchived, setShowArchived] = useState(
		searchParams.get('showArchived') === 'true' || false
	);

    return (
        <FilterWrapper>
            <SearchField
                setSearch={val => {
                    customSetSearchParams({ search: val });
                }}
                label="Search"
                placeholder="Title, uuid, userid, email or org-uuid"
                searchParamsId="search"
            />

            <DateRange
                searchParamsId="dateRange"
                setReturnRange={val => {
                    customSetSearchParams({
                        dateRange: val ? JSON.stringify(val) : null,
                    });
                }}
                disabled={loading}
            />

            <Form>
                <StyledToggle
                    id="toggle-archived-filter"
                    type="switch"
                    onChange={e => {
                        customSetSearchParams({
                            showArchived: !showArchived,
                        });
                        setShowArchived(!showArchived);
                    }}
                    label="Archived"
                    checked={showArchived}
                    disabled={loading}
                    variant="dark"
                />
            </Form>

        </FilterWrapper>
    );
};
export default ProjectsFilter;