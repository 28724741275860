import { useState, useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useQuery, useMutation } from '@tanstack/react-query';

import { BiChevronDown } from "react-icons/bi";
import { TbPhotoPin, TbMapPins } from "react-icons/tb";

import Button from 'react-bootstrap/Button';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Spinner from 'react-bootstrap/Spinner';

import {
	createProject,
	archiveProject,
	bulkUploadImagesToS3,
	updateProjectWithS3Usage,
	startPostProcessing,
	getAreaBalance,
	useArea,
	getImageStatistics,
	getUserStorage,
	getUserSubscription,
} from '@api';

import { getExifData } from '@utils/exif';

import useNotify from '@components/notifictions/notify';
import Checkbox from '@components/form/toggle.component';
import Input from '@components/form/Input';
import View from '@components/view/View';
import Alert from '@components/alert/Alert';

import UploadField from './parts/UploadField';
import Storage from './parts/Storage';
import Area from './parts/Area';
import DatasetQuality from './parts/DatasetQuality';
import FootprintAlert from './parts/FootprintAlert';

import { useAuth } from '@contexts/User.context';

const Row = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 40px;
	//align-items: end;
	position: relative;
`;

const LeftCol = styled.div`
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	align-self: stretch;
	gap: 0.25rem;
`;

const RightCol = styled.div`
	min-height: 100%;
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	//justify-content: flex-end;
	gap: 20px;
`;

const Actions = styled.div`
	display: flex;
	gap: 10px;
	justify-content: flex-end;
	width: 100%;
	margin-top: 1em;
`;

const ProjectChoice = styled.div`
	margin-bottom: 2em;
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 1em;

	button{
		transition: all 0.4s;
		position: relative;
		padding-top: 1rem;
		padding-bottom: 4rem;
		svg{
			transition: all 0.4s;
			width: 60px;
			height: 60px;
			margin-bottom: 10px;
			color: ${p => p.theme.colors.textAccent};
		}
		h3{
			margin: 0;
			transition: all 0.4s;
			.small{
				font-size: 0.7em;
			}
		}

		p{
			transition: all 0.2s;
			position: absolute;
			width: 50%;
			left: 25%;
			@media screen and (max-width: 1399px){
				width: 80%;
				left: 10%;
			}
		}
	}

	.project-choise-title{
		display: flex;
		flex-direction: row;
		width: 100%;
		align-items: center;
		justify-content: center;
		gap: 0.5em;
	}

	${p => p.modeselected && `
		button{
			padding-top: 0.5rem;

			&.active{
				background-color: ${p.theme.colors.green};
				svg{
					color: #ffffff;
				}
			}
			&:hover{
				p{
					opacity: 1;
					transition: all 0.8s;
				}
			}
			&:not(:hover){
				padding-bottom: 0rem;

				h3{
					transform: scale(0.7) translateX(-1.5rem);
				}
				svg{
					transform: scale(0.7) translateX(1.5rem);
				}
				p{
					opacity: 0;
					margin-top: -1.5em;
					margin-bottom: 0;
				}
			}
		}
	`}
`;

const StepHeading = styled.h4`
	color: ${p => p.theme.colors.green};
	span{
		color: ${p => p.theme.colors.textAccent};
		font-weight: 300;
	}
`;

const ORTHOPHOTO_MODE = 'orthophoto';
const SINGLE_PHOTO_MODE = 'single-photo';

/**
 *
 * Route for creating a new project
 */
export default function NewProject() {
	const { checkForJobs } = useNotify();

	const { roleAdmin, isEnterprise, tierPro, currentUser, subscription } = useAuth();
	const navigate = useNavigate();

	// Form fields that needs a state
	const [files, setFiles] = useState([]);
	const totalFilesSize = useMemo(
		() => files.reduce((acc, file) => acc + file.size, 0),
		[files.length]
	);
	const [progress, setProgress] = useState(-1);
	const [isOrthophoto, setIsOrthophoto] = useState(false);
	const [mode, setMode] = useState(roleAdmin ? null : ORTHOPHOTO_MODE); // orthophoto or single photo
	const [exifData, setExifData] = useState(null);

	// States that are used for disabling buttons and showing loading
	const [submitting, setSubmitting] = useState(false);
	const [calculatingArea, setCalculatingArea] = useState(false);

	// State for the exif footprint
	const [exifFootprint, setExifFootprint] = useState(null);
	const lowAreaAvailableLimit = 3000;

	// Error state to prevent the user from submitting the form
	const [error, setError] = useState(false);

	// Warning state for the user if there could be some issues with the dataset
	const [warning, setWarning] = useState(false);

	// Get the users available area only if the user is a pro user
	// User access part is only needed under the payment test period
	const areaBalance = useQuery({
		queryKey: ['areaBalance', currentUser?.uuid],
		queryFn: getAreaBalance,
		enabled: tierPro,
		refetchOnWindowFocus: false,
	});

	const storageQuery = useQuery({
		queryKey: ['userStorage'],
		queryFn: getUserStorage,
		enabled: tierPro,
		refetchOnWindowFocus: false,
	});

	// Query for creating a project
	const createProjectMutation = useMutation({
		mutationFn: createProject,
	});

	// Mutation for archiving a project
	const archiveProjectMutation = useMutation({
		mutationFn: projectId => {
			return archiveProject(projectId);
		},
		onError: async err => {
			console.error(err);
		},
	});

	const selectMode = mode => {
		setMode(mode);
		// Reset states when changing mode
		setFiles([]);
		setExifFootprint(null);
		setError(false);
		setWarning(false);
		setExifData(null);
		setIsOrthophoto(false);
	};

	// Submit the project
	const submitProject = async e => {
		e.preventDefault();

		// Prevent submitting if the button is disabled and someone tries to submit
		if (isDisabled()) return;

		// Get the form data
		const formData = new FormData(e.target);
		const projectName = formData.get('projectName');
		const projectDescription = formData.get('projectDescription');
		const dsm = null; //formData.get('dsm') === 'on'; Use this later
		const dtm = null; //formData.get('dsm') === 'on'; Use this later
		const fastOrtophoto = formData.get('fastOrtophoto') === 'on';
		const resolution = formData.get('resolution');
		const ignoreGsd = null; //formData.get('ignoreGsd') === 'on'; Use this later
		const tiles = null; //formData.get('tiles') === 'on'; Use this later
		const meshSize = formData.get('meshSize');

		// If no files exist then return
		if (files.length === 0) return;

		const isOrthophotoProject = mode === ORTHOPHOTO_MODE;
		const isSinglePhotoProject = mode === SINGLE_PHOTO_MODE;

		try {
			// If not orthophoto and files is less than 25 then alert and return. Applicable only for orthophoto procect
			if (isOrthophotoProject && !isOrthophoto && files.length < 25) {
				throw new Error('You need to upload at least 25 images to create a project.');
			} else if (isSinglePhotoProject && files.length < 1) {
				throw new Error('You need to upload at least 1 image to create a project.');
			}

			setSubmitting(true);

			// Create project
			console.log('creating project');

			//@TODO: remove the only Allskog part later
			const wms_publish =
				isEnterprise && currentUser.active_org_name === 'Allskog'; // Only publish WMS for enterprise accounts

			const { uuid: projectId } = await createProjectMutation.mutateAsync(
				{
					title: projectName,
					description: projectDescription,
					file_storage: totalFilesSize,
					wms_publish,
					orthophoto_project: isOrthophotoProject
				}
			);

			if (!projectId) {
				throw new Error('Failed to create project. Please try again.');
			}

			try {
				console.log('uploading images');
				await bulkUploadImagesToS3(files, projectId, setProgress, isOrthophoto);
				console.log('images uploaded');

				console.log('update project with storage usage from S3');
				await updateProjectWithS3Usage(projectId, isOrthophoto);
				console.log('project updated with storage usage from S3');

			} catch (error) {
				console.error('Error uploading images', error);
				const err = new Error('Failed to upload images. Please try again.');
				err.projectId = projectId;
				throw err;
			}

			if (tierPro) {
				try {
					await useArea({
						amount: exifFootprint?.footprint_area,
						note: 'Estimated before finished project processing',
						project_uuid: projectId,
					});
				} catch (error) {
					console.error('Error using area', error);
					const err = new Error('Failed to use area and project could not be created. Please try again.');
					err.projectId = projectId;
					throw err;
				}
			}

			try {
				console.log('starting project post processing');
				await startPostProcessing({
					project_id: projectId,
					image_count: files.length,
					fastOrtophoto,
					isOrthophotoProject,
					dsm,
					dtm,
					resolution,
					ignoreGsd,
					meshSize,
					tiles,
					uploadPreprocessedPhoto: isOrthophoto,
					ortophotoSize: isOrthophoto ? files[0].size : null,
					isSinglePhotoProject,
					exifData,
				});
				console.log('project post processing started');
			} catch (error) {
				console.error('Error starting project post processing', error);
				const err = new Error('Failed to start project post processing. Please try again.');
				err.projectId = projectId;
				throw err;
			}

			console.log('checking for jobs');
			checkForJobs();
			console.log('jobs checked');

			navigate(`/projects`);
		} catch (error) {
			console.error('Project creation error', error);

			// Makes sure that an unfinished project creation is archived if the upload fails
			// projectId is set in the error object because it is not possible to pass it to the catch block otherwise
			if (error.projectId) {
				console.log('Archiving project due to error in project creation');
				archiveProjectMutation.mutate(error.projectId);
			}

			window.alert(error?.response?.data?.detail || error.message || 'Failed to create project. Please try again.');

		} finally {
			setSubmitting(false);
			setProgress(-1);
		}
	};

	// Check if the submit button should be disabled
	const isDisabled = () => {
		if (
			submitting ||
			calculatingArea ||
			((!exifFootprint && !(isEnterprise || roleAdmin)) || (exifFootprint?.error && !(isEnterprise || roleAdmin))) ||
			isNotEnoughStorage ||
			error
		) {
			return true;
		}

		if (exifFootprint?.footprint_area > areaBalance?.data?.area) {
			return true;
		}

		if (areaBalance?.data?.area < lowAreaAvailableLimit) {
			return true;
		}

		return false;
	};

	// Check if the user has enough storage
	const usedStorage = storageQuery?.data?.used ?? null;
	const totalStorage = subscription?.product_metadata?.value ?? 0;
	const isNotEnoughStorage = useMemo(() => {
		if (tierPro) {
			return usedStorage + totalFilesSize > totalStorage;
		}
		return false;
	}, [totalFilesSize]);

	useEffect(() => {
		// Reset error if it exists when files are changed
		if (error) {
			setError(false);
		}

		if (warning) {
			setWarning(false);
		}

		if (files.length > 0) {
			setCalculatingArea(true);

			console.log("Input to getExifData:", files); // Log the input
			getExifData(files)
				.then(async exifData => {
					console.log("Output from getExifData:", exifData); // Log the output
					setExifData(exifData);

					await getImageStatistics(exifData, isOrthophoto).then(
						footprint => {
							if (footprint?.footprint_area) {
								setExifFootprint(footprint);
							}
						}
					);
				})
				.catch(err => {
					console.error(err);

					if (isOrthophoto) {
						console.log('Orthophoto exif could not be processed. Making a manual estimate.');
						const bytesToGB = files[0].size / Math.pow(1024, 3);
						const estimatedArea = bytesToGB * 100000;
						setExifFootprint({
							footprint_area: estimatedArea,
							warning: "We could not preprocess the orthophoto. The area is estimated based on the file size. The actual area will be calculated after the project is processed.",
						});

						return;
					}

					setExifFootprint(null);
					let errMessage = err?.response?.data?.detail ?? err;
					if (isEnterprise || roleAdmin) {
						setWarning(
							<>
								<p>Files could not be pre processed, possibly due to issues in your dataset. This could cause the project to fail.</p>
								{typeof errMessage === 'string' ? (
									<p className="small">{errMessage}</p>
								) : null}
							</>
						);
					} else {
						setError(
							<>
								<p>Files could not be pre processed, possibly due to issues in your dataset.</p>
								{typeof errMessage === 'string' ? (
									<p className="small">{errMessage}</p>
								) : null}
							</>
						);
					}
				})
				.finally(() => {
					setCalculatingArea(false);
				});
		} else {
			// Reset exif footprint if no files are selected
			if (exifFootprint) {
				setExifFootprint(null);
			}
		}
	}, [files]);

	return (
		<View
			title={
				isEnterprise
					? `Create new project for ${currentUser.active_org_name}`
					: 'Create new project'
			}>

			<StepHeading className="my-4">Step 1:
				<span> Choose Project Type</span>
			</StepHeading>
			<ProjectChoice aria-label="Select project type" modeselected={!!mode}>
				<Button
					variant="dark"
					active={mode === ORTHOPHOTO_MODE}
					onClick={() => {selectMode(ORTHOPHOTO_MODE)}}
				>
					<div className="project-choise-title">
						<TbPhotoPin strokeWidth={1} />
						<h3>Orthophoto</h3>
					</div>
					<p>Use an existing orthophoto or stitch together JPGs to make a new one</p>
				</Button>
				<Button
					variant="dark"
					active={mode === SINGLE_PHOTO_MODE}
					onClick={() => {selectMode(SINGLE_PHOTO_MODE)}}
					disabled={!roleAdmin}
				>
					<div className="project-choise-title">
						<TbMapPins strokeWidth={1} />
						<h3>Single Photo {!roleAdmin && (<><span className="small text-muted">(Coming soon)</span></>)}</h3>
					</div>
					<p>
						Positions individual images as markers on the map
					</p>
				</Button>
			</ProjectChoice>

			<hr className="my-5" />

			<StepHeading className="my-4">Step 2:
				<span> Add
					{mode === ORTHOPHOTO_MODE ? (
						<> Orthophoto Project Details</>
					) : mode === SINGLE_PHOTO_MODE ? (
						<> Single Photo Project Details</>
					) : (
						<> Project Details</>
					)}
				</span>
			</StepHeading>

			{!!mode && (
				<form onSubmit={submitProject}>

					<Row>
						<LeftCol>


							<FormInputs
								submitting={submitting}
								files={files}
								setFiles={setFiles}
								isOrthophoto={isOrthophoto}
								isOrthophotoProject={mode === ORTHOPHOTO_MODE}
								setIsOrthophoto={setIsOrthophoto}
							/>

						</LeftCol>

						<RightCol>
							{!tierPro && calculatingArea && (
								<div className="d-flex flex-column gap-3">
									<Spinner />
									<span>Processing...</span>
								</div>
							)}
							<Area
								areaBalanceQuery={
									tierPro
										? areaBalance
										: null
								}
								estimatedArea={exifFootprint?.footprint_area}
								calculatingArea={calculatingArea}
								lowAreaAvailable={
									exifFootprint?.footprint_area >
									areaBalance?.data?.area ||
									areaBalance?.data?.area < lowAreaAvailableLimit
								}
							/>

						{currentUser?.subscription_id &&
							tierPro && (
								<Storage
									projectFilesSize={totalFilesSize}
									userStorageQuery={storageQuery}
									isNotEnoughStorage={isNotEnoughStorage}
								/>
							)}

							{!calculatingArea && exifFootprint && (
								<>
									{!exifFootprint.error && (
										<DatasetQuality
											exifFootprint={exifFootprint}
										/>
									)}
									<FootprintAlert
										warning={exifFootprint.warning}
										error={exifFootprint.error}
									/>
								</>
							)}

							{error && (
								<Alert
									variant="danger"
									heading="Project can't be created">
									{error}
								</Alert>
							)}

							{warning && (
								<Alert
									variant="warning"
									heading="Warning">
									{warning}
								</Alert>
							)}

							{progress >= 0 && (
								<ProgressBar now={progress} variant="success" />
							)}

							{isOrthophoto && submitting && (
								<p className="alert alert-warning mb-4"><strong>Upload process has started.</strong><br />This can take some time and it's important that you do not close this window.</p>
							)}

							<FormActions
								submitting={submitting}
								isDisabled={isDisabled}
							/>

						</RightCol>
					</Row>
				</form>
			)}
		</View>
	);
}


const AdvancedWrapper = styled.div`
	border: 1px solid rgba(${p => p.theme.colors.borderLightRgb}, 0.2);
	padding: 1em 1em 0;
	border-radius: 0.375rem;
`;

const StyledChevron = styled(BiChevronDown)`
	transform: rotate(${p => (p.showadvanced === 'true' ? '-180deg' : '0deg')});
	transition: all 0.3s;
`;
const FormInputs = ({
	submitting,
	files,
	setFiles,
	isOrthophoto,
	isOrthophotoProject,
	setIsOrthophoto
}) => {

	const [showAdvanced, setShowAdvanced] = useState(false);

	return (
		<>
			<Input
				label="Project Name *"
				type="text"
				placeholder="Project Name"
				name="projectName"
				disabled={submitting}
				required
			/>

			<Input
				label="Project Description"
				type="text"
				name="projectDescription"
				placeholder="Project description"
				disabled={submitting}
			/>

			<UploadField
				files={files}
				setFiles={setFiles}
				isOrthophoto={isOrthophoto}
				isOrthophotoProject={isOrthophotoProject}
				setIsOrthophoto={setIsOrthophoto}
				disabled={submitting}
			/>

			{(!isOrthophoto && isOrthophotoProject) && (
				<>
					<div className="mt-3">
						<Button type="button" variant="link-light" onClick={() => { setShowAdvanced(!showAdvanced) }}>
							<span className="text-decoration-underline">Advanced settings <StyledChevron showadvanced={showAdvanced ? 'true' : 'false'} /></span>
						</Button>
					</div>

					{showAdvanced && (
						<AdvancedWrapper>
							<Checkbox
								defaultChecked={false}
								name="fastOrtophoto"
								id="new-project-fast-ortophoto"
								label="Fast Ortophoto"
								helperText="Faster project creation, but without height calculations"
							/>

							{/*
							Kept hidden for now
							<Checkbox
								defaultChecked={true}
								name="dsm"
								id="new-project-dsm"
								label="DSM"
							/>

							<Checkbox
								defaultChecked={false}
								id="new-project-ignore-gsd"
								label="Ignore GSD"
								name="ignoreGsd"
							/>

							<Checkbox
								defaultChecked={true}
								id="new-project-tiles"
								label="Tiles"
								name="tiles"
							/>

							<Input
								label="Resolution"
								step="0.1"
								min="0.01"
								max="10"
								tooltip="Ground resolution surface model and ortophoto"
								type="text"
								defaultValue="0.1"
								name="resolution"
							/>

							<Input
								label="Mesh Size"
								type="number"
								placeholder="Mesh Size"
								name="meshSize"
								defaultValue="200000"
							/> */}

						</AdvancedWrapper>
					)}
				</>
			)}
		</>
	);
};

const FormActions = ({
	submitting,
	isDisabled
}) => {

	return (
		<Actions>
			<Button
				variant="secondary"
				as={Link}
				to="/projects"
				disabled={submitting}>
				Cancel
			</Button>

			<Button
				type="submit"
				variant="success"
				disabled={isDisabled()}>
				{submitting ? (
					<>
						Submitting ...{' '}
						<Spinner
							animation="border"
							variant="light"
							size="sm"
						/>
					</>
				) : (
					'Create project'
				)}
			</Button>
		</Actions>
	);
}
