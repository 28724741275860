import { useQuery } from '@tanstack/react-query';

import { getAreaBalance } from "@utils/api";
import { convertArea } from "@utils/helpers";

const AreaBalance = () => {
	const { data, isLoading, error } = useQuery({
		queryKey: ['area-balance'],
		queryFn: async () => {
			return await getAreaBalance().then(data => {
				const hectares = convertArea(data.area);
				if(hectares || hectares === 0) return hectares + ' hectares';

				return "Could not be calculated";
			})},
	});

	if (isLoading) return null;

	if (error) return <span className="text-danger">{error.message}</span>;

	return <span>{data}</span>;
}

export default AreaBalance;
