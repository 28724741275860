import {
	useReactTable,
	flexRender,
	getCoreRowModel,
	getSortedRowModel,
} from '@tanstack/react-table';

import EmailVerify from './cells/EmailVerify';

import ApproveUser from './cells/ApproveUser';
import DropdownMenu from './modify/DropdownMenu';
import {
	StyledTable,
	StyledTableHeader,
	StickyTableHeaderWrapper,
} from '../shared/SharedComponents';
import CellContent from '../shared/CellContent';

import {TIERS} from '@contexts/User.context';

const Table = ({ data, loading }) => {

	// Define columns for the table
	const columns = [
		{
			header: 'Email',
			accessorKey: 'email',
			copy: true,
		},
		{
			header: 'Email Verified',
			accessorKey: 'verified',
			cell: ({ row, getValue }) => {
				return <EmailVerify cellValue={getValue} email={row.original.email} />;
			},
		},
		{
			header: 'Approved',
			accessorKey: 'verified_by_admin',
			cell: ({ row, getValue }) => {
				return <ApproveUser cellValue={getValue} email={row.original.email} />;
			},
		},
		{
			header: 'Projects',
			accessorKey: 'projects',
			cell: ({ row }) => {
				return <span>{row.original.projects?.length ? row.original.projects?.length : 0}</span>;
			}
		},
		// {
		// 	header: 'Trained models',
		// 	accessorKey: 'user_models',
		// },
		// {
		// 	header: 'Usage',
		// 	accessorKey: '',
		// },
		{
			header: 'Uuid',
			accessorKey: 'uuid',
			copy: true,
		},
		{
			header: 'Tier',
			accessorKey: 'tier',
		},
		{
			header: 'Access',
			accessorKey: 'access',
			cell: ({ row, getValue }) => {
				if (row.original.tier !== TIERS.Premium) {
					return 'All';
				} else {
					return getValue().join(', ');
				}
			},
		},
		{
			header: 'Role',
			accessorKey: 'role',
		},
		{
			header: 'Subscription',
			accessorKey: 'subscription_id',
			copy: true,
		},
		{
			header: 'Organizations',
			accessorKey: 'organizations',
			cell: ({ row }) => {
				const orgs = row.original.organizations;
				if (orgs && orgs.length > 0) {
					return orgs.join(', ');
				} else {
					return '-';
				}
			}
		},
	];


	const table = useReactTable({
		columns,
		data,
		options: {
			manualPagination: true,
		},
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
	});

	return (
		<StickyTableHeaderWrapper>
			<StyledTable
				borderless
				hover
				responsive
				$loading={loading}
				size="sm">
				<thead>
					{table.getHeaderGroups().map(headerGroup => (
						<tr key={headerGroup.id}>
							{headerGroup.headers.map(column => {
								return (
									<th key={column.id}>
										<StyledTableHeader>
											{flexRender(
												column.column.columnDef.header,
												column.getContext()
											)}
										</StyledTableHeader>
									</th>
								);
							})}
							<th colSpan="1" role="columnheader">
								<span className="visually-hidden">
									Dropdownmenu
								</span>
							</th>
						</tr>
					))}
				</thead>
				<tbody>
					{table.getRowModel().rows.map(row => {
						return (
							<tr key={row.id}>
								{row.getVisibleCells().map(cell => {
									return (
										<td
											key={cell.id}
											className="custom-cell">
											<div className={cell.column.id}>
												<CellContent
													cell={cell}
													data={row.original}
												/>
											</div>
										</td>
									);
								})}
								<td role="cell" className="custom-cell">
									<DropdownMenu
										data={row.original}
									/>
								</td>
							</tr>
						);
					})}
				</tbody>
			</StyledTable>
		</StickyTableHeaderWrapper>
	);
};

export default Table;
