import { createContext, useState } from "react";
import { ThemeProvider as StyledComponentsThemeProvider, createGlobalStyle } from "styled-components";

const ThemeContext = createContext({
	setTheme: () => {},
	theme: null,
});

const utils = {
	fullHeight: "calc(100vh - 71px)",
	sidebarWidth: "340px",
}

const darkTheme = {
    colors: {
		darkGreen: "#239442",
		green: "#198754",
		lightGreen: "#90d7ad",
		textColor: "#cfcfcf",
		textAccent: "#989898",
		linkColor: "#1BAC6A",
		linkColorRgb: "27, 172, 106",
		background: "#1c1c1c",
		backgroundRgb: "28, 28, 28",
		backgroundAccent: "#353535",
		backgroundAccentMuted: "#212529",
		backgroundInput: "#171717",
		modal: "#171717",
		border: "#262626",
		borderLight: "#989898",
		borderLightRgb: "152, 152, 152",
		borderInput: "#252525",
		warning: "#e23838"
    },
	utils: {...utils},
};

// const lightTheme = {
//   colors: {
//     darkGreen: "#239442",
//     green: "#198754",
//     lightGreen: "#90d7ad",
//     textColor: "#333333",
//     background: "#e6e6e6",
//     modal: "#f2f2f2",
//     border: "#d9d9d9",
//     warning: "#e23838"
//   },
//   bootstrap: {
//     background: "light",
//     textColor: "text-dark",
//     button: "success",
//   }
// };

export const currentTheme = darkTheme;

const GlobalStyle = createGlobalStyle`

	:root {
		--bs-body-bg: ${props => props.theme.colors.background};
		--bs-body-color: ${props => props.theme.colors.textColor};
		--bs-body-font-family: 'Montserrat', sans-serif;
		--bs-body-line-height: 1.7;

		--bs-secondary-color: ${props => props.theme.colors.textAccent};

		--bs-link-color: ${props => props.theme.colors.linkColor};
		--bs-link-color-rgb: ${props => props.theme.colors.linkColorRgb};
		--bs-link-hover-color-rgb: ${props => props.theme.colors.linkColorRgb};

		--bs-table-bg: transparent;
	}

	a.inherit{
		color: inherit;
	}

	html, body {
		height: 100%;
		min-height: 100vh;
		margin: 0px;
    	padding: 0px;
	}

	body{
		padding-top: 71px; /* Height of navbar */
	}

	h1, .h1 {
		font-size: 28px;
	}

	h2, .h2 {
		font-size: 18px;
	}

	.btn {
		--bs-btn-border-width: 0px;
		--bs-btn-border-radius: 5px;
		--bs-btn-font-weight: 400;

		&.show {
			background-color: ${props => props.theme.colors.backgroundAccent};
		}
		&[class*='btn-outline-'] {
			--bs-btn-border-width: 1px;
		}
	}

	.primary {
		color: ${props => props.theme.colors.textColor};
		background-color: ${props => props.theme.colors.green};
		&:active, &:selected {
			background-color: ${props => props.theme.colors.green};
		}
	}

	.secondary {
		color: ${props => props.theme.colors.textColor};
		background-color: ${props => props.theme.colors.modal};
		&:hover {
			color: ${props => props.theme.colors.lightGreen};
		}
	}

	.form-control:disabled{
		background-color: #343a40;
		color: #ffffff;
		opacity: 0.6;
	}

	.form-check-input {
		border-color: #198754;
		&:checked {
			background-color: #198754;
			border-color: #198754;
		}
	}
`;

export const ThemeProvider = ({ children }) => {

	const [theme, setTheme] = useState(currentTheme);
	const value = { theme, setTheme };

	return (
		<ThemeContext.Provider value={value}>
			<StyledComponentsThemeProvider theme={theme}>
				<GlobalStyle />
				{children}
			</StyledComponentsThemeProvider>
		</ThemeContext.Provider>
	)
};
