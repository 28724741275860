import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Feature } from 'ol';
import { Point, Polygon } from 'ol/geom';
import { fromLonLat } from 'ol/proj';
import { Fill, Stroke, Style, Circle as CircleStyle } from 'ol/style';
import VectorLayer from 'ol/layer/Vector';
import ImageLayer from 'ol/layer/Image';
import VectorSource from 'ol/source/Vector';
import Static from 'ol/source/ImageStatic';
import { Select } from 'ol/interaction';
import { unByKey } from 'ol/Observable';
import { getCenter } from 'ol/extent';

import Checkbox from '../sidebars/sidebarElements/checkbox.component';

import { getGrid, getUnrotatedGrid, getSingleImage } from '@api';
import { useProject } from '@contexts/Project.context';
import { getMasterFeatures } from '@utils/api';
import { convertToRgba } from '@utils/map/helpers';
import { createExtent, imagePositionsToMapCoordinates } from '@utils/helpers';

const SinglePhotoLayer = ({ maxZoomLevel = 23 }) => {
	const {
		colorScheme,
		mapObject,
		defaultProjection,
		project,
		taskId,
		mapTooltip,
		singlePhotosLayer,
		singleImagePolygonVectorSource,
		dispatch,
	} = useProject();
	const { tooltip, tooltipRef } = mapTooltip;

	const adding = useRef(false);
	const pointermovePopupKey = useRef(null);
	const selectInteractionKey = useRef(null);

	const { data, isError, error } = useQuery({
		queryKey: ['grid_tiles', project.uuid],
		queryFn: () => getUnrotatedGrid(project.uuid),
		enabled: !!project?.uuid && project?.image_mode === 'single_image',
		refetchOnWindowFocus: false,
		retry: false,
	});

	const {
		data: masterFeatureData,
		isError: masterFeatureIsError,
		error: masterFeatureError,
	} = useQuery({
		queryKey: ['master_features', project.uuid, taskId],
		queryFn: () =>
			getMasterFeatures(project.uuid, taskId, 'normalized_predictions'),
		enabled: !!project?.uuid && !!taskId,
		refetchOnWindowFocus: false,
		retry: false,
	});

	const iconStyle = isDetect =>
		new Style({
			image: new CircleStyle({
				radius: 5,
				fill: new Fill({ color: isDetect ? 'green' : 'yellow' }),
				stroke: new Stroke({ color: 'black', width: 2 }),
			}),
			zIndex: 14,
		});

	const updatePolygonStyles = useCallback(() => {
		if (!singleImagePolygonVectorSource || !colorScheme) return;
		singleImagePolygonVectorSource.getFeatures()?.forEach(feature => {
			const color = convertToRgba(
				colorScheme[feature.get('classId')].color
			);
			feature.setStyle(
				new Style({
					stroke: new Stroke({
						color: color,
						width: 3,
					}),
					fill: new Fill({
						color: 'rgba(255, 255, 255, 0.2)',
					}),
				})
			);
		});
	});

	const updateSinglePhotoLayer = useCallback(async () => {
		if (data?.features != null && !adding.current) {
			adding.current = true;

			// Create a vector source that contains your features
			const singlePhotoSource = new VectorSource({
				features: [],
			});

			// Create a vector layer that contains the vector source
			const initSinglePhotosLayer = new VectorLayer({
				source: singlePhotoSource,
				visible: true,
				defaultProjection: defaultProjection,
				zIndex: 24,
				maxZoom: maxZoomLevel,
				properties: {
					customLayerId: 'singlePhotosLayer',
				},
			});

			const initImageLayer = new ImageLayer({
				source: null,
				zIndex: 21,
				visible: false,
				image: {
					rotateWithView: true,
				},
			});
			const polygonLayer = new VectorLayer({
				source: null,
				zIndex: 24,
				visible: false,
				name: 'Detection results',
				properties: {
					customLayerId: 'polygonResultsLayer',
				},
			});

			mapObject.addLayer(initSinglePhotosLayer);
			mapObject.addLayer(initImageLayer);
			mapObject.addLayer(polygonLayer);

			const featurePromises = data.features.map(async feature => {
				const image_name = feature.properties.tile_name;
				let image,
					thumbnail = null;
				try {
					//image = await getSingleImage(`filelink?key=${project.uuid}/images/${image_name}`);
					thumbnail = await getSingleImage(
						`filelink?key=${project.uuid}/images/thumbnails/${image_name}`
					);

					if (!thumbnail) return;
				} catch (e) {
					console.error('Could not get images from s3', e);
					return;
				}

				const _feature = new Feature({
					geometry: new Point(
						fromLonLat([
							feature.properties.center_longitude,
							feature.properties.center_latitude,
						])
					),
					image: image,
					thumbnail: thumbnail,
					polygons: feature.geometry.coordinates[0],
					name: feature.properties.tile_name,
					rotation: feature.properties.gimbal_yaw_degrees,
				});
				_feature.setStyle(iconStyle(false));
				singlePhotoSource.addFeature(_feature);
			});

			Promise.all(featurePromises).then(() => {
				// If not features were added, console warn
				if (singlePhotoSource.getFeatures().length === 0) {
					//@TODO: Show a message to the user
					console.warn(
						'No features were added to the single photo layer'
					);
				}
			});

			const pointermoveKey = mapObject.on(
				'pointermove',
				function (event) {
					const feature = mapObject.forEachFeatureAtPixel(
						event.pixel,
						function (feature) {
							return feature;
						}
					);

					if (feature) {
						// Make mouse cursor a pointer
						mapObject.getTargetElement().style.cursor = 'pointer';

						const thumb = feature.get('thumbnail');
						if (!thumb) return;

						tooltipRef.style.padding = '0';
						tooltipRef.style.minWidth = '250px';
						tooltipRef.style.minHeight = '150px';

						const coordinates = feature
							.getGeometry()
							.getCoordinates();
						tooltip.setPosition(coordinates);

						const rotation = feature.get('rotation');
						const rotation2 = rotation > 180 ? '0' : '180';

						tooltipRef.innerHTML = `<img src="${thumb}" style="max-width: 250px; max-height: 250px; transform: rotate(${rotation2}deg);" />`;
						//tooltipRef.style.transform = `rotate(${rotation}deg)`;

						tooltipRef.style.display = 'block';
					} else {
						// Make mouse cursor a pointer
						mapObject.getTargetElement().style.cursor = 'default';
						tooltip.setPosition(undefined);
						tooltipRef.style = '';
					}
				}
			);
			pointermovePopupKey.current = pointermoveKey;

			const selectInteraction = new Select();
			mapObject.addInteraction(selectInteraction);

			const view = mapObject.getView();
			let prevCenter, prevZoom;

			const interactionKey = selectInteraction.on('select', e => {
				tooltip.setPosition(undefined);
				tooltipRef.style = '';

				if (e.selected.length > 0) {
					// A feature was clicked, show the image
					const selectedFeature = e.selected[0];
					const selectedExtent = createExtent(
						selectedFeature.get('polygons')
					);
					const thumbnailUrl = selectedFeature.get('thumbnail');

					const source = new Static({
						url: thumbnailUrl,
						visible: true,
						projection: defaultProjection,
						imageExtent: selectedExtent,
					});
					initSinglePhotosLayer.setVisible(false);
					initImageLayer.setSource(source);

					const polygonVectorSource = new VectorSource();
					polygonLayer.setSource(polygonVectorSource);
					polygonLayer.setVisible(true);

					selectedFeature.get('detections')?.forEach(detection => {
						const polygonPositionTranslatedToMapCoordinates =
							imagePositionsToMapCoordinates(
								detection.geometry.coordinates,
								selectedExtent
							);
						const polygon = new Polygon(
							polygonPositionTranslatedToMapCoordinates
						);
						const polygonFeature = new Feature({
							geometry: polygon,
							data: detection.properties,
							classId: detection.properties.classid,
							className: detection.properties.classname,
							confidence: detection.properties.confidence,
						});
						polygonFeature.setStyle(
							new Style({
								stroke: new Stroke({
									color: convertToRgba(
										colorScheme[
											polygonFeature.get('classId')
										]
									),
									width: 3,
								}),
								fill: new Fill({
									color: 'rgba(255, 255, 255, 0.2)',
								}),
							})
						);
						polygonVectorSource.addFeature(polygonFeature);
					});

					const size = mapObject.getSize();
					const resolution = view.getResolutionForExtent(
						selectedExtent,
						size
					);
					const zoom = view.getZoomForResolution(resolution) - 0.5;
					prevCenter = view.getCenter();
					prevZoom = view.getZoom();

					view.animate({
						center: getCenter(selectedExtent),
						zoom: zoom,
						duration: 300,
					});

					initImageLayer.setVisible(true);

					// Temporary testing below

					tooltipRef.innerHTML = '';
					tooltip.setPosition(undefined);

					dispatch({
						type: 'setSingleImagePolygonVectorSource',
						payload: polygonVectorSource,
					});
				} else {
					initImageLayer.setVisible(false);
					polygonLayer.setVisible(false);
					initSinglePhotosLayer.setVisible(true);
					dispatch({
						type: 'setSingleImagePolygonVectorSource',
						payload: null,
					});

					view.animate({
						center: prevCenter,
						zoom: prevZoom,
						duration: 300,
					});
				}
			});
			dispatch({
				type: 'setSinglePhotosLayer',
				payload: initSinglePhotosLayer,
			});
			dispatch({ type: 'setSingleImageLayer', payload: initImageLayer });
			dispatch({
				type: 'setSingleImagePolygonLayer',
				payload: polygonLayer,
			});
			selectInteractionKey.current = interactionKey;

			adding.current = false;
		}
	}, [data]);

	useEffect(() => {
		return () => {
			// Clean up

			console.log('Cleaning up single photo layer');

			tooltipRef.style = '';
			tooltipRef.innerHTML = '';

			if (pointermovePopupKey.current) {
				unByKey(pointermovePopupKey.current);
				pointermovePopupKey.current = null;
			}

			if (selectInteractionKey.current) {
				unByKey(selectInteractionKey.current);
				selectInteractionKey.current = null;
			}
		};
	}, []);

	useEffect(() => {
		if (singlePhotosLayer != null) {
			const singlePhotosFeatures = singlePhotosLayer
				.getSource()
				.getFeatures();
			if (taskId != null && masterFeatureData?.features != null) {
				const masterFeaturesGroupedByFilename = Map.groupBy(
					masterFeatureData.features,
					feature => feature.properties.filename
				);
				masterFeaturesGroupedByFilename.forEach(
					(masterFeatures, filename) => {
						const featureWithDetects = singlePhotosFeatures.find(
							feature => feature.values_.name === filename
						);
						featureWithDetects?.setStyle(iconStyle(true));
						featureWithDetects?.set('detections', masterFeatures);
					}
				);
			} else {
				singlePhotosFeatures.forEach(feature =>
					feature.setStyle(iconStyle(false))
				);
			}
			dispatch({
				type: 'setSingleImageFeatures',
				payload: singlePhotosFeatures,
			});
		}

		if (isError) {
			console.warn(
				'An error occured displaying detections',
				masterFeatureError
			);
			if (mapObject && singlePhotosLayer) {
				mapObject.removeLayer(singlePhotosLayer);
				dispatch({ type: 'setSinglePhotosLayer', payload: null });
			}
		}
	}, [taskId, masterFeatureData, masterFeatureIsError]);

	useEffect(() => {
		updatePolygonStyles(colorScheme);
	}, [colorScheme, singleImagePolygonVectorSource]);

	useEffect(() => {
		updateSinglePhotoLayer();

		if (isError) {
			console.warn('could not fetch single photo', error);
			if (mapObject && singlePhotosLayer) {
				mapObject.removeLayer(singlePhotosLayer);
				dispatch({ type: 'setSinglePhotosLayer', payload: null });
			}
		}
	}, [updateSinglePhotoLayer, isError]);

	if (!singlePhotosLayer) return null;

	return (
		<div>
			<Checkbox
				label="Single Image"
				canEdit={false}
				defaultState={true}
				handleCheck={() => singlePhotosLayer.setVisible(true)}
				handleUncheck={() => singlePhotosLayer.setVisible(false)}
			/>
		</div>
	);
};

export default SinglePhotoLayer;
