import { useState } from 'react';
import styled from 'styled-components';
import {Alert as BSAlert} from 'react-bootstrap';

const Content = styled.div`
    p:last-child {
        margin-bottom: 0;
    }
`;

/**
 * Wrapper for react-bootstrap Alert component
 * @param variant - string - one of 'primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark'
 * @param heading - string - heading for the alert
 * @param dismissible - boolean - whether the alert is dismissible
 * @returns
 */
const Alert = ({
    variant = 'warning',
    heading,
    dismissible = false,
    children}) => {
    const [show, setShow] = useState(true);

    if((!children && !heading) || !show) return (null);

    return (
        <BSAlert variant={variant} dismissible={dismissible} onClose={() => {setShow(false)}}>
            {heading && <BSAlert.Heading>{heading}</BSAlert.Heading>}
            <Content>
                {children}
            </Content>
        </BSAlert>
    );
}
export default Alert;
