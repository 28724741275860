import { useEffect } from 'react';
import styled from 'styled-components';

import { makeHexagonLayer } from '@utils/map/hexagon.layer';
import HexagonLayer from '../layers/HexagonLayer';
import PhotoLayer from '../layers/PhotoLayer';
import SinglePhotoLayer from '../layers/SinglePhotoLayer';
import PointLayer from '../layers/pointLayer.component';
import PolygonLayer from '../layers/polygonLayer.component';
import AoiLayer from '../layers/AoiLayer';
import TrainingDataLayer from '../layers/TrainingDataLayer';

import FilterHexagon from '../modifyLayers/FilterHexagon';
import FilterPoints from '../modifyLayers/points/FilterPoints';
import HexagonInfo from './HexagonInfo';
import SidebarTemplate from './sidebarElements/SidebarTemplate';
import { useProject, ProjectMode } from '@contexts/Project.context';
import SidebarSummary from './summary/SidebarSummary';
import { removePointLayer } from "@utils/map/point.layer";
import ModelResultDropdown from './sidebarElements/ModelResultDropdown';
import HexagonColorFilter from '../../hexagonColorFilter/HexagonColorFilter';
import HexagonSummarySidebar from './summary/HexagonSummarySidebar';

const LayerList = styled.ul`
	margin: 0;
	list-style: none;
	padding: 20px;
	font-size: 1rem;
	font-weight: 600;
	text-transform: capitalize;
	display: flex;
	flex-direction: column;
	row-gap: 10px;

	ul{
		margin: 5px 0 0 0px;
		padding: 0;
		list-style: none;
		display: flex;
		flex-direction: column;
		> li {
			> div{
				margin-bottom: 10px;
			}
			&:last-child > div{
				margin-bottom: 0;
			}
		}
	}
`;

const LayerListItem = styled.li`
	margin-top: ${p => p.multiLayer ? '15px' : '0'};
`;

const ModelResult = styled.div`
	margin-top: 15px;
`;
const ModelResultHeader = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;
const ModelName = styled.div`
    font-size: 0.9rem;
    margin: 0;
    opacity: 0.7;
    font-weight: 300;
`;

const sidebarIds = {
	layerView: 'layerView',
	modifyHexagons: 'modifyHexagons',
	modifyPoints: 'modifyPoints',
	modifyPolygons: 'modifyPolygons',
	modifyTrainingData: 'modifyTrainingData',
	hexagonColorFilter: 'hexagonColorFilter',
};

/**
 * Renders the sidebars that are responsible for altering the presentation of the map.
 * anything like changing the styles, showing/hiding layers, filtering etc. should be done here.
 */
const LayersSideBar = ({
	hexagonColor,
	setHexagonColor,
	setExportData,
	maxZoomLevel,
}) => {

  	const {
		annotationType,
		pointLayer,
		polygonLayer,
		mapObject,
		hexagonInfo,
		pickedTask,
		features,
		project,
		projectMode,
		setActiveSidebar,
		dispatch,
  	} = useProject();

	useEffect(() => {
		if (annotationType === 'Circle' && polygonLayer) {
			console.log('removing polygon layer');
			mapObject.removeLayer(polygonLayer);
			dispatch({ type: 'setPolygonLayer', payload: null });
		} else if (annotationType === 'Polygon' && pointLayer) {
			console.log('removing point layer');
			removePointLayer();
			dispatch({ type: 'setPointLayer', payload: null });
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [annotationType, pointLayer, polygonLayer]);

	if(!mapObject) return null;

  	return (
		<>
			<SidebarTemplate
				sidebarId={sidebarIds.layerView}
				zindex={1}
				header="Layers"
				closeable={false}
			>
				<LayerList>

					{projectMode === ProjectMode.ORTHOPHOTO && (
						<>
							<LayerListItem>
								<PhotoLayer
									name="Surface map"
									directory="dsm_tiles"
									zIndex={2}
									maxZoomLevel={maxZoomLevel}
									visible={false}
									layerId="dsmLayer"
								/>
							</LayerListItem>

							<LayerListItem>
								<PhotoLayer
									name="Drone Photo"
									directory="orthophoto_tiles"
									checkDirectory={false}
									zIndex={4}
									maxZoomLevel={maxZoomLevel}
									layerId="orthophotoLayer"
								/>
							</LayerListItem>
						</>
					)}

					{projectMode === ProjectMode.SINGLE_IMAGE && (
						<>
							<LayerListItem>
								<SinglePhotoLayer
									maxZoomLevel={maxZoomLevel}
								/>
							</LayerListItem>
						</>
					)}

					{annotationType && (
						<LayerListItem multiLayer>
							<div>Model results</div>

							<ModelResult>
								<ModelResultHeader>
									<ModelName>{pickedTask?.description}</ModelName>
									{features?.length > 0 &&
									<ModelResultDropdown
										setHexagonColorFilterActive={() => setActiveSidebar({sidebarId: sidebarIds.hexagonColorFilter, parentId: sidebarIds.layerView})}
									/>}
								</ModelResultHeader>
								<ul>
									<LayerListItem>
										<AoiLayer />
									</LayerListItem>

									{ annotationType === "Circle" ? (
										<>
											<LayerListItem>
												<PointLayer
													setActiveSidebar={() => setActiveSidebar({sidebarId: sidebarIds.modifyPoints, parentId: sidebarIds.layerView})}
													setExportData={setExportData}
												/>
											</LayerListItem>

											<LayerListItem>
												<TrainingDataLayer
													layerId="trainingDataPointsLayer"
													featureType="annotation_points"
												/>
											</LayerListItem>
										</>
									) : annotationType === "Polygon" ? (
										<>
											<LayerListItem>
												<PolygonLayer
													setActiveSidebar={() => setActiveSidebar({sidebarId: sidebarIds.modifyPolygons, parentId: sidebarIds.layerView})}
													setExportData={setExportData}
												/>
											</LayerListItem>
											<LayerListItem>
												<TrainingDataLayer
													layerId="trainingDataPolygonsLayer"
													featureType="annotation_polygon"
													setActiveSidebar={() => setActiveSidebar({sidebarId: sidebarIds.modifyTrainingData, parentId: sidebarIds.layerView})}
												/>
											</LayerListItem>
										</>
									) : null }

									<LayerListItem>
										<HexagonLayer
											makeHexagonLayer={makeHexagonLayer}
											hexagonColor={hexagonColor}
											setModifyHexagonMode={() => setActiveSidebar({sidebarId: sidebarIds.modifyHexagons, parentId: sidebarIds.layerView})}
										/>
									</LayerListItem>

								</ul>
							</ModelResult>
						</LayerListItem>
					)}
				</LayerList>

				<SidebarSummary
					project={project}
				/>
			</SidebarTemplate>

			<SidebarTemplate
				zindex={2}
				sidebarId={sidebarIds.modifyHexagons}
				header="Modify Hexagons"
				closeable={true}
			>
					<FilterHexagon
						hexagonColor={hexagonColor}
						setHexagonColor={setHexagonColor}
					/>
			</SidebarTemplate>

			<SidebarTemplate
				zindex={2}
				sidebarId={sidebarIds.modifyPoints}
				header="Modify Points"
				closeable={true}
			>
				<FilterPoints />
			</SidebarTemplate>

			<SidebarTemplate
				zindex={2}
				sidebarId={sidebarIds.modifyPolygons}
				header="Modify Polygons"
				closeable={true}
			>
				<FilterPoints />
			</SidebarTemplate>

			{hexagonInfo && (
				<>
					<HexagonInfo />

					<HexagonSummarySidebar />

					<HexagonColorFilter sidebarId={sidebarIds.hexagonColorFilter} />

				</>
			)}
		</>
	);
};

export default LayersSideBar;
