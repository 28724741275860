import { useState } from 'react';
import { useParams, useLocation, Navigate } from 'react-router-dom';
import { useQuery, useMutation } from '@tanstack/react-query';
import { Button, Form } from 'react-bootstrap';

import { getOrganization, updateOrganization } from '@api';

import AdminView from "@components/view/AdminView";
import { useAuth } from '@contexts/User.context';
import { useToast } from '@contexts/Toast.context';

import Input from '@components/form/Input';
import Toggle from '@components/form/toggle.component';
import AdvancedSelect from '@components/form/advancedSelect';

const OrganizationProfile = () => {

    const params = useParams();
    const location = useLocation();
    const { isOrgAdmin, currentUser } = useAuth();
    const { addToast } = useToast();

    const [projectTags, setProjectTags] = useState([]);
    const [showEditAccessModal, setShowEditAccessModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    const fetchOrg = async () => {
        return await getOrganization(params?.organization_uuid);
    }

    const { isLoading, isError, data: organization, error } = useQuery({
		queryKey: ['organization_profile'],
		queryFn: fetchOrg,
	})

    const members = organization?.members?.filter(m => m.role === 'user') ?? [];
    const adminUsers = organization?.members?.filter(m => m.role === 'admin') ?? {};

    const updateOrgMutation = useMutation({
        mutationFn: (updatedOrg) => {
          return updateOrganization(updatedOrg)
        },
        onSuccess: () => {
            addToast({
                id: `org-profile-update-${new Date().getTime()}`,
                title: "Organization profile was updated!",
                bg: "success",
            });
        },
    })

    const handleSubmit = async e => {
		e.preventDefault();

		let updateData = {
			project_tags: projectTags || [],
		};

		if (params.organization_uuid) {
			updateData.uuid = params.organization_uuid;
		}

		Object.keys(e.target.elements).forEach(key => {
			const el = e.target.elements[key];
			if (el.name === 'admin_user' || el.name === 'project_tags') {
				return;
			}

			if (el.value) {
				updateData[el.name] = el.value;
			}
		});

        updateOrgMutation.mutate(updateData);
};

    if(isLoading) return;

    if(!isOrgAdmin){
        return(
            <Navigate to="/unauthorized" state={{ from: location }} replace />
        );
    }

    return (
        <AdminView title={`Organization profile: ${currentUser.active_org_name}`}>

            {isError ? (
                <div className="alert alert-danger">
                    <p>{error?.message}</p>
                </div>
            ) : (

                <>
                {updateOrgMutation.isError ? (
                    <div className="alert alert-danger alert-dismissible">An error occurred: {updateOrgMutation.error.message}</div>
                  ) : null}

                    <form onSubmit={handleSubmit} disabled={updateOrgMutation.isLoading}>
                        <div className="pb-4">
                            <Input
                                label="Name"
                                name="name"
                                defaultValue={organization.name ?? ''}
                                required
                            />

                            <Input
                                label="Description"
                                name="description"
                                defaultValue={organization.description ?? ''}
                            />
                        </div>

                        <div className="pb-4">
                            <Input
                                label="Organization number"
                                type="number"
                                name="org_no"
                                defaultValue={organization.org_no ?? ''}
                            />

                            <Input
                                label="Address"
                                name="address"
                                defaultValue={organization.address ?? ''}
                            />

                            <Input
                                label="Postal code"
                                name="postal_code"
                                defaultValue={organization.postal_code ?? ''}
                            />

                            <Input
                                label="City"
                                name="city"
                                defaultValue={organization.city ?? ''}
                            />

                            <Input
                                label="Country"
                                name="country"
                                defaultValue={organization.country ?? ''}
                            />
                        </div>

                        <div className="pb-4">

                            <div className="mt-4">
                                <h2>Organization admins</h2>
                                {adminUsers?.length > 0 && (
                                    <ul>
                                        {adminUsers?.map(u => (
                                            <li key={u.user_email}>{u.user_email}</li>
                                        ))}
                                    </ul>
                                )}
                            </div>

                            <div className="mt-4">
                                <h2>Members</h2>
                                {members?.length > 0 && (
                                    <ul>
                                        {members?.map(u => (
                                            <li key={u.user_email}>
                                                {u.user_email}
                                                <span className="small text-muted"> ({u.access.map(a => a).join(', ')})</span>
                                                <Button
                                                    variant="link-light"
                                                    size="sm"
                                                    className="ms-2 p-1"
                                                    onClick={() => {
                                                        setShowEditAccessModal(true);
                                                        setSelectedUser({...u});
                                                    }}>
                                                        <span className="text-decoration-underline">Edit access</span>
                                                </Button>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>

                            <p className="small">To add or remove admins or members, <a href="mailto:post@biodrone.no">contact us at post@biodrone.no</a></p>

                        </div>

                        <AdvancedSelect
                            id="organization-tags"
                            label="Project tags (by assigning project tags, users in this organization will be limited to add only these tags to their projects)"
                            noOptionsMessage={() =>
                                'Start typing to add a tag'
                            }
                            value={projectTags?.map((t) => ({
                                value: t,
                                label: t,
                            }))}
                            onChange={e => {
                                setProjectTags(e.map(tag => tag.label));
                            }}
                            isMulti
                            creatable
                        />

                        <div className="mt-5 mb-5">
                            <Button
                                type="submit"
                                variant="success"
                                className="m-1"
                                disabled={isLoading || updateOrgMutation.isLoading}>
                                {updateOrgMutation.isLoading ? 'Saving...' : 'Save'}
                            </Button>
                        </div>
                    </form>

                </>
            )}

            <EditAccessModal
                show={showEditAccessModal}
                user={selectedUser}
                organization={organization}
                updateOrgMutation={updateOrgMutation}
                handleClose={() => {
                    setShowEditAccessModal(false);
                    setSelectedUser(null);
                }}
            />
        </AdminView>
    );
}
export default OrganizationProfile;

import Modal from 'react-bootstrap/Modal';
import { StyledModal } from '@components/modal/sharedModalStyles';

const EditAccessModal = ({ show, handleClose, user, organization, updateOrgMutation}) => {

    const { access } = user ?? {};

    const options = [
        { value: 'detect', label: 'Detect', helperText: 'Allowed to detect objects in a project'},
        { value: 'train', label: 'Train', helperText: 'Allowed to train models in a project'},
    ];

    if(!show && !user) return null;

    return (
        <StyledModal show={show} onHide={handleClose} centered={true}>
            <Modal.Header closeButton>
                <Modal.Title>Edit access for {user.user_email}</Modal.Title>
            </Modal.Header>
            <Form onSubmit={(e) => {
                e.preventDefault();

                const formData = new FormData(e.target);

                const detect = formData.get('detect');
                const train = formData.get('train');

                user.access = ['create']; // Create is required for now
                if(detect) user.access.push('detect');
                if(train) user.access.push('train');

                // Replace user in organization with updated user
                const updatedMembers = organization.members.map(m => m.user_uuid === user.user_uuid ? user : m);
                organization.members = updatedMembers;

                updateOrgMutation.mutate(organization);
                handleClose();

            }}>
                <Modal.Body>
                    <Toggle
                        type="switch"
                        id={`access-default`}
                        name="create"
                        label="Create"
                        helperText="Allowed to create a project"
                        defaultChecked={true}
                        disabled
                    />
                    {options.map((o, i) => (
                        <Toggle
                            type="switch"
                            id={`access-${i}`}
                            key={`access-${i}`}
                            name={o.value}
                            label={o.label}
                            helperText={o.helperText}
                            defaultChecked={access.some(accessItem => accessItem === o.value)}
                        />
                    ))}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" type="button" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="success" type="submit">
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Form>
        </StyledModal>
    )
}
