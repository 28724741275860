import styled from 'styled-components';

const ProgressWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    min-height: 100%;
`;
const SvgCircle = styled.svg`
    align-self: center;
`;
const BackgroundCircle = styled.circle`
    fill: none;
    stroke: rgba(255,255,255,0.1);
`;
const ProgressCircle = styled.circle`
    fill: none;
    stroke: ${p => p.theme.colors.green};

    stroke-linecap: round;
    stroke-linejoin: round;

    stroke-dasharray: ${p => p.dashArray};
    stroke-dashoffset: ${p => p.dashOffset};
    transition: stroke-dashoffset 0.5s ease 0s;
`;
const CircularProgress = ({size, strokeWidth, progress, status}) => {
  
      // Size of the enclosing square
      //const size = this.props.size;
      // SVG centers the stroke width on the radius, subtract out so circle fits in square
      const radius = (size - strokeWidth) / 2;
      // Enclose cicle in a circumscribing square
      const viewBox = `0 0 ${size} ${size}`;
      // Arc length at 100% coverage is the circle circumference
      const dashArray = radius * Math.PI * 2;
      // Scale 100% coverage overlay with the actual percent
      const dashOffset = dashArray - dashArray * progress / 100;


      const getStatusText = () => {
            switch(status){
                case 'pending':
                    return 'Pending';
                case 'running':
                    return 'Processing';
                case 'finished':
                    if(progress === 100){
                        return 'Completed';
                    }
                    return 'Aborted';
                default:
                    return 'Pending';
            }
      }
  
      return (
        <ProgressWrapper>
            <div>
                {getStatusText()}
                {status === 'running' && (<> <strong>{progress}%</strong></>)}
            </div>
            <SvgCircle
                width={size}
                height={size}
                viewBox={viewBox}
                role="progressbar" 
                aria-valuenow={progress} 
                aria-valuemin="0" 
                aria-valuemax="100" 
                tabIndex="0">

                    <BackgroundCircle
                        cx={size / 2}
                        cy={size / 2}
                        r={radius}
                        strokeWidth={`${strokeWidth}px`} />

                    <ProgressCircle
                        cx={size / 2}
                        cy={size / 2}
                        r={radius}
                        strokeWidth={`${strokeWidth}px`}
                        // Start progress marker at 12 O'Clock
                        transform={`rotate(-90 ${size / 2} ${size / 2})`}
                        size={size}
                        dashOffset={dashOffset}
                        dashArray={dashArray}
                        />
            </SvgCircle>
        </ProgressWrapper>
      );
    
  }

  export default CircularProgress;