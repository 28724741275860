import { useEffect, useMemo } from 'react';
import { useSearchParams, useParams, Link, useLocation, Navigate } from 'react-router-dom';
import { useInfiniteQuery } from '@tanstack/react-query';
import {
	useReactTable,
	flexRender,
	getCoreRowModel,
	getSortedRowModel,
} from '@tanstack/react-table';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

import { getOrganizationsProjects } from '@api';
import { formatAreaNumber } from '@utils/helpers';
import { useAuth } from '@contexts/User.context';

import AdminView from "@components/view/AdminView";

import Loader from '@components/common/Loader';
import { FooterText, StickyTableHeaderWrapper, StyledTable, StyledTableHeader } from '@components/admin/shared/SharedComponents';
import CellContent from '@components/admin/shared/CellContent';
import CopyButton from '@components/admin/shared/CopyButton';
import ProjectThumbnail from '@components/project/projectThumbnail/ProjectThumbnail';
import ProjectsFilter from '@components/project/projectsFilter/ProjectsFilterAdmin';


const OrganizationProjects = () => {
    const params = useParams();
    const location = useLocation();
	const { isOrgAdmin, currentUser } = useAuth();
    const [searchParams] = useSearchParams();
    
    const fetchOrgProjects = async ({ pageParam = 0 }) => {
		const search = searchParams.get('search');
		const archived = searchParams.get('showArchived');
		const range = searchParams.get('dateRange');
       
		return await getOrganizationsProjects({
			offset: pageParam ?? 0,
			limit: 50,
			search,
			archived,
			dateRange: range ? JSON.parse(range) : null,
            organization_uuid: params.organization_uuid,
		});
	}

    const {
		data,
		error,
		fetchNextPage,
		hasNextPage,
		isFetching,
		isFetchingNextPage,
		refetch,
	  } = useInfiniteQuery({
		queryKey: ['org_admin_projects', params?.organization_uuid],
		queryFn: fetchOrgProjects,
		getNextPageParam: (lastPage, allPages) => {
			if (Math.ceil(lastPage.total / lastPage.limit) > allPages.length) {
            	return lastPage.offset + lastPage.projects?.length;
			}
         	return undefined;
		},
		refetchOnWindowFocus: false,
		enabled: !!params?.organization_uuid,
	})

    const [projects, total, totalArea] = useMemo(() => {
		const projects = data?.pages?.flatMap(page => page.projects) ?? [];
		const total = data?.pages?.[data.pages.length - 1]?.total ?? 0;

		let totalArea = 0;
		projects.forEach(project => {
			//If area is not -1, add it to the total
			if (project.area !== -1){
				totalArea += project.area;
			}
		});

		return [projects, total, totalArea];
	}, [data]);

    useEffect(() => {
		if(isFetching) return;
		refetch(); // Call refetch() when searchParams change
	}, [searchParams]);

	if(!isOrgAdmin){
        return(
            <Navigate to="/unauthorized" state={{ from: location }} replace />
        );
    }

    return (
        <AdminView title={`All ${currentUser.active_org_name} projects`} className="position-relative">

			<ProjectsFilter loading={isFetching} />

            {isFetching && <Loader />}

            {!isFetching && error && (
				<div className="alert alert-danger" role="alert">
					Something went wrong:{' '}
					{error.response?.data?.detail ?? error.message ?? 'Unknown error'}
				</div>
			)}

            {!isFetching && !error && projects?.length === 0 && (
                <div className="mb-5">No projects found</div>
            )}

            {projects?.length > 0 && (
                <>

                    <AdminOrgProjectsTable
                        data={projects}
                        loading={isFetching}
                    />

                    <footer className="col-md-12 mb-5">
                        <div className="d-flex justify-content-between mb-4">
							<FooterText>Total area for selected filters: <span>{formatAreaNumber(totalArea)}</span></FooterText>

                            <FooterText>
                                Showing {projects?.length} of {total}
                            </FooterText>
                        </div>

                        {projects?.length < total && (
                            <div className="text-center">
                                <Button
                                    variant="success"
                                    className="mt-2 mb-2 col-6"
                                    disabled={!hasNextPage || isFetchingNextPage}
                                    onClick={() => fetchNextPage()}
                                    >
                                    <span>Load more</span>{' '}
                                    {isFetchingNextPage && (
                                        <Spinner
                                            animation="border"
                                            variant="light"
                                            size="sm"
                                        />
                                    )}
                                </Button>
                            </div>
                        )}
                    </footer>
                </>
            )}
        </AdminView>
    );
}
export default OrganizationProjects;


const AdminOrgProjectsTable = ({data, loading}) => {

    // Define columns for the table
	const columns = [
		{
			header: 'Title',
			accessorKey: 'title',
			cell: ({ row, getValue }) => {
				// If title return link with tiltle and thumbnail
				return (
					<Link to={`/projects/${row.original.uuid}`} title="View on map">
						<ProjectThumbnail project_uuid={row.original.uuid} />
						{getValue()}
					</Link>
				);
			},
		},
		{
			header: 'Uuid',
			accessorKey: 'uuid',
			copy: true,
		},
		{
			header: 'Owner',
			accessorKey: 'owner',
			cell: ({ getValue }) => {
				// If is owner, return a button that copies the cell value to the clipboard
				const value = getValue();
				if (!value) return null;

				return (
					<div className="uuid">
						{value?.user_name}
						<br />
						<CopyButton value={value?.user_uuid}>
							<span>User id: {value?.user_uuid}</span>
						</CopyButton>
					</div>
				);
			},
		},
		{
			header: 'Created',
			accessorKey: 'created_at',
			dateFormat: 'LLL d, yy',
		},
		{
			header: 'Area',
			accessorKey: 'area',
			getFixedNumber: true,
		},
	];

	const table = useReactTable({
		columns,
		data,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
	});

    return(
        <StickyTableHeaderWrapper>
			<StyledTable
				borderless
				hover
				responsive
				$loading={loading}
				size="sm">
				<thead>
					{table.getHeaderGroups().map(headerGroup => (
						<tr key={headerGroup.id}>
							{headerGroup.headers.map(column => {
								if (column.id === 'deleted') return null;
								return (
									<th key={column.id}>
										<StyledTableHeader>
											{flexRender(
												column.column.columnDef.header,
												column.getContext()
											)}
										</StyledTableHeader>
									</th>
								);
							})}
							<th colSpan="1" role="columnheader">
								<span className="visually-hidden">
									Dropdownmenu
								</span>
							</th>
						</tr>
					))}
				</thead>
				<tbody>
					{table.getRowModel().rows.map(row => {
						return (
							<tr
								key={row.id}
								className={
									row.original.deleted ? 'archived' : ''
								}>
								{row.getVisibleCells().map(cell => {
									if (cell.column.id === 'deleted')
										return null;

									return (
										<td
											key={cell.id}
											className="custom-cell">
											<div className={cell.column.id}>
                                                
												<CellContent
													cell={cell}
													data={row.original}
												/>
											</div>
										</td>
									);
								})}
								<td role="cell" className="custom-cell">
									{/* <DropdownMenu data={row.original} /> */}
								</td>
							</tr>
						);
					})}
				</tbody>
			</StyledTable>
		</StickyTableHeaderWrapper>
    );
};