import Button from 'react-bootstrap/Button';
import { IoBuild } from 'react-icons/io5';
import { Tooltip } from 'react-tooltip';
import { getArea } from 'ol/sphere';

import { useAuth } from '@contexts/User.context';
import { ProjectMode, useProject } from '@contexts/Project.context';

import { setupFreehandDraw, removeFreehandDraw, checkAreaOfInterest } from '@utils/map/freeHand.draw';
import { clearTooltip } from '@utils/map/tooltip.overlay';

import { getGrid } from '@api';
import AnnotateOrthophoto from '../ai/AnnotateOrthophoto';
import AnnotateSingleImages from '../ai/AnnotateSingleImages';
import { setLayersVisibilityByCustomIds } from '@utils/map/helpers';

const annotationSidebarId = 'annotationSidebar';

export default function Annotate() {

	const {
		project,
		projectMode,
        features,
		tasks,
        pickedTask,
        toolBarVisible,
		isDemo,
		mapObject,
		mapTooltip,
		annotationMode,
		setActiveSidebar,
		removeFromActiveSidebars,
		dispatch,
    } = useProject();
	const {tooltip, tooltipRef} = mapTooltip;

    const {
        tierTrial,
        trialEnded,
		tierPremium,
		tierPro,
		subscriptionActive,
		isEnterprise,
		activeOrgAccess,
		userAccess
    } = useAuth();

	// layers to hide during annotation
	const layersToHide = ['aoiLayer', 'dsmLayer', 'pointsResultsLayer', 'polygonResultsLayer', 'hexagonLayer', 'trainingDataPointsLayer'];
	// layers to show after annotation is stopped
	const layersToShow = ['aoiLayer', 'pointsResultsLayer', 'polygonResultsLayer', 'hexagonLayer'];

	const annotationButtonTooltipText = () => {

        if(tierTrial){
            if(trialEnded) return 'Your trial has ended and you can no longer annotate';
            if(pickedTask?.multimodel) return 'You can not train a multimodel as a trial user';
            if(pickedTask?.is_trained) return 'You can not improve accuracy as a trial user';
        }

        if(features.length === 0) return 'Make manual annotations to train the AI model';

        return 'Enhance AI accuracy by fine-tuning the annotations';
    }

	const checkTrainingPermissions = async () => {
		if(tierTrial){

            let alertText = null;
            if(trialEnded){
                alertText ='Your trial has ended and you can no longer annotate';
            } else if(pickedTask?.multimodel) {
                alertText = 'You can not train a multimodel as a trial user';
            } else if(pickedTask?.is_trained) {
                alertText = 'You can not improve accuracy as a trial user';
            } else {
                // Use mapObject.getLayers() to check if training data layer exists
                const layers = mapObject.getLayers();
                layers?.forEach(layer => {
                    const name = layer.get('name');
                    if(name === 'Training data'){
                        alertText = 'Training data exists. You can only train a model once as a trial user.';
                    }
                });
            }
            if(alertText){
                window.alert(alertText);
                return false;
            }
        }
        else if(tierPremium && !userAccess?.includes("train")) {
            // Check if userAccess array contains "train"
            alert('Your user does not have access rights to to train AI');
            return false;
        }
		else if( tierPro && !subscriptionActive){
			alert('Your user needs an active subscription to train AI');
            return false;
		}

        if(isEnterprise){
            // Check if activeOrgAccess array contains "all" or "train"
            if(!activeOrgAccess.includes("all") && !activeOrgAccess.includes("train")) {
                alert('Your user does not have access rights to train AI');
                return false;
            }
        }

		return true;
	}

	const prepareTraining = async () => {

		const isTrainingAllowed = await checkTrainingPermissions();
		if (!isTrainingAllowed) return;

		const grid = await fetchGrid(project.uuid);
		if (!grid || grid.length === 0) {
            console.error('No squares to check');
            window.alert('An error accured and training could not be started. Please try again later.');
            return;
        }

		// Hide all layers that should not be visible during annotation
		setLayersVisibilityByCustomIds(mapObject, layersToHide, false);

		dispatch({
			type: 'setToolBarVisible',
			payload: false,
		});

		dispatch({
			type: 'setDialogue',
			payload: {
				header: "Set a boundary",
				body: "Please draw a boundary around the area in the map where you want to make annotations. Press ESC to cancel.",
			},
		});

		const { draw, layer, source } = setupDraw({mapObject, tooltip, tooltipRef});

		// Make it possible to cancel the draw by pressing ESC
		const escapeFunction = (e) => {
			if (e.key === 'Escape') {
				cleanupDraw({draw, layer});
				dispatch({
					type: 'setToolBarVisible',
					payload: true,
				});
				// Remove the event listener after it has been used
				window.removeEventListener('keydown', escapeFunction);
			}
		}
		window.addEventListener('keydown', escapeFunction);

		// Draw the area of interest
        // Recursively run the promise until it returns true
        const drawAreaUntilTrue = async() => {
            const gridFeatures = await drawAOI({grid, draw, source, mapTooltip, mode: projectMode});
            if (!gridFeatures) {
                await drawAreaUntilTrue(); // Recurse
				return;
            }

			// Clear the draw interaction and source
			cleanupDraw({draw, layer});
			// Remove the event listener bc it's no longer needed
			window.removeEventListener('keydown', escapeFunction);

			// Allow training to start
			allowTraining(gridFeatures);
        }
        drawAreaUntilTrue();
	}

	const allowTraining = (gridFeatures) => {

		let taskLabels = null; // e.g. ["bar", "lauv"]
		let taskIds = null;

		if(tasks){
			for (let i = 0; i < tasks.length; i++) {
				if (tasks[i].model_uuid === pickedTask.model_uuid) {
					taskLabels = tasks[i].classes.map((task) => task.description);
					break;
				}
			}
		}
		taskIds = Array.from(Array(taskLabels.length).keys());

		dispatch({
			type: 'setAnnotationMode',
			payload: {
				gridFeatures,
			},
		})

		dispatch({
			type: 'setAnnotationSidebarData',
			payload:{
				classIds: taskIds,
				labels: taskLabels,
				activeClassId: taskIds[0],
			},
		})

		setActiveSidebar({sidebarId: annotationSidebarId });
	}

	const cleanupDraw = ({draw, layer}) => {
		// Clear the draw interaction and source
		dispatch({
			type: 'setDialogue',
			payload: null,
		});

		removeFreehandDraw({ mapRef: mapObject, draw, layer });
		clearTooltip({...mapTooltip});

	}

	if(!mapObject || !pickedTask) return null;

	if(!!annotationMode){

		if(projectMode === ProjectMode.ORTHOPHOTO){
			return (
				<AnnotateOrthophoto
					layersToShow={layersToShow}
					hideAnnotationSidebar={() => {removeFromActiveSidebars(annotationSidebarId)}}
				/>
			);
		} else if(projectMode === ProjectMode.SINGLE_IMAGE){
			return (
				<AnnotateSingleImages layersToShow={layersToShow} />
			);
		}
	}

	return (

		<>
			<Tooltip id="annotate" variant="light" />
			<Button
				disabled={!toolBarVisible || isDemo}
				onClick={prepareTraining}
				className=" btn-dark secondary"
				data-tooltip-id="tooltip-toolbar-root"
				data-tooltip-content={annotationButtonTooltipText()}
				data-tooltip-place="top"
			>
				<IoBuild
					className="m-1 mb-2"
					style={{ transform: 'rotate(-90deg)' }}
				/>
				{features.length === 0 ? "Train AI" : "Improve AI Accuracy"}
			</Button>

		</>

	);
};

/**
 * Format area output.
 * @param {Polygon} polygon The polygon.
 * @return {string} Formatted area.
 */
const formatArea = (polygon) => {
	const area = getArea(polygon);
	let output;
	if (area > 10000) {
		output =
			Math.round((area / 1000000) * 100) / 100 + ' ' + 'km<sup>2</sup>';
	} else {
		output = Math.round(area * 100) / 100 + ' ' + 'm<sup>2</sup>';
	}
	return output;
};


const setupDraw = ({mapObject,tooltip, tooltipRef}) => {
	// Setup draw interaction
	const { draw, layer, source } = setupFreehandDraw({
		type: 'Polygon',
		mapRef: mapObject
	});

	const freehandRef = {
		draw,
		layer,
		source
	};

	// Add drawstart event listener add tooltip to the map
	draw.on('drawstart', function (e) {
		const sketch = e.feature;

		sketch.getGeometry().on('change', function (evt) {
			const geom = evt.target;

			// Format area output
			const output = formatArea(geom);
			tooltipRef.innerHTML = output;

			const tooltipCoord = geom.getInteriorPoint().getCoordinates();
			tooltip.setPosition(tooltipCoord);
		});
	});

	return freehandRef;
}

const fetchGrid = async (project_uuid) => {
	try {
		const data = await getGrid(project_uuid);
		if (data.features) {
			return data.features;
		} else {
			return [];
		}
	} catch (error) {
		console.error(error);
		return [];
	}
}

const drawAOI = async ({grid, draw, source, mapTooltip, mode}) => {
	return new Promise((resolve, reject) => {
		checkAreaOfInterest({ mode, grid, draw }).then((coordinates) => {
			resolve(coordinates);
		}).catch((error) => {
			console.error(error);
			window.alert(error);

			// Clear the draw interaction and source
			source.clear();
			clearTooltip({...mapTooltip});

			resolve(false);
		});
	});
}
