import _ from 'lodash';

/**
 * Helper function to setup the layer details
 *
 * @param {*} features
 * @param {*} colorOptions
 * @returns Object with labels, classIds, and colorStyles
 */
export const setupLayerDetails = (features, colorOptions) => {
	if (features.length === 0) {
		return {
			labels: [],
			classIds: [],
			colorStyles: [],
		};
	}

	const newLabels = [
		...new Set(
			features.map(feature => {
				return feature.properties.classname;
			})
		),
	];

	const newClassIds = [
		...new Set(
			features.map(feature => {
				return feature.properties.classid;
			})
		),
	];

	const modelClasses = _.uniqBy(
		features.map(feature => ({
			id: feature.properties.classid,
			label: feature.properties.classname,
		})),
		item => `${item.id}-${item.label}`
	);

	const colorStyles = {};
	newClassIds.forEach((pointId, index) => {
		// Using the index to get the correct color from the colorOptions
		// Using the pointId as the key for the colorStyles object to make it easier to find the color for a given pointId
		colorStyles[pointId] = {
			color: colorOptions[index],
			visible: true,
		};
	});

	return {
		modelClasses: modelClasses,
		labels: newLabels,
		classIds: newClassIds,
		colorStyles: colorStyles,
	};
};

export const convertToRgba = color => {
	if (!color) return null;

	const { r, g, b, a } = color;

	return `rgba(${r}, ${g}, ${b}, ${a})`;
};

export const webGLPointsStyleColor = (pointsColor, classIds) => {
	if (!classIds || classIds.length === 0 || !pointsColor) return null;

	// if there is only one classId, return the color for that classId. This is to avoid the 'interpolate' style.
	if (classIds.length === 1) {
		if (pointsColor[classIds[0]]?.visible) {
			return convertToRgba(pointsColor[classIds[0]].color);
		} else {
			return 'rgba(255, 255, 255, 0)';
		}
	}

	// if there are more than one classId, return the color for each classId
	const style = ['match', ['get', 'classId']];

	classIds.forEach(classId => {
		style.push(classId);

		if (
			pointsColor[classId]?.visible ||
			pointsColor[classId]?.visible === undefined
		) {
			style.push(
				convertToRgba(pointsColor[classId]?.color) ||
					convertToRgba(pointsColor[classId]?.value) ||
					'rgba(255, 255, 255, )'
			);
		} else {
			style.push('rgba(255, 255, 255, 0)');
		}
	});
	style.push('rgba(255, 255, 255, 0)'); // default color if no match

	return style;
};

export const deleteLayerByCustomId = (mapObject, customLayerId) => {
	if (!mapObject) return;

	const isLayerDeleted = mapObject
		.getLayers()
		.getArray()
		.some(layer => {
			const layerCustomId = layer.getProperties()?.customLayerId;
			if (layerCustomId === customLayerId) {
				mapObject.removeLayer(layer);
				return true;
			}
			return false;
		});

	return isLayerDeleted;
};

export const getLayerByCustomId = (mapObject, customLayerId) => {
	if (!mapObject) return;

	const layer = mapObject
		.getLayers()
		.getArray()
		.find(layer => {
			const layerCustomId = layer.getProperties()?.customLayerId;
			return layerCustomId === customLayerId;
		});

	return layer;
};

export const setLayersVisibilityByCustomIds = (
	mapObject,
	customLayerIds,
	visibility
) => {
	if (!mapObject) return;

	mapObject
		.getLayers()
		.getArray()
		.forEach(layer => {
			const layerCustomId = layer.getProperties()?.customLayerId;
			if (customLayerIds.includes(layerCustomId)) {
				layer.setVisible(visibility);
			}
		});
};
