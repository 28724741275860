import { useMutation } from '@tanstack/react-query';
import { useForm, Controller } from 'react-hook-form';

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

import { updateUser, requestPasswordReset } from "@api";
import { useAuth } from "@contexts/User.context";

import AdminView from "@components/view/AdminView";
import Input from "@components/form/Input";
import Alert from '@components/alert/Alert';
import { useEffect } from 'react';

/**
 * Edit profile page
 */
const Profile = () => {
    const { currentUser, refetchCurrentUser } = useAuth();
	const { control, handleSubmit, setFocus } = useForm({
		defaultValues: {
			name: currentUser.name ?? '',
			email: currentUser.email ?? '',
		},
	})

	const updateUserMutation = useMutation({
        mutationFn: (name) => updateUser({ name }),
		onSuccess: refetchCurrentUser,
    })

	const passwordResetMutation = useMutation({
        mutationFn: () => requestPasswordReset(currentUser?.email)
    })

    const handlePasswordReset = (event) => {
		event.preventDefault();
		passwordResetMutation.mutate();
	};

	const onSubmit = (values) => {
		const {name} = values;
		updateUserMutation.mutate(name);
	};

	useEffect(() => {
		if(!currentUser?.name) {
			setFocus('name');
		}
	}, [setFocus]);

    return <AdminView maxWidth={7} title="Profile">
		{updateUserMutation.isSuccess && (
			<Alert variant="success" heading="User updated">
				<p>Your user profile has been updated.</p>
			</Alert>
		)}

		{updateUserMutation.isError && (
			<Alert variant="danger" heading="Error updating user">
				<p>Something went wrong and your user profile could not be updated.</p>
			</Alert>
		)}

		{!currentUser?.name && (
			<Alert variant="warning" heading="Missing name">
				<p>Your user profile is missing a name. Please update your profile to include your name.</p>
			</Alert>
		)}

        <Form onSubmit={handleSubmit(onSubmit)}>
			<Controller
				className="mb-3"
				name="name"
				control={control}
				render={({ field }) => <Input
					{...field}
					type="text"
					placeholder="Enter name"
					label="Name"
					autoComplete="name"
					required
				/>}
			/>

			<Controller
				className="mb-3"
				name="email"
				control={control}
				render={({ field }) => <Input
					{...field}
					type="email"
					placeholder="Enter email"
					label="Email"
					disabled
					required
				/>}
			/>

			<Button type="submit" variant="success" disabled={updateUserMutation.isLoading}>Update User</Button>

            <Form.Group className="mb-3 mt-5" controlId="formResetPassword">
                <Form.Label>Password</Form.Label>
                <div>
                    <Button
                        variant="link p-0"
                        onClick={handlePasswordReset}
                        disabled={passwordResetMutation.isLoading || passwordResetMutation.isSuccess}>
                            Request password reset {passwordResetMutation.isLoading && <Spinner size="sm" className="mx-2" />}
                    </Button>
                    {passwordResetMutation.isSuccess && (
                        <>
                            <p>Password reset request received and an email has been sendt to <strong>{currentUser.email}</strong>.</p>
                            <p className="mb-0">Check your email and follow the instructions.</p>
                        </>
                    )}
                    {passwordResetMutation.isError && (
                        <p className="text-danger mt-2">Something went wrong and your password could not be reset.</p>
                    )}
                </div>
            </Form.Group>
        </Form>

    </AdminView>;
};

export default Profile;
