import { useEffect, useState, useMemo } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { Link, useSearchParams } from 'react-router-dom';

import { Tooltip } from "react-tooltip";
import { Alert, ButtonGroup, Button, Spinner } from 'react-bootstrap';
import { AiOutlineFilter, AiFillPlusCircle } from 'react-icons/ai';

import styled from 'styled-components';

import ProjectCard from '@components/project/projectCard/ProjectCard';
import ProjectsFilter from '@components/project/projectsFilter/ProjectsFilterUser';
import Toolbar from '@components/toolbar/toolbar.component';
import View from '@components/view/View';
import Loader from '@components/common/Loader';

import { useAuth } from '@contexts/User.context';
import { getProjects } from '@api';

const FilterButtonWrapper = styled.div`
	text-align: right;
	.btn {
		border: 1px solid hsl(0, 0%, 30%);
		display: inline-flex;
		align-items: center;
	}
`;
const ProjectCount = styled.div`
	font-size: 0.9rem;
	font-weight: bold;
	text-align: right;
`;
const NoProjectsMessages = styled.p`
	padding: 20px 0;
	text-align: center;
	font-size: 1.2rem;
`;

const Projects = () => {
	const [searchParams] = useSearchParams();
	const tags = searchParams.getAll('tags');
	const search = searchParams.get('search');

	const {
		currentUser,
		tierTrial,
		trialEnded,
		tierPro,
		subscriptionLoading,
		subscriptionActive
	} = useAuth();

	const [isNewUser, setIsNewUser] = useState(true);
	const [showFilters, setShowFilters] = useState(true);

	const tooltipContent = tierTrial
	? trialEnded
		? "Your trial period has ended"
		: "You can create one project during the trial period"
	: null;

	const newProjectButton = () => <Button
		variant="success"
		className="mt-2 mb-2"
		size="sm"
		data-tooltip-id="tooltip-projects-root"
		data-tooltip-content={tooltipContent}
		data-tooltip-place="top"
		as={Link}
		to="/projects/new"
		disabled={tierTrial && trialEnded}
	>
		<AiFillPlusCircle className="m-1 mb-2" />
		<span>New Project</span>
	</Button>

	const fetchProjects = async ({ pageParam = 0 }) => {

		// If user is tierPro and subscription is not active, redirect to home
		if(tierPro && !subscriptionActive){
			window.location.href = '/';
		}

		return await getProjects({
			tags,
			offset: pageParam ?? 0,
			limit: 12,
			search,
		});
	}

	const {
		data,
		error,
		fetchNextPage,
		hasNextPage,
		isFetching,
		isFetchingNextPage,
		refetch,
	  } = useInfiniteQuery({
		queryKey: ['user_projects', currentUser?.active_org_uuid],
		queryFn: fetchProjects,
		getNextPageParam: (lastPage, allPages) => {
			if (Math.ceil(lastPage.total / lastPage.limit) > allPages.length) {
            	return lastPage.offset + lastPage.projects?.length;
			}
         	return undefined;
		},
		refetchOnWindowFocus: false,
		enabled: !!currentUser?.loggedIn,
	})

	const [projects, total] = useMemo(() => {
		const projects = data?.pages?.flatMap(page => page.projects) ?? [];
		const total = data?.pages?.[data.pages.length - 1]?.total ?? 0;

		if((tags.length === 0 && !search) && projects.length === 0 && !error){
			setIsNewUser(true);
		} else {
			setIsNewUser(false);
		}

		return [projects, total];
	}, [data]);


	useEffect(() => {
		if(isFetching) return;
		refetch(); // Call refetch() when searchParams or currentUser change
	}, [searchParams, currentUser]);


	return (
		<View
			title={`Your Biodrone projects${currentUser?.active_org_name ? ` in ${currentUser?.active_org_name}` : ''}`}
			headingComponent={
				!isNewUser && (
					<FilterButtonWrapper>
						<Button
							size="xs"
							type="button"
							variant={showFilters ? 'outline-light' : 'light'}
							className="border-1"
							onClick={() => {
								setShowFilters(!showFilters);
							}}
							title={showFilters ? 'Hide filters' : 'Show filters'}
							aria-pressed={showFilters}>
							<AiOutlineFilter
								aria-hidden="true"
								className="me-2 fs-5"
							/>
							<span className="fw-normal">
								{showFilters ? 'Hide filters' : 'Show filters'}
							</span>
						</Button>
					</FilterButtonWrapper>
				)
			}>
			<Toolbar alignment="right">
				<ButtonGroup>
					{newProjectButton()}
					{/* <NewProjectModal
						numberProjects={projects.length}
						callBack={() => {
							refetch();
						}}
					/> */}
				</ButtonGroup>
			</Toolbar>

			{isNewUser && !isFetching && (
				<div className="d-flex align-content-center w-100">
					<div>
						<p>Start by creating your first project. You need images from a drone or an orthophoto.</p>
						{newProjectButton()}
					</div>
				</div>
			)}

			<div>

				{isFetching && (
					<div className="col-md-12">
						<Loader />
					</div>
				)}

				{error && !isFetching && (
					<div className="col-md-12">
						<Alert variant="danger">
							<Alert.Heading as="h2">
								<strong>An error occured:</strong>
							</Alert.Heading>
							<p className="mb-0">{error?.response?.data?.detail}</p>
						</Alert>
					</div>
				)}

				{!error && !isNewUser && (
					<>
						<div className="col-md-12">
							<ProjectsFilter
								show={showFilters}
								currentUser={currentUser}
							/>
						</div>

						{projects.length === 0 &&(
							<NoProjectsMessages>No projects found</NoProjectsMessages>
						)}

						<div className="row">
							{projects.map((project, i) => (
								<div
									className="col-12 col-sm-6 col-md-4 col-lg-3"
									key={`${project.uuid}-${i}`}>
									<ProjectCard
										project={project}
										refetchProjects={refetch}
									/>
								</div>
							))}
						</div>

						<div className="col-md-12 pb-3">
							<ProjectCount>
								Showing {projects.length} of {total}
							</ProjectCount>

							{projects?.length < total && (
								<div className="text-center">
									<Button
										variant="success"
										className="mt-2 mb-2 col-6"
										disabled={!hasNextPage || isFetchingNextPage}
										onClick={() => fetchNextPage()}>
											<span>Load more</span>{' '}
										{isFetchingNextPage && (
											<Spinner
												animation="border"
												variant="light"
												size="sm"
											/>
										)}
									</Button>
								</div>
							)}
						</div>
					</>
				)}
			</div>

			<Tooltip id="tooltip-projects-root" variant="light" />

		</View>
	);
};

export default Projects;
