import View from "../components/view/View";

const BiodroneEULA = () => {

    return (
        <View title="Biodrone Portal User Agreement">
            <ol>
                <li>
                    <h2>Definition of Parties</h2>
                    <p>"You" or "Your" refers to you personally (the individual who reads and agrees to be bound by these terms) or, if you act on behalf of a corporation or other legal entity, collectively you and such corporation or legal entity on whose behalf you register for an account or use our services.</p>
                </li>
                <li>
                    <h2>Account and Organization Management</h2>
                    <p>By creating an account with Biodrone, you agree to:</p>
                    <ul className="mb-4">
                        <li>Provide accurate and complete registration information.</li>
                        <li>Maintain the confidentiality of your account password.</li>
                        <li>Be responsible for all activities that occur under your account.</li>
                    </ul>

                </li>

                <li>
                    <h2>Subscription and Licensing Terms</h2>
                    <p>Biodrone offers various subscription plans. By subscribing, you agree to the terms and conditions of your chosen plan, including any limitations and renewal terms.</p>
                </li>

                <li>
                    <h2>Cancellation Policy</h2>
                    <p>You may cancel your subscription at any time. However, no refunds will be provided for any remaining period of your subscription.</p>
                </li>

                <li>
                    <h2>Payment Terms and Data Ownership</h2>
                    <p>All payments are subject to the following:</p>
                    <ul className="mb-4">
                        <li>Accepted payment methods are outlined during the purchase process.</li>
                        <li>You are responsible for all applicable taxes and fees.</li>
                        <li>In case of payment failure, your access to Biodrone services may be suspended.</li>
                    </ul>

                    <p>Data Ownership:</p>
                    <ul className="mb-4">
                        <li>Biodrone acknowledges that you retain ownership of any data you upload to the Biodrone portal.</li>
                        <li>Biodrone will use your data solely for the purpose of providing and improving the services, and will not sell your data to third parties..</li>
                    </ul>

                    <p>You grant Biodrone the right to use the non-personal data, such as annotations, models, and similar material, for system improvement and marketing purposes. This permission is indefinite.</p>
                </li>

                <li>
                    <h2>Intellectual Property Rights</h2>
                    <p>All intellectual property rights in the Biodrone portal, its content, and services are owned by Biodrone. You do not acquire any ownership rights by using our services.</p>
                </li>

                <li>
                    <h2>Limitation of Liability and Warranties</h2>
                    <p>Biodrone provides its services "as is". Except as expressly stated, we disclaim all warranties and will not be liable for any indirect or consequential damages.</p>
                </li>

                <li>
                    <h2>User Content and Responsibility</h2>
                    <p>You are responsible for the content you upload or generate using Biodrone's services, and for ensuring it complies with all applicable laws and regulations.</p>
                </li>

                <li>
                    <h2>Data Privacy</h2>
                    <p>Biodrone is committed to protecting your privacy. Our data handling practices are outlined in our privacy policy.</p>
                </li>

                <li>
                    <h2>Amendments and Severability</h2>
                    <p>Biodrone reserves the right to amend this agreement. If any provision is found unenforceable, it will not affect the enforceability of the remaining provisions.</p>
                </li>

                <li>
                    <h2>Jurisdiction and Applicable Law</h2>
                    <p>Any disputes arising from this agreement will be governed by the laws of Oslo Tingrett and shall be subject to the exclusive jurisdiction of its courts.</p>
                    <p>By creating an account and using the Biodrone portal, you agree to these terms.</p>
                </li>
            </ol>

        </View>
    );
}

export default BiodroneEULA;
