import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { format, startOfMonth, endOfDay, subMonths, endOfMonth, isSameMonth, isSameDay } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import { Button } from 'react-bootstrap';
import { FilterButton } from '../admin/shared/SharedComponents';

const PickerWrapper = styled.div`
    position: relative;
    display: inline-block;
`;

const Label = styled.span`
    display: block;
`;

const DayPickerOuter = styled.div`
    position: absolute;
    top: 4rem;
    right: 0;
    z-index: 1;
    background: ${props => props.theme.colors.background};
    border: 1px solid rgba(${props => props.theme.colors.borderLightRgb}, 0.1);
    box-shadow: 0px 4px 4px rgba(0,0,0,0.25);
    display: flex;
    flex-direction: column;
    align-items: right;
`;

const StyledFooter = styled.p`
    margin: 1rem 0 0;
    font-size: 0.8rem;
`;

const ButtonWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
    margin: 0.5rem 0 1rem;
`;

const currentDate = new Date();
const firstDayOfMonth = startOfMonth(currentDate);
const today = endOfDay(currentDate);

const lastMonthFirstDay = startOfMonth(subMonths(currentDate, 1));
const lastMonthLastDay = endOfDay(endOfMonth(subMonths(currentDate, 1)));

const DateRange = ({setReturnRange, searchParamsId = null, disabled = false}) => {
    const [searchParams] = useSearchParams();
    
    const [show, setShow] = useState(false);
    const [range, setRange] = useState({});
    const [month, setMonth] = useState(firstDayOfMonth);

    useEffect(() => {
        const rangeParam = searchParams.get(searchParamsId);
        const formattedRange = rangeParam && JSON.parse(rangeParam);
        if(formattedRange && Object.keys(formattedRange).length > 0){
            setRange({
                from: formattedRange.from ? new Date(formattedRange.from) : null,
                to: formattedRange.to ? new Date(formattedRange.to) : null
            });
        } 

        return () => {
            setRange({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParams.get(searchParamsId)]);

    useEffect(() => {
        if(range?.from || range?.from && !isSameMonth(range.from, month)){
            setMonth(range.from);
        }
    }, [range?.from]);

    return (
        <PickerWrapper>
            <div>
                <Label>Date range</Label>
                <FilterButton 
                    variant="outline-light" 
                    size="sm"
                    aria-pressed={show}
                    active={show}
                    aria-controls="date-range-picker"
                    disabled={disabled}
                    onClick={() => setShow(!show)}>
                        {range?.from ? format(range.from, 'd. LLL') : ''}
                        {range?.to && !isSameDay(range.from, range.to) ? ' - ' + format(range.to, 'd. LLL') : ''}
                        {!range?.from && !range?.to && 'Not selected'}
                </FilterButton>
            </div>
            {show && (
                <DayPickerOuter>
                    <DayPicker
                        id="date-range-picker"
                        mode="range"
                        month={month}
                        onMonthChange={setMonth}
                        selected={range}
                        onSelect={setRange}
                        footer={<DateRangeFooter range={range} setRange={setRange} month={month} />}
                    />

                    <Button 
                        variant="success" 
                        size="sm" 
                        className="m-3"
                        onClick={() => {
                            setReturnRange(range);
                            setShow(false);
                        }}>
                            Apply
                        </Button>
                </DayPickerOuter>
            )}
        </PickerWrapper>
    );
}

export default DateRange;

const DateRangeFooter = ({range, setRange, month}) => {

    let footerText = <StyledFooter>Please pick the first day.</StyledFooter>;
    if (range?.from) {
        if (range.to) {
            footerText = <StyledFooter>Selected: {format(range.from, 'd. LLL Y')} – {format(range.to, 'd. LLL Y')}</StyledFooter>;
        } else {
            footerText = <StyledFooter>Selected: {format(range.from, 'PPP')}</StyledFooter>;
        }
    }

    return (
        <div>
            <ButtonWrapper>
                <Button 
                    size="sm" 
                    onClick={() => {
                        setRange({from: lastMonthFirstDay, to: lastMonthLastDay});
                    }}
                    disabled={isSameMonth(lastMonthLastDay, month)}
                    variant="secondary"
                    className="p-0">
                        Pick last month
                </Button>
                <Button 
                    size="sm" 
                    onClick={() => {
                        setRange({from: firstDayOfMonth, to: today});
                    }}
                    disabled={range?.from && isSameMonth(today, month)}
                    variant="secondary"
                    className="p-0">
                        Pick this month
                </Button>
            </ButtonWrapper>

            {footerText}
        </div>
    );
};