import styled from 'styled-components';
import ModifyPoint from './ModifyPoints';
import Checkbox from '../../sidebars/sidebarElements/checkbox.component';
import { togglePolygonsByLabel } from '@utils/map/polygon.layer';

import { useProject, ProjectMode} from '@contexts/Project.context';

const FilterContainer = styled.div`
	padding: 20px 20px 200px;
`;
const SidebarRow = styled.div`
	display: grid;
	grid-template-columns: 1fr 50px;
	&:hover {
		cursor: pointer;
		color: white;
	}
`;
const PopoverHolder = styled.div`
	position: relative;
`;

const FilterPoints = () => {
    const {
		pickedModelClasses,
        pointLayer,
        polygonLayer,
        colorScheme,
		projectMode,
        dispatch,
    } = useProject();

	if(!pickedModelClasses) return null;

    return (
        <FilterContainer>
            {
				pickedModelClasses.map(({label, id}) => (
					<div key={`class-${id}`}>
						<SidebarRow>
							<Checkbox
								canEdit={false}
								label={label}
								handleCheck={() => {
									if(projectMode === ProjectMode.ORTHOPHOTO){
										if(pointLayer){
											dispatch({
												type: 'setColorScheme',
												payload: {
													...colorScheme,
													[id]: {
														visible: true,
														color: colorScheme[id].color,
													},
												},
											});
										}

										if(polygonLayer){
											togglePolygonsByLabel(id, true, colorScheme[id].color)
										}
									}
								}}
								handleUncheck={() => {
									if(projectMode === ProjectMode.ORTHOPHOTO){
										if(pointLayer){
											dispatch({
												type: 'setColorScheme',
												payload: {
													...colorScheme,
													[id]: {
														visible: false,
														color: colorScheme[id].color,
													},
												},
											});
										}

										if(polygonLayer){
											togglePolygonsByLabel(id, false)
										}
									}
								}}
							/>
							<PopoverHolder>
								<ModifyPoint label={label} id={id} />
							</PopoverHolder>
						</SidebarRow>

						<hr className="my-3" />
					</div>
				))
			}

        </FilterContainer>
    )
}

export default FilterPoints;
