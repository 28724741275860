import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { SlArrowDown, SlArrowUp } from 'react-icons/sl';
import { createExtent, imagePositionsToMapCoordinates } from '@utils/helpers';
import { useProject } from '@contexts/Project.context';
import Static from 'ol/source/ImageStatic';
import { getCenter } from 'ol/extent';
import { Polygon } from 'ol/geom';
import { Feature } from 'ol';
import { Fill, Stroke, Style } from 'ol/style';
import VectorSource from 'ol/source/Vector';
import { convertToRgba } from '@utils/map/helpers';

const Carousel = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	position: absolute;
	bottom: 75px;
	right: 20px;
	border-radius: 5px;
	border-color: black;
	border-style: solid;
	z-index: 2;
	width: 300px;
	height: 60%;
`;

const CarouselItems = styled.div`
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	flex-direction: column;
	width: 100%;
	height: 100%;
`;

const CarouselItem = styled.div`
	width: 100%;
	height: 100%;
	padding: 10px;
	img {
		width: 100%;
		height: 100%;

		&:hover {
			cursor: pointer;
		}

		border-style: ${props => (props.isSelected ? 'solid' : 'none')};
		border-color: ${props => props.theme.colors.borderLight};
	}
`;
const ArrowContainer = styled.div`
	display: flex;
	justify-content: center;
	&:hover {
		cursor: pointer;
	}
`;

const CarouselSlider = ({ items }) => {
	const {
		colorScheme,
		mapObject,
		defaultProjection,
		singleImageLayer,
		singleImagePolygonLayer,
		singlePhotosLayer,
		dispatch,
	} = useProject();

	const [currentIndex, setCurrentIndex] = useState(0);
	const [itemsDisplayed, setItemsDisplayed] = useState([]);
	const [isCarouselImageClicked, setIsCarouselImageClicked] = useState(false);
	const [selectedImage, setSelectedImage] = useState(null);
	const [prevView, setPrevView] = useState(null);

	const itemsToShow = 3;

	useEffect(() => {
		if (items) {
			const itemsToDisplay = items.slice(
				currentIndex,
				currentIndex + itemsToShow
			);
			setItemsDisplayed(itemsToDisplay);
		}
	}, []);

	const handleNext = () => {
		const nextIndex =
			currentIndex + itemsToShow >= items.length
				? 0
				: currentIndex + itemsToShow;
		setCurrentIndex(nextIndex);
		const itemsToDisplay = items.slice(nextIndex, nextIndex + itemsToShow);
		setItemsDisplayed(itemsToDisplay);
	};

	const handlePrevious = () => {
		const nextIndex =
			currentIndex - itemsToShow < 0
				? items.length - itemsToShow
				: currentIndex - itemsToShow;
		setCurrentIndex(nextIndex);
		const itemsToDisplay = items.slice(nextIndex, nextIndex + itemsToShow);
		setItemsDisplayed(itemsToDisplay);
	};

	const handleImageClick = (event, imageFeature, index) => {
		event.stopPropagation();

		const imageExtent = createExtent(imageFeature.get('polygons'));
		const thumbnailUrl = imageFeature.get('thumbnail');

		const source = new Static({
			url: thumbnailUrl,
			visible: true,
			projection: defaultProjection,
			imageExtent: imageExtent,
		});
		singlePhotosLayer.setVisible(false);
		singleImageLayer.setSource(source);

		const polygonVectorSource = new VectorSource();
		singleImagePolygonLayer.setSource(polygonVectorSource);
		singleImagePolygonLayer.setVisible(true);

		imageFeature.get('detections')?.forEach(detection => {
			const polygonPositionTranslatedToMapCoordinates =
				imagePositionsToMapCoordinates(
					detection.geometry.coordinates,
					imageExtent
				);
			const polygon = new Polygon(
				polygonPositionTranslatedToMapCoordinates
			);
			const polygonFeature = new Feature({
				geometry: polygon,
				data: detection.properties,
				classId: detection.properties.classid,
				className: detection.properties.classname,
				confidence: detection.properties.confidence,
			});
			polygonFeature.setStyle(
				new Style({
					stroke: new Stroke({
						color: convertToRgba(
							colorScheme[polygonFeature.get('classId')].color
						),
						width: 3,
					}),
					fill: new Fill({
						color: 'rgba(255, 255, 255, 0.2)',
					}),
				})
			);
			polygonVectorSource.addFeature(polygonFeature);
		});

		const view = mapObject.getView();
		const size = mapObject.getSize();
		const resolution = view.getResolutionForExtent(imageExtent, size);
		const zoom = view.getZoomForResolution(resolution) - 0.5;
		const prevCenter = view.getCenter();
		const prevZoom = view.getZoom();
		setPrevView({ prevCenter: prevCenter, prevZoom: prevZoom });

		view.animate({
			center: getCenter(imageExtent),
			zoom: zoom,
			duration: 300,
		});

		singleImageLayer.setVisible(true);
		setIsCarouselImageClicked(true);
		setSelectedImage(index);
	};

	const handleWindowClick = () => {
		if (isCarouselImageClicked) {
			singleImagePolygonLayer.setVisible(false);
			singleImageLayer.setVisible(false);
			singlePhotosLayer.setVisible(true);
			const view = mapObject.getView();
			view.animate({
				center: prevView.prevCenter,
				zoom: prevView.prevZoom,
				duration: 300,
			});

			setSelectedImage(null);
			setIsCarouselImageClicked(false); // Reset the flag
		}
	};

	useEffect(() => {
		window.addEventListener('click', handleWindowClick);

		return () => {
			window.removeEventListener('click', handleWindowClick);
		};
	}, [isCarouselImageClicked]);

	return (
		<Carousel>
			<ArrowContainer onClick={() => handlePrevious()}>
				<SlArrowUp color="black" />
			</ArrowContainer>

			{itemsDisplayed.length > 0 && (
				<CarouselItems>
					{itemsDisplayed.map((item, i) => (
						<CarouselItem
							onClick={event => handleImageClick(event, item, i)}
							isSelected={selectedImage === i}>
							<img src={item.get('thumbnail')} alt={i} />
						</CarouselItem>
					))}
				</CarouselItems>
			)}
			<ArrowContainer onClick={() => handleNext()}>
				<SlArrowDown color="black" />
			</ArrowContainer>
		</Carousel>
	);
};

export default CarouselSlider;
