import { useCallback, useEffect, useRef } from "react";
import { useQuery } from "@tanstack/react-query";

import { useProject } from "@contexts/Project.context";
import {
    makePointLayer,
    removePointLayer,
} from "@utils/map/point.layer";
import { getMasterFeatures } from "@utils/api";
import { setupLayerDetails } from "@utils/map/helpers";

import Checkbox from "../sidebars/sidebarElements/checkbox.component";
import NoResult from "../sidebars/sidebarElements/noAnalyzes";

/**
 *
 * responsible for rendering the point layer,
 * and the feature menu that is used to change the styles of the points
 */

const PointLayer = ({
    setActiveSidebar,
    setExportData,
}) => {

    const {
        mapObject,
        defaultProjection,
        features,
		pickedModelClasses,
        pointLayer,
        taskId,
        project,
		colorScheme,
        colorOptions,
		dispatch,
    } = useProject();

    const {data: pointData, isError, error} = useQuery({
		queryKey: ["points_master_features", project.uuid, taskId],
		queryFn: () => getMasterFeatures(project.uuid, taskId, "points"),
		enabled: !!project?.uuid && !!taskId,
		refetchOnWindowFocus: false,
        retry: false,
	});

    const adding = useRef(false);

    const updatePointLayer = useCallback(async () => {
        if(adding.current) return;

        if (features.length > 0
            && mapObject
        ) {

            if(!pickedModelClasses?.length){
                const {modelClasses, colorStyles} = setupLayerDetails(features, colorOptions);

				dispatch({ type: 'setColorScheme', payload: colorStyles });
				dispatch({ type: 'setPickedModelClasses', payload: modelClasses });
            }

			// Don't add the layer if the color scheme or picked model classes are not set
			if(!colorScheme || !pickedModelClasses?.length) return;

			adding.current = true;

			const classIds = pickedModelClasses.map(({id}) => id);
            makePointLayer(mapObject, defaultProjection, features, colorScheme, classIds).then((layer) => {
				dispatch({ type: 'setPointLayer', payload: layer });
                console.log("added point layer");
            }).finally(() => {
                adding.current = false;
            });
        } else {
            if (mapObject && pointLayer) {
                removePointLayer();
				dispatch({ type: 'setPointLayer', payload: null });
				dispatch({ type: 'setPickedModelClasses', payload: null});
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [features, colorScheme]);

    useEffect(() => {
        if(pointData?.features){
			dispatch({ type: 'setFeatures', payload: pointData.features });
            setExportData(pointData);
            return;
        }

        if(isError){
            console.warn('could not fetch points', error);
            if (mapObject && pointLayer) {
                removePointLayer();
				dispatch({ type: 'setPointLayer', payload: null });
            }
        }
    }, [pointData, isError]);

    useEffect(() => {
        updatePointLayer();
    }, [updatePointLayer]);

    if(!pointLayer) return <NoResult />;

    return (
        <div id="pointLayer">
            <Checkbox
                label={pointLayer.get("name")}
                handleCheck={() => {
                    pointLayer.setVisible(true);
                }}
                handleUncheck={() => {
                    pointLayer.setVisible(false);
                }}
                handleClick={setActiveSidebar}
            />
        </div>
    )
}

export default PointLayer;
