import { useMemo, useState, useEffect } from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { components } from "react-select";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import {MdDelete} from "react-icons/md";

import AdvancedSelect from "@components/form/advancedSelect";
import Input from "@components/form/Input";
import { StyledModal } from "@components/modal/sharedModalStyles";
import { useToast } from "@contexts/Toast.context";

import { getFilterTemplates, createFilterTemplate, updateFilterTemplate, deleteFilterTemplate } from "@api";

export default function FilterTemplates({
	emptyFilterBox,
	setFilters,
	activeFilterTemplate,
	setActiveFilterTemplate,
	initialHexagonSize,
	setHexagonSize,
	isDemo,
}){
	const { addToast } = useToast();

	const {data: filterTemplatesData, refetch: filterTemplatesRefetch} = useQuery({
        queryKey: ['filterTemplates'],
        queryFn: getFilterTemplates,
    });

	useEffect(() => {
		if(!filterTemplatesData) return;
		// Refetch the filter templates in case a new template was created
		filterTemplatesRefetch();
	}, [activeFilterTemplate]);

	const { filterTemplates } = useMemo(() => {
		//onst filterTemplates = [{title: "Empty (base for new)", filterBoxes: []}];
		const filterTemplates = [];

		if(!filterTemplatesData?.filter_templates) {
			return {filterTemplates};
		}

		// Add saved filter templates
		filterTemplatesData?.filter_templates?.map(data => {
			try{
				const filterBoxes = data.filter_boxes?.map(box => {
					return {
						...box,
					};
				});

				filterTemplates.push({
					title: data.title,
					uuid: data.uuid,
					hexagonSize: data.hexagon_size,
					filterBoxes,
				});

			} catch(e) {
				console.error('Error parsing filter template data', e);
			}
		});

		return {filterTemplates};

	}, [filterTemplatesData]); // Dependencies


	if(!filterTemplates) return null;

	return (
		<>
			<AdvancedSelect
				label="Filter template"
				placeholder="No template selected"
				id="filter-template-select"
				value={activeFilterTemplate ? {value: activeFilterTemplate.uuid, label: activeFilterTemplate.title} : null}
				options={filterTemplates.map((data) => ({value: data.uuid, label: data.title}))}
				onChange={e => {
					if(!e){
						setFilters([{...emptyFilterBox}]);
						setActiveFilterTemplate(null);
						setHexagonSize(initialHexagonSize);
						return;
					}

					const value = e.value;
					const selectedFilter = filterTemplates.find(template => template.uuid === value);
					if (!selectedFilter) return;
					setFilters(selectedFilter.filterBoxes);
					setActiveFilterTemplate(selectedFilter);
					setHexagonSize(selectedFilter.hexagonSize);
				}}
				isSearchable
				isClearable
				noOptionsMessage={() => 'No matching templates found'}
				components={{
					Option: (props) =>
						<Option
							{...props}
							setActiveFilterTemplate={setActiveFilterTemplate}
							filterTemplatesRefetch={filterTemplatesRefetch}
							addToast={addToast}
							isDemo={isDemo}
						/>
				}}
			/>

		</>
	);
};

const Option = ( {setActiveFilterTemplate, addToast, isDemo, ...props} ) => {
	const filter = props?.data;

	const mutation = useMutation({
		mutationFn: () => {
			return deleteFilterTemplate(filter.value);
		},
		onError: (err) => {
			console.error(err);
			addToast({
				id: `delete_filter_template_error-${new Date().getTime()}`,
				title: `Failed to delete filter template: ${err.response?.data?.detail || err.message || 'Unknown error'}`,
				bg: "danger",
			});
		},
		onSuccess: () => {
			addToast({
				id: `delete_filter_template_success-${new Date().getTime()}`,
				title: "Filter template deleted successfully",
				bg: "success",
			});
			setActiveFilterTemplate(null);
		},
	})

	const handleDelete = () => {
		if(window.confirm(`Are you sure you want to delete the filter template "${filter.label}"?`)){
			mutation.mutate();
		}
	}

	return (
	  <div className="d-flex flex-row gap-2">
		<components.Option {...props} />
		<Button
			variant="link-dark"
			className="p-1"
			onClick={handleDelete}
			title="Delete filter template"
			disabled={isDemo}
		>
			<MdDelete />
		</Button>
	  </div>
	);
};

export const CreateFilterTemplate = ({
	filterBoxes,
	hexagonSize,
	activeFilterTemplate,
	setActiveFilterTemplate,
	isDemo,
}) => {
	const [showModal, setShowModal] = useState(false);
	const { addToast } = useToast();

	const mutation = useMutation({
        mutationFn: (title) => {
			if(activeFilterTemplate){
				return updateFilterTemplate({
					uuid: activeFilterTemplate.uuid,
					title,
					hexagonSize,
					filterBoxes
				});
			}

			return createFilterTemplate({title, hexagonSize, filterBoxes})
		},
		onError: (err) => {
			console.error(err);
			addToast({
                id: `filter_template_result_error-${new Date().getTime()}`,
                title: "Failed to save filter template",
                bg: "danger",
            });
		},
		onSuccess: (res) => {
			addToast({
                id: `filter_template_result_success-${new Date().getTime()}`,
                title: "Filter template saved successfully",
                bg: "success",
            });
			setActiveFilterTemplate(res);
			setShowModal(false);
		},
    })

	const onSubmit = (e) => {
		e.preventDefault();

		const formData = new FormData(e.target);
		const title = formData.get('title');

		mutation.mutate(title);
	}

	return (
		<>
			<div className="d-flex justify-content-end">
				<Button
					variant="link-secondary"
					className="px-0 pt-2"
					size="sm"
					onClick={() => setShowModal(true)}
					disabled={isDemo}
				>
					<span className="text-decoration-underline text-muted">
						{!activeFilterTemplate ? 'Save filters as new template' : 'Update filter template'}
					</span>
				</Button>
			</div>

			<StyledModal onHide={() => setShowModal(false)} show={showModal} centered={true}>
				<Modal.Header closeButton closeVariant="white">
					<Modal.Title>
						{!activeFilterTemplate ? 'Save filter boxes as template' : 'Update filter template'}
					</Modal.Title>
				</Modal.Header>
				<Form onSubmit={onSubmit}>
					<Modal.Body>
						<Input
							type="text"
							label="Template title"
							placeholder="Enter template title"
							name="title"
							defaultValue={activeFilterTemplate?.title || ''}
							required
						/>
						<p className="text-muted">Selected settings from the filter boxes will also be saved.</p>

					</Modal.Body>
					<Modal.Footer>
						<Button
							type="button"
							variant="secondary"
							onClick={() => setShowModal(false)}
							disabled={mutation.isLoading}
						>Cancel</Button>
						<Button
							type="submit"
							variant="success"
							disabled={mutation.isLoading}
						>
							{mutation.isLoading ? 'Saving...' : !activeFilterTemplate ? 'Save' : 'Update'}
						</Button>
					</Modal.Footer>
				</Form>
			</StyledModal>
		</>
	);
};
