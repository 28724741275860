import styled from "styled-components";
import { AiOutlineRight } from "react-icons/ai";

import SidebarTemplate from "../sidebarElements/SidebarTemplate";
import LabelButton from "../sidebarElements/labelButton.component";
import HexagonAccordion from "../sidebarElements/HexagonAccordion";

import {formatAreaNumber} from "@utils/helpers";
import { useProject } from "@contexts/Project.context";

const StyledLabelButton = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 15px;

    svg{
        margin-top: 3px;
    }
`;

const Title = styled.div`
	font-size: 1rem;
    font-weight: 600;
	margin-bottom: 10px;
`;

const LabelDetails = styled.div`
    font-size: 0.9em;
    font-weight: normal;
    line-height: 1.1;
    p{
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        &:last-of-type{
            margin-bottom: 0;
        }
    }
`;

const Name = styled.span`
    text-transform: capitalize;
    color: ${p => p.theme.colors.textAccent};
	display: block;
`;

const Menu = styled.div`
    color: ${(props) => props.theme.colors.textColor};
    padding-top: 10px;
    padding-bottom: 20px;
`;

const Details = styled.div`
    padding: 0 20px 20px;
    p{
        margin: 0;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		gap: 10px;
    }
`;

const DetailsName = styled.span`
    text-transform: capitalize;
	display: block;
`;

const DetailsList = styled.ul`
	list-style: none;
	padding: 0;
	margin: 0;

	font-weight: normal;
    line-height: 1.2;
    color: ${p => p.theme.colors.textColor};

    li{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
		span:first-child{
			text-transform: capitalize;
			color: ${p => p.theme.colors.textAccent};
		}
    }
`;

const sidebarId = "hexagonSummary";

const HexagonSummarySidebar = () => {

	const {
		hexagonInfo
  	} = useProject();

	const hexagonsSummary = hexagonInfo?.metadata?.total;
	const hexagonMeta = hexagonsSummary?.metadata;

    if(!hexagonMeta) return;

    return (

		<SidebarTemplate
			index={2}
			sidebarId={sidebarId}
			header="Hexagons total summary"
			closeable={true}
		>
			<Menu>
				<Details>
					{hexagonMeta.count && (<p><strong>Objects in total</strong> <span>{formatAreaNumber(hexagonMeta.count, 0)}</span></p>)}
					{hexagonMeta.area && (<p><strong>Area</strong> <span>{formatAreaNumber(hexagonMeta.area)} m²</span></p>)}
				</Details>
				<HexagonAccordion hexagonData={hexagonsSummary} />
			</Menu>
		</SidebarTemplate>

    );
}

export default HexagonSummarySidebar;

export const HexagonSummaryPreview = () => {

	const {
		setActiveSidebar,
		hexagonInfo
  	} = useProject();

	const hexagonsSummary = hexagonInfo?.metadata?.total;
	const hexagonMeta = hexagonsSummary?.metadata;

    if(!hexagonMeta) return;

	return (
		<div>
			<hr className="mt-0" />

			<StyledLabelButton
				as={LabelButton}
				handleClick={() => {
					setActiveSidebar({sidebarId, parentId: "layerView"});
				}}
			>
				<Title>Hexagons Summary</Title>

				<AiOutlineRight />
			</StyledLabelButton>

			<LabelDetails>
				{hexagonMeta.count && (<p><Name>Total</Name> <span>{formatAreaNumber(hexagonMeta.count)}</span></p>)}

				{hexagonMeta.area && (<p><Name>Area</Name> <span>{formatAreaNumber(hexagonMeta.area)} m²</span></p>)}

				{hexagonsSummary?.detections && Object.keys(hexagonsSummary?.detections).map((key) => (
					<DetectionDetail key={`hexagon-detail-${key}`} name={key} detection={hexagonsSummary?.detections[key]} totalCount={hexagonMeta.count} />
				))}
			</LabelDetails>
		</div>
	);
};

const DetectionDetail = ({name, detection, totalCount}) => {

	const percentOfTotal = Math.round(detection.count / totalCount * 100);

    return (
		<div className="mt-3">
			<DetailsName>{name} ({percentOfTotal}%)</DetailsName>
        	<DetailsList>
				<li>
					<span>Count</span> <span>{detection.count}</span></li>
				<li>
					<span>Mean height</span> <span>{detection.mean_height} m</span>
				</li>
			</DetailsList>
		</div>
    );
}
