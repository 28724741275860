import { Polygon } from 'ol/geom';
import { Fill, Stroke, Style } from 'ol/style.js';
import CircleStyle from 'ol/style/Circle';

import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Feature } from 'ol';
import { convertToRgba } from './helpers';

const hiddenStyle = new Style({
	// global for re-use without creating a new object every time in the togglePointsByLabel function
	image: new CircleStyle({
		radius: 5,
		fill: new Fill({
			color: 'rgba(255, 255, 255, 0.01)',
		}),
	}),
});

/**
 * Toggles the visibility of polygons with a specific label
 * @param {string} classId - the label to toggle
 * @param {boolean} visible - whether the polygons should be visible or not
 * @param {Style} style - the style to apply to the polygons
 * @returns {void}
 */
export const togglePolygonsByLabel = async (classId, visible, color) => {
	polygonLayer
		.getSource()
		.getFeatures()
		.forEach(feature => {
			if (feature.get('classId') === classId) {
				if (visible) {
					const rgba = convertToRgba(color);
					setPolygonStyle(feature, rgba);
				} else {
					feature.setStyle(hiddenStyle);
				}
			}
		});
};

let polygonLayer = null;
let polygonVectorSource = null;

export const makePolygonLayer = (currentMap, features, colorScheme) => {
	if (polygonLayer) {
		currentMap.removeLayer(polygonLayer);
		polygonLayer = null;
	}

	if (!features || features.length === 0) return null;

	polygonVectorSource = new VectorSource();
	makePolygons(features, colorScheme);
	polygonLayer = new VectorLayer({
		source: polygonVectorSource,
		zIndex: 10,
		name: 'Detection results',
		properties: {
			customLayerId: 'polygonResultsLayer',
		},
	});

	currentMap.addLayer(polygonLayer);
	return polygonLayer;
};

const makePolygons = (features, colorScheme) => {
	features.forEach(feature => {
		const polygon = new Polygon(feature.geometry.coordinates);
		const polygonFeature = new Feature({
			geometry: polygon,
			data: feature.properties,
			classId: feature.properties.classid,
			className: feature.properties.classname,
			confidence: feature.properties.confidence,
		});
		const color = convertToRgba(
			colorScheme[feature.properties.classid].color
		);
		setPolygonStyle(polygonFeature, color);
		polygonVectorSource.addFeature(polygonFeature);
	});
};

export const updatePolygonStyles = colorScheme => {
	if (!polygonLayer) return;
	polygonLayer
		.getSource()
		.getFeatures()
		.forEach(feature => {
			const color = convertToRgba(
				colorScheme[feature.get('classId')].color
			);
			setPolygonStyle(feature, color);
		});
};

const setPolygonStyle = (feature, color) => {
	feature.setStyle(
		new Style({
			stroke: new Stroke({
				color: color,
				width: 3,
			}),
			fill: new Fill({
				color: 'rgba(255, 255, 255, 0.2)',
			}),
		})
	);
};
