import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';

import { useAuth } from '@contexts/User.context';

const StyledNav = styled(Nav)`
	row-gap: 16px;
`;
const StyledNavLink = styled(Nav.Link)`
	color: ${props => props.theme.colors.textColor};
	text-decoration: none;
	span {
		border-bottom: 1px solid transparent;
		transition: all 0.2s ease-in-out;
	}
	&.active {
		span {
			border-color: ${props => props.theme.colors.textColor};
		}
	}
	&:hover {
		color: ${props => props.theme.colors.textColor};
		span {
			border-color: ${props => props.theme.colors.textColor};
		}
	}
`;

const HeaderName = styled.p`
	font-size: 0.8rem;
	margin: 0 0 10px;
	opacity: 0.7;
	font-weight: 300;
`;

const NavList = styled.ul`
	list-style: none;
	padding: 0;
	margin: 0;
	display: flex;
	flex-direction: column;
	gap: 12px;
	li{
		list-style: none;
	}
	ul{
		padding-left: 1em;
		padding-top: 5px;

		&:not(:first-child){
			font-size: 0.9em;
			margin-bottom: 0;
			li:before{
				content: "- ";
			}
		}
	}
`;

const AdminNavigation = () => {
	const {
		roleAdmin,
		isOrgAdmin,
		tierPro,
		currentUser,
		subscriptionActive
	} = useAuth();

	return (
		<StyledNav className="flex-column">
			<HeaderName>Admin Menu</HeaderName>
			<NavList>
				{!roleAdmin && isOrgAdmin && (
					<li>
						<span className="text-muted">Organization</span>
						<ul>
							<li>
								<StyledNavLink as={NavLink} to={`/organization/${currentUser.active_org_id}/projects`}>
									<span>Projects</span>
								</StyledNavLink>
							</li>
							<li>
								<StyledNavLink as={NavLink} to={`/organization/${currentUser.active_org_id}/profile`}>
									<span>Profile</span>
								</StyledNavLink>
							</li>
						</ul>
					</li>
				)}

				{roleAdmin && (
					<>
						<li>
							<StyledNavLink as={NavLink} to="/admin/organizations">
								<span>Organizations</span>
							</StyledNavLink>
						</li>
						<li>
							<StyledNavLink as={NavLink} to="/admin/users">
								<span>Users</span>
							</StyledNavLink>
						</li>
						<li>
							<StyledNavLink as={NavLink} to="/admin/projects">
								<span>Projects</span>
							</StyledNavLink>
							<ul>
								<li>
									<StyledNavLink as={NavLink} to="/admin/demo-projects">
										<span>Demos</span>
									</StyledNavLink>
								</li>
							</ul>
						</li>
					</>
				)}

			{tierPro && subscriptionActive && (
				<>
					<li>
						<StyledNavLink as={NavLink} to="/storage-subscription">
							<span>Subscription</span>
						</StyledNavLink>
					</li>
					<li>

						<StyledNavLink as={NavLink} to="/area-transactions">
							<span>Area</span>
						</StyledNavLink>
					</li>
				</>
			)}

				<li>
					<StyledNavLink as={NavLink} to="/profile">
						<span>User profile</span>
					</StyledNavLink>
				</li>

			</NavList>
		</StyledNav>
	);
};

export default AdminNavigation;
