import { useCallback, useEffect, useRef, forwardRef, useImperativeHandle } from "react";

import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import { Feature } from "ol/index";
import { Polygon } from "ol/geom";
import { Stroke, Style, Fill} from "ol/style";
import { KML, GeoJSON } from "ol/format";
import { unByKey } from "ol/Observable";

import Button from "react-bootstrap/Button";

import { useProject } from "@contexts/Project.context";
import { deleteLayerByCustomId, getLayerByCustomId, setLayersVisibilityByCustomIds } from "@utils/map/helpers";
import { downloadFile } from "@utils/files";
import { clearTooltip } from "@utils/map/tooltip.overlay";
import { addHexagonInteraction } from "../sidebars/HexagonInfo";

/*
*  This component is responsible for rendering the area of interest layer and toggling its visibility.
*/
const HexagonColorFilterLayer = forwardRef((props, ref) => {

	const {data, setHexagons} = props;

	const {
		mapObject,
		mapTooltip,
		defaultProjection,
		project,
		dispatch,
    } = useProject();

	const {tooltip, tooltipRef} = mapTooltip;

	const mouseOverRef = useRef(null);
	const selectInteractionRef = useRef(null);
    const adding = useRef(false);
	const layerId = 'hexagonColorFilterLayer';

	// Function to remove the hexagon color filter layer
	// This function is exposed to the parent component
	const removeHcfLayer = () => {
		if(mapObject && !adding.current){

			if(selectInteractionRef.current){
				mapObject.removeInteraction(selectInteractionRef.current);
				selectInteractionRef.current = false;
				dispatch({ type: 'setSelectedHexagon', payload: null });

			}

			const deleted = deleteLayerByCustomId(mapObject, layerId);

			if(deleted){
				console.log('removing hexagon color filter layer');
				// Remove pointermove event
				unByKey(mouseOverRef.current);
				//mapObject.un('pointermove', mapMouseOver);

				// remove all elements in the tooltip
				tooltipRef.innerHTML = '';

				// Show hexagon layer
				setLayersVisibilityByCustomIds(mapObject, ['hexagonLayer', 'aoiLayer'], true);
			}
		}
	}
	useImperativeHandle(ref, () => ({
		removeHcfLayer
	}));

    const updateHCFLayer = useCallback(() => {

        // if the map object exists, task ID and project ID is set, create and add the layer
        if(mapObject && !adding.current){
			adding.current = true;

            if(!data){
                adding.current = false;
                return;
            }

            let layerSource = null;

			const hexagonFeatures = data.map(hexagon => makeHexagon(hexagon));
			setHexagons(hexagonFeatures);

			if(hexagonFeatures?.length){

				const hcfLayerSource = new VectorSource({
					features: hexagonFeatures,
				});

				const hCFLayer = getLayerByCustomId(mapObject, layerId);

				// If layer already exists, update the source
				if(hCFLayer) {
					console.log('layer exists, updating source');
					hCFLayer.setSource(hcfLayerSource);
					adding.current = false;
					return;
				}

				layerSource = new VectorLayer({
					name: 'Hexagons color filter',
					renderBuffer: 2000,
					source: hcfLayerSource,
					opacity: 1,
					zIndex: 2000,
					properties: {
						customLayerId: layerId,
					},
				});

				mapObject.addLayer(layerSource);

				mouseOverRef.current = mapObject.on('pointermove', (event) => mapMouseOver(event, tooltip, tooltipRef));

				if (!selectInteractionRef.current) {
					addHexagonInteraction(layerSource, mapObject, dispatch, selectInteractionRef);
				}

				// Hide hexagon layer
				setLayersVisibilityByCustomIds(mapObject, ['hexagonLayer', 'aoiLayer'], false);

				console.log('added hexagon color filter layer');
			}

            adding.current = false;
        }
    }, [data]);

    useEffect(() => {
        updateHCFLayer();
    }, [updateHCFLayer]);

	// Function to handle the export of data
	const handleExport = (type, title) => {
		let format, fileType, fileExtension;

		// Check the type of export
		if(type === 'kml'){
			// If it's KML, set the format, fileType, and fileExtension accordingly
			format = new KML();
			fileType = 'vnd.google-earth.kml+xml';
			fileExtension = 'kml';
		} else if(type === 'geojson'){
			// If it's GeoJSON, set the format, fileType, and fileExtension accordingly
			format = new GeoJSON();
			fileType = 'geo+json';
			fileExtension = 'geojson';
		}

		// Get the features from the source
		const hCFLayer = getLayerByCustomId(mapObject, layerId);
		const features = hCFLayer.getSource().getFeatures();

		// The exports does not handle multiple styles, so we need to create new features with the last color
		const newFeatures = features.map(feature => {
			const colors = feature.get('colors');
			if(!colors?.length) return feature;

			const newFeature = feature.clone();
			const style = hexagonStyleForExport(colors[colors.length - 1]);

			newFeature.setStyle(style);
			newFeature.unset('colors'); // Remove the colors property because it does not make sense in the export

			return newFeature;
		});

		// Write the features to a string
		const data = format.writeFeatures(newFeatures, { featureProjection: defaultProjection });

		// Create a new blob from the string
		const blob = new Blob([data], { type: `application/${fileType}` });

		// Create a new object URL from the blob
		const url = URL.createObjectURL(blob);

		// Download the file
		downloadFile(url, title, fileExtension);
	};

    if(adding.current) return null;

	return (

		<>
			<Button
				onClick={() => {handleExport('kml', project?.title || 'map')}}
				variant="dark"
				size="sm"
			>
				Export KML
			</Button>
			<Button
				onClick={() => {handleExport('geojson', project?.title || 'map')}}
				variant="dark"
				size="sm"
			>
				Export GeoJSON
			</Button>

	  	</>

	);

});
export default HexagonColorFilterLayer;

const makeHexagon = (feature) => {
	let coordinates = feature.metadata.hexagon_polygon;
	let hexagon = new Feature({
		data: { ...feature },
		geometry: new Polygon([
			[
				[coordinates[0][0], coordinates[0][1]],
				[coordinates[1][0], coordinates[1][1]],
				[coordinates[2][0], coordinates[2][1]],
				[coordinates[3][0], coordinates[3][1]],
				[coordinates[4][0], coordinates[4][1]],
				[coordinates[5][0], coordinates[5][1]],
				[coordinates[0][0], coordinates[0][1]],
			],
		]),
	});

	const style = hexagonStyles(null);

	hexagon.setStyle(style);
	return hexagon;
};

export const hexagonStyles = (rgbColors) => {
	// If no colors are provided, return the default style
	if(!rgbColors || !rgbColors.length) {
		return new Style({
			stroke: new Stroke({
				color: `rgba(0,0,0,0.7)`,
				width: 2,
			}),
			fill: new Fill({
				color: `rgba(0,0,0,0.2)`,
			}),
		});
	}

	// If length is 1, return the style with the color
	if(rgbColors.length === 1) {
		const { r, g, b, a } = rgbColors[0];

		return new Style({
			stroke: new Stroke({
				color: `rgba(0,0,0,0.7)`,
				width: 2,
			}),
			fill: new Fill({
				color: `rgba(${r}, ${g}, ${b}, ${a})`,
			}),
		});
	}

	// Base style for the hexagon
	const styles = [
		new Style({
			stroke: new Stroke({
				color: `rgba(0,0,0,0.7)`,
				width: 2,
			}),
		})
	];

	// Add style for each color provided
    rgbColors.forEach(function(rgbColor) {
		const { r, g, b, a } = rgbColor;

		styles.push(new Style({
			stroke: new Stroke({
				// Use the last color for the stroke
				color: 'rgba(0,0,0,0)',
				width: 2,
			}),
			fill: new Fill({
				color: `rgba(${r}, ${g}, ${b}, ${a})`,
			}),
		}));
	});

	return styles;
}

const hexagonStyleForExport = (rgbColor) => {
	if(!rgbColor) {
		return new Style({
			stroke: new Stroke({
				color: `rgba(0,0,0,0.7)`,
				width: 2,
			}),
			fill: new Fill({
				color: `rgba(0,0,0,0.2)`,
			}),
		});
	}

	const { r, g, b, a } = rgbColor;

	return new Style({
		stroke: new Stroke({
			color: `#000`,
			width: 2,
		}),
		fill: new Fill({
			color: `rgba(${r}, ${g}, ${b}, ${a})`,
		}),
	});
}

const mapMouseOver = (evt, tooltip, tooltipRef) => {
	let currentFeature;
	const mapObject = evt.map;

	if (evt.dragging) {
		clearTooltip({ tooltip, tooltipRef });
		currentFeature = undefined;
		return;
	}
	const pixel = mapObject.getEventPixel(evt.originalEvent);

	const displayFeatureInfo = function (pixel, target, currentFeature) {
		const feature = target.closest('.ol-control')
			? undefined
			: mapObject.forEachFeatureAtPixel(pixel, function (feature) {
				return feature;
			});
		if (feature) {
			if (feature !== currentFeature) {
				// show tooltip
				tooltip.setPosition(evt.coordinate);
				tooltipRef.innerHTML = feature.get('name');
			}
		} else {
			// hide tooltip
			clearTooltip({ tooltip, tooltipRef });
		}
		currentFeature = feature;
	};

	displayFeatureInfo(pixel, evt.originalEvent.target, currentFeature);
}
