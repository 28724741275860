import {
	forwardRef,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { Link, useNavigate } from 'react-router-dom';

import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Dropdown from 'react-bootstrap/Dropdown';
import ProgressBar from 'react-bootstrap/ProgressBar';
import {
	AiOutlineDelete,
	AiOutlineEdit,
	AiOutlineMore,
	AiOutlineTags,
} from 'react-icons/ai';
import { Spinner } from 'react-bootstrap';

import { useAuth } from '@contexts/User.context';
import { getJobProgressStream, getProjectJobs } from '@utils/api';
import { getJobName } from '@utils/helpers';
import { humanFileSize } from '@utils/files';
import ProjectTags from '../projectTags/projectTags.component';
import ProjectTagsModal from '../projectTags/projectTagsModal';
import ArchiveProjectDialog from '@components/map/archive/archiveProjectDialog.component';

import logo from '@assets/logo/logo_white.svg';

import { fileApiUrl } from '@utils/api';
import { getToken } from '@contexts/Token.context';

const StyledProjectCard = styled(Card)`
	color: ${p => p.theme.colors.textColor};
	background-color:
		${p => (p.demo === 'true') ? p.theme.colors.backgroundAccent :
		(p.error === 'true' ? 'var(--bs-danger-bg-subtle)' :
		p.theme.colors.modal)};
	border: none;
	margin-bottom: calc(var(--bs-gutter-x));
	min-height: calc(100% - var(--bs-gutter-x));

	.card-footer{
		background: none;
	}
`;

const StyledActionsWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	padding-bottom: var(--bs-card-spacer-y);

	.action-btn {
		font-weight: 400;

		&:not(:hover) {
			background-color: ${p => p.theme.colors.border};
		}
	}
`;

const StyledImageLoader = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	aspect-ratio: 1 / 1;
`;

const StyledCardImg = styled(Card.Img)`
	&.fallback {
		background-color: #000;
		aspect-ratio: 1 / 1;
		padding: 40px;
	}
`;

const StyledDropdownToggleButton = styled(Button)`
	background-color: ${p => p.theme.colors.border};
	padding: 0;
	font-size: 1.5rem;
	width: 36px;
	height: 36px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const StyledBadgeWrapper = styled.div`
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	padding: 0.2rem 0.3rem;
	width: 100%;
	position: absolute;
	top: 0;
	right: 0;
`;

const StyledProgress = styled.div`
	> span {
		font-size: 0.8rem;
	}
`;

const ProjectCard = ({project, refetchProjects}) => {
	const timer = useRef(null);

	const {tierTrial, tierPro} = useAuth();

	const {
		uuid: projectID,
		title,
		description,
		created_at: createdAt,
		tags,
		file_storage: fileStorage,
		orthophoto_progress,
		demo_project: isDemo,
	} = project;

	const [job, setJob] = useState(null);
	const [progress, setProgress] = useState(null);

	const [showTagsModal, setShowTagsModal] = useState(false);

	const [projectToBeArchived, setProjectToBeArchived] = useState(null);

	const error = !!orthophoto_progress?.error;

	const navigate = useNavigate();

	const handleDropdownSelect = eventKey => {
		switch (eventKey) {
			case 'edit':
				navigate(`/projects/${projectID}/edit`);
				break;
			case 'tags':
				setShowTagsModal(true);
				break;
			case 'delete':
				setProjectToBeArchived({ id: projectID, title: title });
				break;
			default:
				console.log('Unknown eventKey: ', eventKey);
		}
	};

	useMemo(async () => {
		if (!projectID || job) return;

		const jobs = await getProjectJobs(projectID);

		if (!jobs?.length > 0) return;

		const currentJob = jobs.pop();

		setJob(currentJob);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [projectID, job]);

	useEffect(() => {
		if (!job) return;

		const stream = getJobProgressStream(job.uuid);

		// Event handler for receiving SSE events
		stream.onmessage = event => {
			console.log('Received event:', event.data);
			if (!event.data) return;

			const data = JSON.parse(event.data);

			if (data.job_status === 'running') {
				setProgress({
					label: `Running ${getJobName(job.job_type)}`,
					value: Math.ceil(data.progress),
				});
			} else if (data.job_status === 'pending') {
				setProgress({
					label: `Pending ${getJobName(job.job_type)}`,
					value: 0,
				});
			} else if (
				data.job_status === 'failed' ||
				data.job_status === 'killed'
			) {
				setProgress({
					label: `Failed processing ${getJobName(job.job_type)}`,
					value: 0,
				});

				stream.close();

				timer.current = setTimeout(() => {
					setProgress(null);
				}, 20000);
			} else {
				setProgress({
					label: `Finished processing ${getJobName(job.job_type)}`,
					value: 100,
				});

				stream.close();

				timer.current = setTimeout(() => {
					setProgress(null);
				}, 5000);
			}
		};

		// Event handler for SSE errors
		stream.onerror = error => {
			console.error('SSE error:', error);
			stream.close();
			setProgress(null);
		};

		return () => {
			stream.close();
			clearTimeout(timer.current);
		};

	}, [job]);


	return (
		<StyledProjectCard error={error.toString()} demo={isDemo.toString()}>
			{tags?.length > 0 && (
				<StyledBadgeWrapper>
					<ProjectTags projectID={projectID} tags={tags} />
				</StyledBadgeWrapper>
			)}

			<CardImage projectID={projectID} />

			<Card.Body>
				{error && (
					<p className="alert alert-danger small p-2">Project processing has failed</p>
				)}
				<Card.Title>{title ? title : 'Untitled project'}</Card.Title>
				<Card.Subtitle className="mb-2 text-muted fst-italic">
					{new Date(createdAt).toLocaleDateString('nb-NO', {
						day: 'numeric',
						month: 'short',
						year: 'numeric',
					})}
				</Card.Subtitle>

				<Card.Text>{description}</Card.Text>

				{tierPro && !isDemo && (
					<p className="small text-muted mb-0">{humanFileSize(fileStorage)} storage</p>
				)}
			</Card.Body>

			<Card.Footer className="border-0 background-none">
				<StyledActionsWrapper>
					<Link
						to={`/projects/${projectID}/`}
						className="btn-outline-light border-0 action-btn btn">
						View Project
					</Link>

					{!isDemo && (
						<CardDropdown
							projectID={projectID}
							handleDropdownSelect={handleDropdownSelect}
							tierTrial={tierTrial}
							isDemo={isDemo}
						/>
					)}

				</StyledActionsWrapper>

				{progress?.label && (
					<StyledProgress>
						<div>
							{progress?.value === 0 && (
								<>
									<Spinner
										animation="border"
										variant="success"
										size="sm"
										role="status">
										<span className="visually-hidden">
											Loading progress
										</span>
									</Spinner>{' '}
								</>
							)}
							<span>{progress.label}</span>
						</div>

						{progress?.value > 0 && (
							<ProgressBar
								now={progress.value}
								label={`${progress.value} %`}
							/>
						)}
					</StyledProgress>
				)}
			</Card.Footer>

			{showTagsModal && (
				<ProjectTagsModal
					show={showTagsModal}
					setShow={setShowTagsModal}
					projectId={projectID}
					projectTitle={title}
					existingTags={tags}
					callBack={refetchProjects}
				/>
			)}

			{projectToBeArchived && (
				<ArchiveProjectDialog
					project={projectToBeArchived}
					callBack={() => {
						setProjectToBeArchived(null);
						refetchProjects();
					}}
				/>
			)}
		</StyledProjectCard>
	);
};

export default ProjectCard;


const CardImage = ({projectID}) => {

	const [image, setImage] = useState(null);

	const fetchImage = useCallback(async () => {

		const url = `${fileApiUrl}getfile?key=${projectID}/odm_orthophoto/thumbnail.png`;
		const token = getToken();
		const response = await fetch(url, {
			method: 'GET',
			headers: {
				authorization: `Bearer ${token}`,
			},
		});

		if (!response.ok || response.status === 204 || !response.url){
			// Set fallback image
			setImage({
				isFallback: true,
				src: logo,
			});
			return;
		}

		setImage({
			isFallback: false,
			src: response.url,
		});

	}, [projectID]);

	useEffect(() => {
		fetchImage();

	}, [fetchImage]);

	return(
		<>
			{!image ? (
				<StyledImageLoader>
					<Spinner animation="border" role="status">
						<span className="visually-hidden">Loading image</span>
					</Spinner>
				</StyledImageLoader>
			) : (
				<Link to={`/projects/${projectID}/`}>
					<StyledCardImg
						variant="top"
						src={image.src}
						className={image.isFallback ? 'fallback' : ''}
					/>
				</Link>
			)}
		</>
	);

};

const CustomToggle = forwardRef(({ children, onClick }, ref) => (
	<StyledDropdownToggleButton
		className="rounded-circle btn-dark"
		size="lg"
		ref={ref}
		onClick={e => {
			onClick(e);
		}}
	>
		{children}
	</StyledDropdownToggleButton>
));

const CardDropdown = ({
	projectID,
	handleDropdownSelect,
	tierTrial,
}) => {
	return(
		<Dropdown
			onSelect={eventKey => handleDropdownSelect(eventKey)}

		>
			<Dropdown.Toggle
				as={CustomToggle}
				aria-label="Toggle project actions"
				id={`action-menu-${projectID}`}
			>
				<AiOutlineMore aria-hidden="true" />
			</Dropdown.Toggle>

			<Dropdown.Menu variant="dark">
				<Dropdown.Item as="button" eventKey="edit">
					<AiOutlineEdit
						className="m-1 mb-2"
						aria-hidden="true"
					/>
					Edit
				</Dropdown.Item>

				<Dropdown.Item as="button" eventKey="tags">
					<AiOutlineTags
						className="m-1 mb-2"
						aria-hidden="true"
					/>
					Edit tags
				</Dropdown.Item>

				<Dropdown.Divider className="border-secondary" />

				<Dropdown.Item as="button" eventKey="delete" disabled={tierTrial}>
					<AiOutlineDelete
						className="m-1 mb-2"
						aria-hidden="true"
					/>
					Delete
				</Dropdown.Item>
			</Dropdown.Menu>
		</Dropdown>
	);
}
