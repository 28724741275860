// React imports
import { useEffect, useState, useRef } from "react";

// Third-party library imports
import { Tooltip } from 'react-tooltip';
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { VscRecord } from "react-icons/vsc";
import { BiShapePolygon } from "react-icons/bi";
import { BsFillEraserFill } from "react-icons/bs";

// Contexts
import { useProject } from "@contexts/Project.context";
import { useToast } from "@contexts/Toast.context";

// Hooks
import useNotify from '@components/notifictions/notify';
import useKeyboardShortcut from "@hooks/useKeyboardShortcut.hook";

// Utils
import { setSquareBoundary } from "@utils/map/annotation.interaction";
import { initAnnotationSquare, removeAnnotationSquare } from "@utils/map/square.draw";
import { startCircleAnnotation, stopDrawingCircles, getCirclesInSquare } from "@utils/map/circle.draw";
import { startPolygonAnnotation, getPolygonsInSquare, stopDrawingPolygons } from "@utils/map/polygon.draw";
import { addErasorInteraction, removeErasorInteraction, startErasor, stopErasor } from "@utils/map/erasor.interaction";
import { setLayersVisibilityByCustomIds } from "@utils/map/helpers";

// Components
import { AnnotationToolBar, KeyboardShortcut } from "@components/map/sharedStyles.component";
import UtilityButton from "./utilityButtons/UtilityButton";

// API
import { postTileData, initTraining } from "@api";

export default function AnnotateOrthophoto({layersToShow, hideAnnotationSidebar}) {

	const {
		features,
		mapObject,
		polygonLayer,
		project,
		taskId,
		pickedTask,
		annotationType,
		annotationMode,
		toolBarVisible,
		colorOptions,
		confirmModalContent,
		dispatch,
	} = useProject();

	const {gridFeatures: squaresInAreaOfInterest} = annotationMode;

	const { checkForJobs } = useNotify();
	const { addToast } = useToast();

	const [loading, setLoading] = useState(false);

    const [activeSquareIndex, setActiveSquareIndex] = useState(0);
    const [drawing, setDrawing] = useState(false);

	const isTraining = useRef(false); // To prevent re-rendering of the annotation type

	const DrawingIcon = () => {
		if(annotationType === "Circle"){
			return <VscRecord style={{ marginTop: "-4px", marginRight: "5px" }} />
		}
		return <BiShapePolygon style={{ marginTop: "-4px", marginRight: "5px" }} />;
	}

	// End annotation session and start training
	const endAnnotation = async () => {
		const confirmContinue = await saveTileData();

		if(confirmContinue){
			initTraining(project?.uuid, taskId).then((res) => {
				console.log('init training', res);
				if(res?.data?.warning){
					addToast({
						id: `training_warning-${new Date().getTime()}`,
						className: 'bg-warning',
						title: `Training model '${pickedTask.description}' started with warnings:`,
						message: res.data.warning,
						autohide: false,
					});
				} else if(res?.data?.error || res?.data?.detail || res === undefined){
					addToast({
						id: `training_error-${new Date().getTime()}`,
						className: 'bg-danger',
						title: `Training model '${pickedTask.description}' failed:`,
						message: (res?.data?.error || res?.data?.detail) ?? 'Unknown error starting training.',
						autohide: false,
					});
				} else if(res?.data?.status === 'success'){
					addToast({
						id: `training_success-${new Date().getTime()}`,
						className: 'bg-success',
						title: `Training model '${pickedTask.description}' started successfully`,
						autohide: false,
					});
				}
			}).finally(() => {

				stopAnnotation();
				setTimeout(() => {
					checkForJobs();
				}, 5000);
			});
		}
	};

	// Stop annotation session
	const stopAnnotation = () => {
		console.log("stopping annotation");

		if(mapObject){
			const layers = mapObject.getLayers();
			layers?.forEach(layer => {
				const name = layer.get('name');
				if(layersToShow.includes(name)){
					layer.setVisible(true);
				}
			});
		}

		setActiveSquareIndex(null);
		removeAnnotationSquare(mapObject);
		removeErasorInteraction(mapObject);

		mapObject.on('pointermove', () => {mapObject.getTargetElement().style.cursor = 'auto'});

		if (annotationType === "Circle"){
			stopDrawingCircles(mapObject);
		} else if (annotationType === "Polygon") {
			stopDrawingPolygons(mapObject);
		};

		dispatch({
			type: 'setDialogue',
			payload: null,
		});
		dispatch({
			type: 'setToolBarVisible',
			payload: true,
		});
		dispatch({
			type: 'setAnnotationMode',
			payload: null,
		});

		setLayersVisibilityByCustomIds(mapObject, layersToShow, true);
		hideAnnotationSidebar();

	};

	// Save the annotations in the current square
	const saveTileData = async () => {
		setLoading(true);
		const currentTileName = squaresInAreaOfInterest[activeSquareIndex]?.properties.tile_name;
		let data;

		if (annotationType === "Circle") {
			data = getCirclesInSquare(squaresInAreaOfInterest[activeSquareIndex], taskId);
		} else if (annotationType === "Polygon") {
			data = getPolygonsInSquare(squaresInAreaOfInterest[activeSquareIndex]);
		}

		if(!data?.annotations || data?.annotations?.length === 0){
			if(!window.confirm("No new annotations were made in this square. It could affect the model if something's missed. Still good to go?")){
				// Cancel saving data to annotate more
				setLoading(false);
				return false;
			}

			// Backend does not want an empty tile to be posted if there are overlapping annotations
			if(data.hasOverlappedAnnotations){
				console.log('Skipping saving empty tile with overlapping annotations');
				setLoading(false);
				return true;
			}
		}

		const res = await postTileData(project?.uuid, taskId, currentTileName, data.annotations)
			.then(() => {
				return true;
			})
			.catch((error) => {
				console.error(error);
				return false;
			}).finally(() => {
				setLoading(false);
			}
		);

		return res;
	}

	// change the square to annotate
	const onChangeSquare = (index) => {
		setActiveSquareIndex(index);
		setSquareBoundary(squaresInAreaOfInterest[index]);
		initAnnotationSquare(squaresInAreaOfInterest[index], mapObject);
	};

	// Move to the next square
	const nextSquare = async () => {
        if(loading) return;

        if (activeSquareIndex < squaresInAreaOfInterest.length - 1) {
            const confirmContinue = await saveTileData();
            if(confirmContinue){
				onChangeSquare(activeSquareIndex + 1);
            }
        } else if (activeSquareIndex === squaresInAreaOfInterest.length - 1) {
            endAnnotation();
        }
    };

	/*
    const prevSquare = () => {
        if (activeSquareIndex > 0) {
            setActiveSquareIndex(activeSquareIndex - 1);
            setSquareBoundary(squaresInAreaOfInterest[activeSquareIndex - 1]);
        }
    };
    */

	// Start drawing
	const startDraw = () => {
		setDrawing(true);
        stopErasor(mapObject);
	};

	// Start erasing
	const startErase = () => {
		setDrawing(false);
		startErasor(mapObject);
	};

	// Creates a confirm modal to cancel the annotation session
	const cancel = () => {
		dispatch({
			type: 'setConfirmModalContent',
			payload: {
				title: "Cancel annotation session",
				message: "Are you sure you want to cancel the annotation mode and exit?",
				onConfirm: stopAnnotation,
				onCancel: () => {},
			},
		});
	};

	// Keyboard shortcuts
	useKeyboardShortcut('Enter', (!!annotationMode && confirmModalContent === null), nextSquare);
	useKeyboardShortcut('Escape', (!toolBarVisible && confirmModalContent === null), () => cancel() );
	useKeyboardShortcut('D', !!annotationMode, () => startDraw() );
	useKeyboardShortcut('E', !!annotationMode, () => startErase() );

	// Starts the right annotation type
	useEffect(() => {
		if(activeSquareIndex === null || isTraining.current) return;

		isTraining.current = true;
		console.log("Starting annotation mode");

		if (annotationType === "Circle") {

			console.log("Starting circle annotation");
			startCircleAnnotation(
				colorOptions,
				features,
				squaresInAreaOfInterest,
				mapObject
			);
			setDrawing(true);

		} else if (annotationType === "Polygon") {
			startPolygonAnnotation(
				colorOptions,
				polygonLayer,
				squaresInAreaOfInterest,
				mapObject
			);
			setDrawing(true);
		}

		dispatch({
			type: 'setDialogue',
			payload: {
				header: "Annotating",
				body: "Please ensure that all relevant objects within a square are annotated before clicking 'Continue' to maintain annotation accuracy."
			},
		});

		initAnnotationSquare(squaresInAreaOfInterest[activeSquareIndex], mapObject); // draws the first square
		setSquareBoundary(squaresInAreaOfInterest[activeSquareIndex]);

		mapObject.getView().animate({
			zoom: 21.5,
			duration: 300,
		});

		addErasorInteraction(annotationType, mapObject);

	}, []);

	return (
		<>
			<AnnotationToolBar aria-label="Annotation tools">

				<Tooltip
					id="annotate-tip"
					variant="light"
					render={({ content, activeAnchor }) => (
						<span>
							{content} <KeyboardShortcut>{activeAnchor?.getAttribute('data-tooltip-keyboardshortcut')}</KeyboardShortcut>
						</span>
						)}
				/>

				<div>
					<UtilityButton
						label="Cancel"
						tooltip={{
							id: "annotate-tip",
							content: "Cancel the annotation mode and exit",
							place: "top",
						}}
						onClick={cancel}
						variant="danger"
						keyboardShortcutLabel="ESC"
					/>
				</div>

				<ButtonGroup>
					<UtilityButton
						label="Draw"
						tooltip={{
							id: "annotate-tip",
							content: "Draw annotations on the current square",
							place: "top",
						}}
						onClick={startDraw}
						variant={drawing ? "success" : "dark"}
						keyboardShortcutLabel="D"
						icon={() => <DrawingIcon />}
					/>

					<UtilityButton
						label="Erase"
						tooltip={{
							id: "annotate-tip",
							content: "Erase annotations from the current square",
							place: "top",
						}}
						onClick={startErase}
						variant={!drawing ? "success" : "dark"}
						keyboardShortcutLabel="E"
						icon={() => <BsFillEraserFill style={{ marginTop: "-4px", marginRight: "5px" }} />}
					/>
				</ButtonGroup>


				<ButtonGroup>
					<UtilityButton
						label="Start AI-training"
						tooltip={{
							id: "annotate-tip",
							content: "End annotation session and start AI-training",
							place: "top",
						}}
						onClick={() => {
							dispatch({
								type: 'setConfirmModalContent',
								payload: {
									title: "End annotation session",
									message: "Are you sure you want end the annotation session? Your data will be saved and AI-training will start.",
									onConfirm: endAnnotation,
									onCancel: () => {},
								},
							});
						}}
						disabled={loading}
						variant="dark"
					/>

					{
						activeSquareIndex <= squaresInAreaOfInterest.length - 1 ? (
							<UtilityButton
								label="Continue"
								tooltip={{
									id: "annotate-tip",
									content: "Save the annotations in the current tile and move to the next tile",
									place: "top",
								}}
								disabled={loading}
								loading={loading}
								onClick={nextSquare}
								variant="success"
								keyboardShortcutLabel="↵"
							/>
						) : null
					}
				</ButtonGroup>

			</AnnotationToolBar>
		</>
	);
}
