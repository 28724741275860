import { useEffect, useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { useQuery, useMutation } from '@tanstack/react-query';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';

import Input from '@components/form/Input';
import AdvancedSelect from '@components/form/advancedSelect';
import Alert from '@components/alert/Alert';

import {
	adminGetUsers,
	getOrganization,
	createOrganization,
	updateOrganization,
} from '@api';
import AdminView from '@components/view/AdminView';

const StyledActionsWrapper = styled.div`
	margin-top: calc(var(--bs-gutter-x) * 2);
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

const Organization = () => {
	const {organization_uuid} = useParams();
	const navigate = useNavigate();

	const [adminUsers, setAdminUsers] = useState([]);
	const [members, setMembers] = useState([]);
	const [userOptions, setUserOptions] = useState([]);
	const [membersOptions, setMembersOptions] = useState([]);
	const [projectTags, setProjectTags] = useState([]);
	const [saving, setSaving] = useState(false);

	const { isLoading, isError, data: organization, error } = useQuery({
		queryKey: ['organization', organization_uuid],
		queryFn: () => getOrganization(organization_uuid),
		enabled: !!organization_uuid,
	  });

	const { data: userData, isLoading: userDataLoading } = useQuery({
		queryKey: ['organization_available_users'],
		queryFn: () => { return adminGetUsers({limit: 400})},
	})

	const mutationCreate = useMutation({
        mutationFn: (org) => {
        	return createOrganization(org)
        },
		onSuccess: () => {
			navigate(-1);
		},
		onSettled: () => {
			setSaving(false);
		}
    })

	const mutationUpdate = useMutation({
        mutationFn: (updatedOrg) => {
        	return updateOrganization(updatedOrg)
        },
		onSettled: () => {
			setSaving(false);
		}
    })

	const handleAdminSelectChange = (selected) => {
		setAdminUsers(selected);

		// Remove selected admins from membersOptions
		const usersWithoutAdmins = userOptions.filter(user => !selected.some(selectedUser => selectedUser.value.user_uuid === user.value.user_uuid));
		setMembersOptions(usersWithoutAdmins);

		// Remove selected admins from members
		setMembers(members.filter(member => !selected.some(selectedUser => selectedUser.value.user_uuid === member.value.user_uuid)));
	};


	const handleSubmit = async e => {
		e.preventDefault();

		setSaving(true);

		let updateData = {
			members: [],
			project_tags: projectTags || [],
		};

		if (organization_uuid) {
			updateData.uuid = organization_uuid;
		}

		adminUsers?.forEach(m => {
			updateData.members.push({
				user_uuid: m.value.user_uuid,
				role: 'admin',
				access: []
			});
		});

		members?.forEach(m => {
			updateData.members.push({
				user_uuid: m.value.user_uuid,
				role: 'user',
				access: m.value.access ?? ['create']
			});
		});

		Object.keys(e.target.elements).forEach(key => {
			const el = e.target.elements[key];
			if (el.name === 'members' || el.name === 'project_tags') {
				return;
			}

			if (el.value) {
				updateData[el.name] = el.value;
			}
		});

		if (updateData.uuid){
			mutationUpdate.mutate(updateData);
		} else {
			mutationCreate.mutate(updateData);
		}

	};

	useEffect(() => {

		if(!organization) return;

		setProjectTags(
			organization?.project_tags ?? []
		);

		if (organization?.members?.length > 0) {

			// Set members already in the organization
			setMembers(
				organization.members
					.filter(m => m.role === 'user')
					.map(m => ({
					value: m,
					label: m.user_email,
				}))
			);

			// Set admin users already in the organization
			setAdminUsers(
				organization?.members
					.filter(m => m.role === 'admin')
					.map(m => ({
					value: m,
					label: m.user_email,
				}))
			);
		}

		if(userOptions.length > 0) return;

		const users = userData?.users?.map(user => ({user_uuid: user.uuid, user_email: user.email})) ?? [];
		setUserOptions(users.map(user => ({
			value: user,
			label: user.user_email,
		}))	);

		// Filter out selected admin users from membersOptions
		const usersWithoutAdmins = users.filter(user => !(organization?.members.some(member => member.user_uuid === user.user_uuid && member.role === 'admin')));
		setMembersOptions(
			usersWithoutAdmins.map(user => ({
				value: user,
				label: user.user_email,
			}))
		);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userData, organization]);

	if((organization_uuid && isLoading) || userDataLoading) return <AdminView title="Organization: loading..." />;


	if(isError) return (
		<AdminView title="Error loading organization">
			<Alert variant="danger">
				<p className="mb-0">Organization could not be loaded. Error: {error?.response?.data?.detail}</p>
			</Alert>
		</AdminView>
	);

	return (
		<AdminView
			title={
				isError ? 'Error loading organization' :
				organization?.name
					? `Organization: ${organization?.name}`
					: 'Create organization'
			}>

			{isError ? (
				<div className="alert alert-danger">
					<p>Organization could not be loaded. Error: {error?.message}</p>
				</div>
			) : (

				<>

					{mutationCreate.isError ? (
						<div className="alert alert-danger">
							Could not create organization. Error: {mutationCreate.error?.message}
						</div>
					) : null}

					{mutationUpdate.isError ? (
						<div className="alert alert-danger">
							Could not update organization. Error: {mutationUpdate.error?.message}
						</div>
					) : null}

					{mutationUpdate.isSuccess ? (
						<div className="alert alert-success">
							Organization successfully updated!
						</div>
					) : null}

					<form onSubmit={handleSubmit}>
						<div className="pb-4">
							<Input
								label="Name"
								name="name"
								defaultValue={organization?.name ?? ''}
								required
							/>

							<Input
								label="Description"
								name="description"
								defaultValue={organization?.description ?? ''}
							/>
						</div>

						<div className="pb-4">
							<Input
								label="Organization number"
								type="number"
								name="org_no"
								defaultValue={organization?.org_no ?? ''}
							/>

							<Input
								label="Address"
								name="address"
								defaultValue={organization?.address ?? ''}
							/>

							<Input
								label="Postal code"
								name="postal_code"
								defaultValue={organization?.postal_code ?? ''}
							/>

							<Input
								label="City"
								name="city"
								defaultValue={organization?.city ?? ''}
							/>

							<Input
								label="Country"
								name="country"
								defaultValue={organization?.country ?? ''}
							/>
						</div>

						<div className="pb-4">

							<AdvancedSelect
								id="organization-admin-select"
								label="Organization admin"
								value={adminUsers}
								options={userOptions}
								onChange={handleAdminSelectChange}
								isMulti
							/>

							<AdvancedSelect
								id="organization-members-select"
								label="Members"
								value={members}
								options={membersOptions.filter(option => !members.some(member => member.label === option.label))}
								onChange={setMembers}
								isMulti
							/>
						</div>

						<AdvancedSelect
							id="organization-tags"
							label="Project tags (by assigning project tags, users in this organization will be limited to add only these tags to their projects)"
							noOptionsMessage={() =>
								'Start typing to add a tag'
							}
							value={projectTags?.map((t) => ({
								value: t,
								label: t,
							}))}
							onChange={e => {
								setProjectTags(e.map(tag => tag.label));
							}}
							isMulti
							creatable
						/>

						<StyledActionsWrapper>
							<div className="d-flex w-100 gap-2 justify-content-between align-content-center">
								<div>
									<Button
										type="button"
										variant="secondary"
										onClick={() => navigate(-1)}
										disabled={isLoading}>
										Back
									</Button>

									<Button
										type="submit"
										variant="success"
										className="ms-2"
										disabled={(isLoading && organization_uuid) || saving}>
											{ organization?.name ? ( saving ? 'Saving...' : 'Save')
											: ( saving ? 'Creating...': 'Create')}
									</Button>
								</div>
								<div>
									<Link to={`/organization/${organization_uuid}/profile`}>View org. admin profile</Link>
								</div>
							</div>
						</StyledActionsWrapper>
					</form>
				</>
			)}
		</AdminView>
	);
};

export default Organization;
