import { useCallback, useEffect, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";

import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import { Feature } from "ol/index";
import { Polygon, MultiPolygon } from "ol/geom";

import { useProject } from "@/contexts/Project.context";
import { getMasterFeatures } from "@/utils/api";
import { deleteLayerByCustomId, getLayerByCustomId } from "@utils/map/helpers";

import Checkbox from "../sidebars/sidebarElements/checkbox.component";

/*
*  This component is responsible for rendering the area of interest layer and toggling its visibility.
*/
const AoiLayer = () => {

    const {
        mapObject,
        project,
        taskId,
    } = useProject();

    const {data, isError, error} = useQuery({
		queryKey: ["aoi_master_features", project.uuid, taskId],
		queryFn: () => getMasterFeatures(project.uuid, taskId, "detected_areas"),
		enabled: !!project?.uuid && !!taskId,
		refetchOnWindowFocus: false,
        retry: false,
	});

    const adding = useRef(false);
    const [aoiLayer, setAoiLayer] = useState(null);
	const layerId = 'aoiLayer';

    const updateAoiLayer = useCallback(() => {

        // if the map object exists, task ID and project ID is set, create and add the layer
        if(mapObject && taskId && !adding.current){
            adding.current = true;
            let layerSource = null;

            if(!data){
                adding.current = false;
                return;
            }

			const existingLayer = getLayerByCustomId(mapObject, layerId);

            // get the feature for area of interest from the API
            if (data.features?.length && data.features[0]?.geometry) {

                // create the geometry feature
                const geometry = createGeometryFeature(data.features[0].geometry);
                if(geometry) {
                    const aoiVectorSource = new VectorSource({
                        features: [
                            new Feature({
                                geometry: geometry,
                            }),
                        ]
                    });

					if(existingLayer){
						console.log(`Model Boundry Layer already exists. Adding it to state.`);
						layerSource = existingLayer;
						// Update source
						layerSource.setSource(aoiVectorSource);
					} else {

						// create the layer
						layerSource = new VectorLayer({
							source: aoiVectorSource,
							zIndex: 11,
							name: 'Model boundary',
							style: {
								'stroke-color': 'rgba(255, 255, 0, 0.8)',
								'stroke-width': 4,
								'stroke-line-dash': [10, 10],
							},
							properties: {
								customLayerId: layerId,
							},
						});

						// add the layer to the map
						mapObject.addLayer(layerSource);
						console.log('added aoi layer');
					}
                }
            } else if(existingLayer) {
				// No data related to the layer and it is not needed anymore. Delete it.
				deleteLayerByCustomId(mapObject, layerId);
			}

            setAoiLayer(layerSource);
            adding.current = false;
        }
    }, [data]);

    // Create an OpenLayers geometry feature from the GeoJSON geometry
    const createGeometryFeature = (geometry) => {
        if (geometry.type === 'Polygon') {
          return new Polygon(geometry.coordinates);
        } else if (geometry.type === 'MultiPolygon') {
          return new MultiPolygon(geometry.coordinates);
        }

        return null; // Handle other geometry types if needed
    };

    useEffect(() => {
        updateAoiLayer();

        if(isError){
            console.warn('could not fetch aoi', error);
            if (mapObject && aoiLayer) {
				deleteLayerByCustomId(mapObject, layerId);
                setAoiLayer(null);
            }
        }

		return () => {
			setAoiLayer(null);
		};
    }, [updateAoiLayer, isError]);

    if(!aoiLayer) return null;

    return(
        <div id="aoiLayer">
            {
                aoiLayer ? (
                    <Checkbox
                        label={aoiLayer.get("name")}
                        canEdit={false}
                        handleCheck={() => aoiLayer.setVisible(true)}
                        handleUncheck={() => aoiLayer.setVisible(false)}
                    />
                ) : null
            }
        </div>
    );

};
export default AoiLayer;
