import { useState } from "react";
import { useMutation, useQueryClient } from '@tanstack/react-query';
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";

import { adminDeleteUser } from "../../../../utils/api";

import { StyledModal } from "../../../modal/sharedModalStyles";

const DeleteModal = ({ data, show, setShow}) => {
	const queryClient = useQueryClient();

	const [inputValue, setInputValue] = useState("");

	const label = "Type DELETE in all caps to confirm this action";

	const resetStates = () => {
		setInputValue("");
        setShow();
	};

	const mutation = useMutation({
        mutationFn: (uuid) => {
          return adminDeleteUser(uuid)
        },
		onSuccess: async () => { 

			await queryClient.refetchQueries({ queryKey: ['admin_users'], type: 'active' })
			resetStates();
		}
    })

	const handleDeleteUser = async () => {
		mutation.mutate(data.uuid);
	};

	return (
		<StyledModal
			variant="dark"
			show={show}
			centered={true}
			onHide={() => {
				resetStates();
			}}
		>
			<Modal.Header closeButton closeVariant="white">
				<Modal.Title>Delete user</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				{mutation.isLoading && <Spinner animation="border" variant="primary" />}

				{!mutation.isLoading && mutation.isError && (
					<p>
						Error: {mutation.error?.response?.data?.detail ?? mutation.error?.message}
					</p>
				)}

				{!mutation.isLoading && !mutation.isError && (
					<>
						<p>
							You are about to delete user{' '}
							<strong>
								<i>{data.email}</i>
							</strong>
							.
						</p>

						<Form
							onSubmit={(e) => {
								e.preventDefault();

								handleDeleteUser();
							}}
						>
							<p>
								Please type <strong>DELETE</strong> to confirm.
							</p>
							<FloatingLabel
								className="mb-3 text-secondary"
								label={label}
							>
								<Form.Control
									type="text"
									placeholder={label}
									onChange={(e) =>
										setInputValue(e.target.value)
									}
									className="text-white"
                                    autoFocus={true}
								/>
							</FloatingLabel>
						</Form>
					</>
				)}
			</Modal.Body>

			<Modal.Footer>
				<Button variant="secondary" onClick={() => resetStates()}>
					Cancel
				</Button>
				<Button
					variant="danger"
					disabled={inputValue !== "DELETE" || mutation.isLoading || mutation.isError}
					onClick={() => handleDeleteUser()}
				>
					Delete
				</Button>
			</Modal.Footer>
		</StyledModal>
	);
};
export default DeleteModal;
