import Feature from 'ol/Feature';
import Circle from 'ol/geom/Circle';
import Draw from 'ol/interaction/Draw';
import Modify from 'ol/interaction/Modify';
import Snap from 'ol/interaction/Snap';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Fill, Stroke, Style } from 'ol/style';
import {
	activeSquareRef,
	annotationClassId,
	isPointInsideSquare,
} from './annotation.interaction.js';
import { getSquareCenter } from './freeHand.draw.js';

let circleInteraction,
	circleLayer,
	circleSource,
	snapInteraction,
	modifyInteraction;
let annotationClassIds = [];
let colorOptions = null;

let addedCircles = [];
let modifiedCircles = [];

export const startCircleAnnotation = (
	colorOptionsInput,
	existingCircles,
	squareList,
	mapReference
) => {
	colorOptions = colorOptionsInput;
	circleSource = new VectorSource({ wrapX: false });
	circleLayer = new VectorLayer({
		source: circleSource,
		renderBuffer: 500,
		zIndex: 25,
	});
	loadCircles(existingCircles, squareList);

	circleInteraction = new Draw({
		source: circleSource,
		type: 'Circle',
	});
	snapInteraction = new Snap({
		source: circleSource,
	});
	modifyInteraction = new Modify({
		source: circleSource,
	});

	mapReference.addLayer(circleLayer);
	mapReference.addInteraction(circleInteraction);
	mapReference.addInteraction(snapInteraction);
	mapReference.addInteraction(modifyInteraction);

	circleInteraction.on('drawstart', function (e) {
		let isInside = isPointInsideSquare(
			e.feature.getGeometry().getCenter(),
			activeSquareRef
		);

		if (isInside) {
			const currentSquareName = activeSquareRef.properties.tile_name;
			const addedCircle = e.feature;
			addedCircle.values_.classId = annotationClassId;
			addedCircle.fileName = currentSquareName;

			annotationClassIds.push(annotationClassId);
			snapInteraction.setActive(true);
			modifyInteraction.setActive(true);

			addCircleStyle(addedCircle, colorOptions[annotationClassId]);
		} else {
			circleInteraction.finishDrawing();
			snapInteraction.setActive(false);
			modifyInteraction.setActive(false);

			// moves view to the square that the user is trying to draw in
			mapReference.getView().animate({
				center: getSquareCenter(
					activeSquareRef.geometry.coordinates[0]
				),
				zoom: 21.5,
				duration: 300,
			});
		}
	});

	circleInteraction.on('drawend', function (e) {
		const currentSquareName = activeSquareRef.properties.tile_name;
		const addedCircle = e.feature;

		if (addedCircles[currentSquareName] === undefined) {
			addedCircles[currentSquareName] = [];
		}
		addedCircles[currentSquareName].push(addedCircle);
	});

	// does not let the user modify circles outside the square
	let originalPosition = null;
	modifyInteraction.on('modifystart', function (e) {
		const currentFeature = e.features.item(0);
		originalPosition = currentFeature.getGeometry().getCenter();
		const currentSquareName = activeSquareRef.properties.tile_name;
		if (currentFeature.values_.id !== undefined) {
			if (modifiedCircles[currentSquareName] === undefined) {
				modifiedCircles[currentSquareName] = [];
			}

			modifiedCircles[currentSquareName].forEach((circle, index) => {
				if (circle.values_.id === currentFeature.values_.id) {
					modifiedCircles[currentSquareName].splice(index, 1);
				}
			});
			modifiedCircles[currentSquareName].push(currentFeature);
		}

		let isInside = isPointInsideSquare(originalPosition, activeSquareRef);

		if (!isInside) {
			modifyInteraction.setActive(false);
		} else {
			modifyInteraction.setActive(true);
		}
	});

	// makes sure that the user cannot modify the circle outside the square
	modifyInteraction.on('modifyend', function (e) {
		const featureGeometry = e.features.item(0).getGeometry();
		const newPossition = featureGeometry.getCenter();

		let isInside = isPointInsideSquare(newPossition, activeSquareRef);
		if (!isInside) {
			featureGeometry.setCenter(originalPosition);
		}
	});
};

const addCircleStyle = (circle, color) => {
	const { r, g, b } = color;
	circle.setStyle(
		new Style({
			fill: new Fill({
				color: 'rgba(0, 0, 0, 0.05)',
			}),
			stroke: new Stroke({
				color: `rgb(${r}, ${g}, ${b})`,
				width: 3,
			}),
		})
	);
};

// helper function that enables user to delete a circle on click
const deleteCircle = feature => {
	const currentSquareName = activeSquareRef.properties.tile_name;

	if (addedCircles[currentSquareName] !== undefined) {
		addedCircles[currentSquareName].forEach((circle, index) => {
			if (circle.ol_uid === feature.ol_uid) {
				console.log('deleted from addedcircles');
				addedCircles[currentSquareName].splice(index, 1);
				circleSource.removeFeature(feature);
				return;
			}
		});
	}

	if (modifiedCircles[currentSquareName] !== undefined) {
		modifiedCircles[currentSquareName].forEach((circle, index) => {
			if (circle.values_.id === feature.values_.id) {
				console.log('deleted from modified circles');
				modifiedCircles[currentSquareName].splice(index, 1);
				circleSource.removeFeature(feature);
				return;
			}
		});
	}
};

export const getCircleEraseData = () => {
	return {
		interactionsToDeactivate: [
			circleInteraction,
			snapInteraction,
			modifyInteraction,
		],
		selectedLayer: circleLayer,
		deleteFunction: deleteCircle,
	};
};

export const getCirclesInSquare = (square, taskId) => {
	const allPreparedCircles = prepareCircleData(taskId);
	const circlesInSquare = [];
	let hasOverlappedAnnotations = false; // Only need to check this if there are no circles in the square

	allPreparedCircles.forEach(circle => {
		if (circle.tile_name === square.properties.tile_name) {
			circlesInSquare.push(circle);
		}
	});

	// No circles in square, checking for overlapped annotations
	if (circlesInSquare.length === 0) {
		allPreparedCircles.some(circle => {
			hasOverlappedAnnotations = isPointInsideSquare(
				[circle.x_center, circle.y_center],
				square
			);
			return hasOverlappedAnnotations; // This will stop the loop if hasOverlappedAnnotations is true
		});
	}

	return { annotations: circlesInSquare, hasOverlappedAnnotations };
};

/**
 * Stops drawing circles and returns the circles array
 * @returns {Array} an array containing the circles
 */
export const stopDrawingCircles = mapReference => {
	mapReference.removeLayer(circleLayer);
	mapReference.removeInteraction(circleInteraction);
	mapReference.removeInteraction(snapInteraction);
	mapReference.removeInteraction(modifyInteraction);

	// zooms out to the default zoom level
	mapReference.getView().animate({
		zoom: 17,
		duration: 300,
	});

	let results = prepareCircleData();

	addedCircles = [];
	modifiedCircles = [];
	return results;
};

/**
 * loads the circles from the database
 * @param {Array} circles - an array of circles
 * @returns {void}
 * @todo add correct file name to the circles
 */
const loadCircles = (circles, squareList) => {
	let circlesFeatures = [];

	circles.forEach(circle => {
		const x = circle.geometry.coordinates[0];
		const y = circle.geometry.coordinates[1];
		const radius = circle.properties.radius;
		const classId = circle.properties.classid;

		const circleFeature = new Feature({
			id: circle.properties.annotation_uuid,
			classId: classId,
			geometry: new Circle([x, y], radius),
			radius: radius,
			center: [x, y],
		});
		addCircleStyle(circleFeature, colorOptions[classId]);

		circlesFeatures.push(circleFeature);
		circleSource.addFeature(circleFeature);
		annotationClassIds.push(classId);
	});

	squareList.forEach(square => {
		const squareName = square.properties.tile_name;
		if (modifiedCircles[squareName] === undefined) {
			modifiedCircles[squareName] = [];
		}
		circlesFeatures.forEach(circle => {
			if (isPointInsideSquare(circle.getGeometry().getCenter(), square)) {
				circle.values_.squareName = squareName;
				modifiedCircles[squareName].push(circle);
			}
		});
	});
	return circlesFeatures;
};

const prepareCircleData = taskId => {
	let preparedAddedCircles = [];
	let preparedModifiedCircles = [];

	if (Object.keys(addedCircles).length > 0) {
		for (const squareName in addedCircles) {
			addedCircles[squareName].forEach(circle => {
				const circleData = {
					radius: circle.getGeometry().getRadius(),
					center: circle.getGeometry().getCenter(),
					classId: circle.values_.classId,
					tile_name: squareName,
					annotation_type: 'circle',
				};
				preparedAddedCircles.push(circleData);
			});
		}
	}

	if (Object.keys(modifiedCircles).length > 0) {
		for (const squareName in modifiedCircles) {
			modifiedCircles[squareName].forEach(circle => {
				const circleData = {
					radius: circle.getGeometry().getRadius(),
					center: circle.getGeometry().getCenter(),
					id: circle.values_.id,
					classId: circle.values_.classId,
					tile_name: squareName,
					annotation_type: 'circle',
				};
				preparedModifiedCircles.push(circleData);
			});
		}
	}

	const results = {
		new: preparedAddedCircles,
		modified: preparedModifiedCircles,
	};

	let postData = preparePostData(results, taskId);

	return postData;
};

const preparePostData = (data, taskId) => {
	let postData = [];

	if (data.new && data.new.length > 0) {
		for (let i = 0; i < data.new.length; i++) {
			const radius = data.new[i].radius;
			const center = data.new[i].center;
			const x = center[0];
			const y = center[1];
			const square = data.new[i].tile_name;
			const classId = data.new[i].classId;

			const newAnnotation = {
				uuid: 'unknown',
				task_uuid: taskId,
				annotation_type: 'circle',
				machine_annotation: false,
				deprecated: false,
				human_input: true,
				classid: classId,
				x_center: x,
				y_center: y,
				radius: radius,
				tile_name: square,
			};
			postData.push(newAnnotation);
		}
	}

	if (data.deleted && data.deleted.length > 0) {
		for (let i = 0; i < data.deleted.length; i++) {
			console.log('deleted: ', data.deleted[i]);
		}
	}

	if (data.modified && data.modified.length > 0) {
		for (let i = 0; i < data.modified.length; i++) {
			const radius = data.modified[i].radius;
			const center = data.modified[i].center;
			const x = center[0];
			const y = center[1];
			const square = data.modified[i].tile_name;
			const classId = data.modified[i].classId;
			const annotationId = data.modified[i].id;

			const modifiedAnnotation = {
				uuid: annotationId,
				task_uuid: taskId,
				annotation_type: 'circle',
				machine_annotation: true,
				deprecated: false,
				human_input: true,
				classid: classId,
				x_center: x,
				y_center: y,
				radius: radius,
				tile_name: square,
			};
			postData.push(modifiedAnnotation);
		}
	}
	return postData;
};
