import { useState } from 'react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import styled from 'styled-components';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';

import { StyledModal } from '@components/modal/sharedModalStyles';
import Input from '@components/form/Input';
import AreaTransactionsList from '@components/area/AreaTransactionsList';

import { convertArea } from '@utils/helpers';
import { getAreaBalance, adminUseArea, adminAddArea } from "@api";
import { useAuth } from '@contexts/User.context';

const EditModal = ({ user, show, setShow }) => {

    const [showLatestTransactions, setShowLatestTransactions] = useState(false);

    const areaBalanceQuery = useQuery({
        queryKey: ['areaBalance', user.uuid],
        queryFn: () => getAreaBalance({ user_uuid: user.uuid }),
    });

	return (
		<StyledModal
			variant="dark"
			show={show}
			centered={true}
            size="xl"
			onHide={setShow}>
			<Modal.Header closeButton closeVariant="white">
				<Modal.Title>Manage area</Modal.Title>
			</Modal.Header>

            <Modal.Body>
                <Card bg="dark" className="border-0 mb-4" body>
                    <ul className="list-inline mb-0">
                        <li>
                            User: {user.email}
                        </li>
                        <li>
                            Area Balance: {areaBalanceQuery?.data?.area ? `${convertArea(areaBalanceQuery?.data?.area)} hectares` : 'Not loaded'}
                        </li>
                    </ul>
                </Card>
                <p className="text-muted small">
                    Any value added in the fields below will be visible for the user.
                </p>

                <Stack gap={3}>
                    <Card>
                        <Card.Body>
                            <AreaForm user={user} formType="add" />
                        </Card.Body>
                    </Card>

                    <Card>
                        <Card.Body>
                            <AreaForm user={user} formType="remove" />
                        </Card.Body>
                    </Card>
                </Stack>
                <div className="mt-5 mb-3">
                    {showLatestTransactions ? (
                        <>
                            <h3 className="h5">Latest transactions <small>(as seen by the user)</small></h3>
                            <AreaTransactionsList user={user} />
                        </>
                    ) : (
                        <Button variant="outline-light" onClick={() => setShowLatestTransactions(true)}>
                            Load latest transactions
                        </Button>
                    )}

                </div>
            </Modal.Body>


		</StyledModal>
	);
};
export default EditModal;

/**
 * Component to add or remove area from a user
 *
 * @param {object} user
 * @param {string} formType must be either 'remove' or 'add'
 * @returns Component
 */

const Grid = styled.div`
    display: grid;
    grid-template-columns: 2fr 4fr 1fr;
    gap: 1rem;
    align-items: center;
    div:last-child {
        text-align: right;
    }
`;
const AreaForm = ({user, formType}) => {

    const queryClient = useQueryClient();
	const { currentUser } = useAuth();

    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);

	const areaMutation = useMutation({
		mutationFn: ((formData) => {
            if(formType === 'remove') {
                return adminUseArea(formData);
            } else {
                return adminAddArea(formData);
            }
        }),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['areaBalance', user.uuid] });
			queryClient.invalidateQueries({ queryKey: ['transactions', user.uuid] });
            setIsSuccess(true);
            setTimeout(() => {
                setIsSuccess(false);
            }, 4000);
		},
		onError: (err) => {
			console.error(err?.response?.data?.detail || err?.message || err);
			setIsError(true);
			setTimeout(() => {
				setIsError(false);
			}, 4000);
		},
	});

    return (
        <Form
            onSubmit={e => {
                e.preventDefault();

                const data = new FormData(e.target);

                let amount = data.get('amount');
                const note = data.get('note');

                if (!amount) return;

                // Convert amount in hectares to square meters
                amount = amount * 10000;

                areaMutation.mutate({amount, note, user_uuid: user.uuid});

                e.target.reset();
            }}>
                <p className="text-muted">
                    {formType === 'remove' ? 'Remove area from user' : 'Add area to user'}
                </p>

                <Grid>
					<Input name="amount" label="Hectares" type="text" pattern="\d+(\.\d{1,2})?" noWrap required />

                    <Input name="note" label="Note" type="text" noWrap required />

                    <div>
                        <Button type="submit" variant={formType === 'remove' ? 'danger' : 'success'} className="w-100">
                            {formType === 'remove' ? 'Remove' : 'Add'}
                        </Button>
                    </div>
                </Grid>

                {isSuccess && (
                    <p className="text-success mt-4 mb-o">
                        {formType === 'remove' ? 'Area removed successfully' : 'Area added successfully'}
                    </p>
                )}

				{isError && (
					<p className="text-danger mt-4 mb-0">
						{formType === 'remove' ? 'Error removing area' : 'Error adding area'}. See console for more details.
					</p>
				)}
        </Form>
    );
}
