import { useState, useEffect, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import Form from 'react-bootstrap/Form';
import { Spinner } from 'react-bootstrap';
import { AiOutlineSearch } from 'react-icons/ai';

import AdvancedSelect from '@components/form/advancedSelect';

import { getUserTags } from '@utils/api';

const Wrapper = styled.div`
	background-color: ${props => props.theme.colors.modal};
	border: ${props => `1px solid ${props.theme.colors.border}`};
`;

const StyledSearchLabel = styled.label`
	width: 100%;
	position: relative;

	> div {
		position: relative;
		color: var(--bs-gray-600, #6c757d);
	}

	svg {
		position: absolute;
		left: 8px;
		bottom: 4px;
	}
`;

const StyledSearchInput = styled(Form.Control)`
	background-color: ${props => props.theme.colors.backgroundInput};
	border: 1px solid ${props => props.theme.colors.borderInput};
	color: ${props => props.theme.colors.textColor};
	padding-left: 40px;
	&:focus {
		background-color: ${props => props.theme.colors.modal};
		border: 1px solid hsl(0, 0%, 30%);
		color: ${props => props.theme.colors.textColor};
	}
	&::-webkit-search-cancel-button {
		color: #ff0000;
		background-color: #ff0000; /* Change this to the desired color */
	}
`;

const StyledAdvancedSelect = styled(AdvancedSelect)`
	.react-select {
		&__control {
			padding-top: 0;
			padding-bottom: 0;
		}
	}
`;

const StyledTagsLabel = styled.label`
	width: 100%;
`;

const ProjectsFilter = ({ show = true, currentUser }) => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [tags, setTags] = useState(searchParams?.get('tags')?.split(',') || []);
	const [search, setSearch] = useState(searchParams.get('search') ?? '');

	const timeoutRef = useRef(null);

	const {
		data,  // All the tags that the user has
		isLoading,
		refetch
	  } = useQuery({
		queryKey: ['user_tags'],
		queryFn: async () => await getUserTags(),
		refetchOnWindowFocus: false,
		retry: false,
	});

	const defaultTags = ['Demo'];
	const userTags = [...(data?.tags || []), ...defaultTags];

	const handleSearch = e => {
		const value = e.target.value;

		clearTimeout(timeoutRef.current);
		timeoutRef.current = setTimeout(() => {
			// Send search request after 500ms
			setSearch(value);
		}, 500);
	};

	useEffect(() => {
		if (!tags || search === null) return;

		const query = {};

		if (tags.length > 0) query.tags = tags.join(',');
		if (search) query.search = search;

		setSearchParams(query);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search, tags]);

	useEffect(() => {
		if (!currentUser?.loggedIn || isLoading) return;
		refetch();
	}, [currentUser]);

	if (!show) return null;

	return (
		<Wrapper className="row mb-4 mx-0 p-2 pt-3 rounded">
			<div className="col-4">
				<StyledSearchLabel>
					<span>Filter by project name</span>
					<div>
						<AiOutlineSearch className="m-1 mb-2" />
						<StyledSearchInput
							type="search"
							placeholder="Search..."
							aria-label="Search..."
							name="search"
							defaultValue={searchParams.get('search')}
							onChange={handleSearch}
						/>
					</div>
				</StyledSearchLabel>
			</div>

			<div className="col-4" />

			<div className="col-4">
				<StyledTagsLabel>
					<span>Filter by tags</span>
					{isLoading && (
						<div>
							<Spinner animation="border" variant="light" />
						</div>
					)}
					{!isLoading && (
						<>
							{!userTags || userTags.length === 0 ? (
								<p className="text-muted m-0">
									No tags found. Edit a project to add tags.
								</p>
							) : (
								<StyledAdvancedSelect
									value={tags?.map((tag, index) => {
										return {
											value: `${tag}-${index}`,
											label: tag,
										};
									})}
									options={userTags
										.filter(t => {
											return !tags?.includes(t);
										})
										.map((tag, index) => {
											return {
												value: `${tag}-${index}`,
												label: tag,
											};
										})}
									onChange={val => {
										setTags(() => val?.map(v => v.label));
									}}
									isMulti
								/>
							)}
						</>
					)}
				</StyledTagsLabel>
			</div>
		</Wrapper>
	);
};

export default ProjectsFilter;
