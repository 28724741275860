import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { fileApiUrl } from '@utils/api';
import { getToken } from '@contexts/Token.context';

const StyledImage = styled.img`
	background-color: #000;
	aspect-ratio: 1 / 1;
	width: 50px;
	height: auto;
	margin-bottom: 5px;
`;
const ProjectThumbnail = ({ project_uuid }) => {
	const [imageUrl, setImageUrl] = useState(null);

	// Fetch thumbnail from AWS. Only show if it exists.
	const fetchImageUrl = useCallback(async () => {
		const url = `${fileApiUrl}getfile?key=${project_uuid}/odm_orthophoto/thumbnail.png`;
		const token = getToken();
		const response = await fetch(url, {
			method: 'GET',
			headers: {
				authorization: `Bearer ${token}`,
			},
		});

		if (!response.ok || response.status === 204 || !response.url) return;

		setImageUrl(response.url);

	}, [project_uuid]);

	useEffect(() => {
		fetchImageUrl();
	}, [fetchImageUrl]);

	if (!project_uuid || !imageUrl) {
		return null;
	}

	return <div>{imageUrl && <StyledImage src={imageUrl} />}</div>;
};
export default ProjectThumbnail;
