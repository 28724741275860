import { getToken } from '@contexts/Token.context.jsx';
import { getUserData as refreshToken } from './index.js';

const maxRetries = 2;
const retryDelayFactor = 1000;
const DEPLOYMENT = import.meta.env.MODE;
const baseURLDefault =
	DEPLOYMENT === 'production'
		? `https://s3service.biodroneportal.com/`
		: DEPLOYMENT === 'test'
		? `https://s3servicetest.biodroneportal.com/`
		: //: 'http://localhost:9000/';
		  `https://s3servicetest.biodroneportal.com/`;

export const fileApiUrl = baseURLDefault;

export class Upload {
	chunkSize = 1024 * 1024 * 50; // 50 MB in bytes
	baseURL = baseURLDefault;
	uploadPartStates = [];

	fileRef = null;
	updateProgress = () => {};

	constructor({ fileRef, projectId, updateProgress, chunkSize, baseURL }) {
		this.fileRef = fileRef;
		this.updateProgress = updateProgress;
		this.projectId = projectId;
		this.chunkSize = chunkSize || this.chunkSize;
		this.baseURL = baseURL || this.baseURL;

		for (
			let chunkNumber = 0;
			chunkNumber * this.chunkSize <= fileRef.size;
			chunkNumber++
		) {
			this.uploadPartStates.push({
				PartNumber: chunkNumber + 1,
				startByte: chunkNumber * this.chunkSize,
				endByte: (chunkNumber + 1) * this.chunkSize,
				status: 'waiting',
			});
		}
	}
	// private functions
	#request = async (url, params) => {
		let attemptNumber = 1;
		let resolved = false;

		while (attemptNumber <= maxRetries || resolved === true) {
			try {
				const token = getToken();
				const response = await fetch(`${this.baseURL}${url}`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						authorization: `Bearer ${token}`,
					},
					mode: 'cors',
					body: JSON.stringify(params),
				});

				if (response.ok) {
					const data = await response.json();
					resolved = true;
					return Promise.resolve(data);
				}

				if (response.status === 401) {
					await refreshToken();
				}
			} catch (error) {
				console.error('resuest failed');
				console.info(error);
				if (attemptNumber > maxRetries)
					Promise.reject(
						'Network error: Max number of attempts eexceeded!'
					);
				attemptNumber++;
				await new Promise(resolve =>
					setTimeout(resolve, retryDelayFactor * attemptNumber)
				);
			}
		}
	};

	#startMultipartUpload = async () => {
		try {
			const data = await this.#request(`startMultipartUpload`, {
				fileName: this.fileRef.name,
				projectId: this.projectId,
				size: this.fileRef.size,
			});

			const { key, uploadId } = data;
			this.key = key;
			this.uploadId = uploadId;
		} catch (error) {
			return Promise.reject(error);
		}
	};

	#upload = async (chunkData, url, partNumber) => {
		let attemptNumber = 1;
		let resolved = false;

		while (attemptNumber <= maxRetries) {
			try {
				const partResponse = await fetch(url, {
					method: 'PUT',
					body: chunkData,
					headers: {
						'Content-Type': 'multipart/form-data',
						'Content-Disposition': `attachment; name="file"; filename=${this.fileRef.name}; part=${partNumber}`,
						'Content-Length': chunkData.length,
					},
				});
				if (partResponse.ok) {
					chunkData = null;
					const ETag = partResponse.headers.get('ETag');

					resolved = true;
					return Promise.resolve({
						ETag,
					});
				} else {
					return Promise.reject(partResponse.statusText);
				}
			} catch (error) {
				console.error(error);
				console.info(
					'Retrying upload in ',
					(attemptNumber * retryDelayFactor) / 1000,
					'seconds'
				);
				if (attemptNumber >= maxRetries) return Promise.reject(error);
				await new Promise(resolve =>
					setTimeout(resolve, retryDelayFactor * attemptNumber)
				);
				attemptNumber++;
			}
		}
	};

	#uploadPart = async partIndex => {
		try {
			let partState = this.uploadPartStates[partIndex];
			if (!partState)
				Promise.reject('No state found at index ', partIndex); // check if partState exists

			partState.status = 'pending';

			const { url: uploadUrl } = await this.#request(
				`getUploadPartLink/`,
				{
					partNumber: partState.PartNumber,
					uploadId: this.uploadId,
					key: this.key,
				}
			);

			let chunk = this.fileRef.slice(
				partState.startByte,
				partState.endByte
			);

			const { ETag } = await this.#upload(
				chunk,
				uploadUrl,
				partState.PartNumber
			);
			partState.status = 'completed';
			partState.ETag = ETag;

			chunk = null;

			const completedCount = this.uploadPartStates.reduce(
				(count, part) => {
					if (part.status === 'completed') count++;
					return count;
				},
				0
			);
			const progress =
				(completedCount / this.uploadPartStates.length) * 100;

			this.updateProgress(progress);

			return Promise.resolve();
		} catch (error) {
			return Promise.reject(error);
		}
	};

	#completeMultipartUpload = async () => {
		try {
			const parts = this.uploadPartStates.map(partStatus => {
				return {
					PartNumber: partStatus.PartNumber,
					ETag: partStatus.ETag,
				};
			});
			const response = await this.#request(`endMultipartUpload/`, {
				parts: parts,
				uploadId: this.uploadId,
				key: this.key,
			});
			if (response.ok) {
				return Promise.resolve();
			}
		} catch (error) {
			return Promise.reject(error);
		}
	};

	// Public functions
	start = async () => {
		try {
			await this.#startMultipartUpload();

			/**
			 * Refactor this block so that it allows multiple parts to run in paralell
			 */
			for (let i = 0; i < this.uploadPartStates.length; i++) {
				await this.#uploadPart(i);
			}

			await this.#completeMultipartUpload();
			this.fileRef = null;
			return Promise.resolve();
		} catch (error) {
			console.error(error);
			return Promise.reject(error);
		}
	};
}

export const getSingleImage = async (uri, baseURL = baseURLDefault) => {
	try {
		const token = getToken();
		return await fetch(`${baseURL}${uri}`, {
			method: 'GET',
			headers: {
				authorization: `Bearer ${token}`,
			},
		})
			.then(response => response.json())
			.then(signedUrl => signedUrl.url);
	} catch (err) {
		console.error(err);
		return err.response;
	}
};
