import { useRef } from "react";
import Button from "react-bootstrap/Button";
import { FaSearch } from "react-icons/fa";

import { useProject } from "@contexts/Project.context";
import { useAuth } from "@contexts/User.context";
import { useToast } from "@contexts/Toast.context";
import { setupFreehandDraw, removeFreehandDraw } from "@utils/map/freeHand.draw";
import useNotify from "@components/notifictions/notify";
import { getPolygonSize, postDetectPolygon } from "@api";

/**
 * Component to draw a selection area and run detection on the selected area
 *
 * @returns the button to draw the selection area
 */
const DetectInArea = ({
	isTrained,
}) => {
	const {
		tierTrial,
		trialEnded,
		isEnterprise,
		activeOrgAccess,
		tierPremium,
		userAccess,
		tierPro,
		subscriptionActive
	} = useAuth();

	const {
		project,
		taskId,
		toolBarVisible,
		mapObject,
		isDemo,
		dispatch
	} = useProject();

	const { addToast } = useToast();
	const { checkForJobs } = useNotify();

	const drawRef = useRef(null);

	const initDetectionFlow = () => {

		dispatch({
			type: 'setDialogue',
			payload: {
				header: 'Set a boundary',
				body: 'Please draw a boundary around the region where you wish to generate detections.',
			}
		});
		dispatch({ type: 'setToolBarVisible', payload: false });

		drawSelection();

	};

	const drawSelection = () => {
		// Setup draw interaction
		const { draw, layer } = setupFreehandDraw({
			type: 'Polygon',
			mapRef: mapObject
		});
		drawRef.current = {draw, layer};

		draw.on('drawend', async (e) => {
			const coordinates = e.feature.getGeometry().getCoordinates();

			const polygonSize = await getPolygonSize(coordinates, project?.uuid, taskId);

			if(tierTrial && polygonSize.number_of_tiles > 50){
				window.alert('Trial users can not run detection on large areas. Please select a smaller area.');
				removeFreehandDraw({
					mapRef: mapObject,
					draw,
					layer,
				});
				initDetectionFlow();

			} else{
				dispatch({ type: 'setConfirmModalContent', payload: {
					title: 'Confirm Selection',
					message: (
						<>
							<p>Detecting objects is a resource-intensive process that may take several minutes, depending on the area selected.</p>
							<p>Do you want to run detection in the selected area?</p>
						</>
					),
					onConfirm: () => runPostDetection(coordinates),
					onCancel: () => resetDetectionFlow(),
				} });
			}
		});
	}

	const resetDetectionFlow = () => {
		const { draw, layer } = drawRef.current;

		dispatch({ type: 'setDialogue', payload: null });
		dispatch({ type: 'setToolBarVisible', payload: true });
		removeFreehandDraw({
			mapRef: mapObject,
			draw,
			layer,
		});
	}

	const runPostDetection = (coordinates) => {
		postDetectPolygon(project?.uuid, taskId, coordinates).then(res => {
			const resMessage = 'Detection is running. Depending on the area selected, this may take several minutes. You will be notified when detection is complete.';

			if(res?.warning){
				addToast({
					id: `detection_warning-${new Date().getTime()}`,
					className: 'bg-warning',
					title: `Detection started with warnings:`,
					message: (
						<>
							<p>{res.warning}.</p>
							<p className="mb-0">{resMessage}</p>
						</>
					),
					autohide: false,
				});
			} else {
				addToast({
					id: `detection_success-${new Date().getTime()}`,
					className: 'bg-success',
					title: `Running detection...`,
					message: resMessage,
					autohide: false,
				});
			}

			checkForJobs();
		}).catch(error => {
			addToast({
				id: `detection_error-${new Date().getTime()}`,
				className: 'bg-danger',
				title: `Error Running detection...`,
				message: error?.response?.data?.detail || 'Unknown error running detection.',
				autohide: false,
			});
		}).finally(() => {
			resetDetectionFlow();
		});
	}

	if (!taskId) return null;

    return (
		<Button disabled={!isTrained || !toolBarVisible || isDemo}
			onClick={() => {
				if(tierTrial && trialEnded) {
					alert('Your trial has ended and you can no longer detect objects');
					return;
				}
				else if(tierPremium && !userAccess?.includes("detect")) {
					// Check if userAccess array contains "detect"
					alert('Your user does not have access rights to detect objects');
					return;
				}
				else if(tierPro && !subscriptionActive) {
					alert('Your user needs an active subscription to detect objects');
					return;
				}

				if(isEnterprise) {
					// Check if activeOrgAccess array contains "all" or "detect"
					if(!activeOrgAccess.includes("all") && !activeOrgAccess.includes("detect")) {
						alert('Your user does not have access rights to detect objects');
						return;
					}
				}

				initDetectionFlow();
			}}
			data-tooltip-id="tooltip-toolbar-root"
			data-tooltip-content={tierTrial && trialEnded ? 'Your trial has ended' : 'Detect objects in a selected area'}
			data-tooltip-place="top"
			className="btn-dark secondary"
		>
			<FaSearch className="m-1 mb-2" />
			Detect Objects
		</Button>
    );
};

export default DetectInArea;
