import Container from "react-bootstrap/Container";
import styled from "styled-components";

export const StyledView = styled(Container)`
	color: ${(props) => props.theme.colors.textColor};
	padding-top: 2.5rem;
	padding-bottom: 3rem;

	${p => p.maxwidth && `
		max-width: ${p.maxwidth}px;
	`}
`;

const StyledHeadingWrapper = styled.div`
	display: grid;
	grid-template-columns: auto 1fr;`
;

const View = ({
	title = '',
	maxWidth = null,
	headingComponent = null,
	children
}) => {
	return (
		<StyledView maxwidth={maxWidth}>
			<StyledHeadingWrapper>
				<h1 className="mb-4">{title}</h1>
				{headingComponent ? headingComponent : null}
			</StyledHeadingWrapper>
			{children}
		</StyledView>
	);
};

export default View;
