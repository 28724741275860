import { Polygon } from 'ol/geom';
import { Feature } from 'ol/index';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Stroke, Style } from 'ol/style';

/**
 * Adds a square annotation to the map from a geojson object with the geometry of a square to the map
 * @param {Object} activeAnnotationSquare - the geojson object to add to the map
 * @returns {void}
 */
let annotationSquareSource, annotationSquareLayer;
export const initAnnotationSquare = (activeAnnotationSquare, mapReference) => {
	mapReference.removeLayer(annotationSquareLayer);
	annotationSquareSource = new VectorSource();
	annotationSquareLayer = new VectorLayer({
		renderBuffer: 500,
		source: annotationSquareSource,
		zIndex: 20,
	});

	const annotationSquare = new Feature({
		geometry: new Polygon([
			[
				[
					activeAnnotationSquare.geometry.coordinates[0][0][0],
					activeAnnotationSquare.geometry.coordinates[0][0][1],
				],
				[
					activeAnnotationSquare.geometry.coordinates[0][1][0],
					activeAnnotationSquare.geometry.coordinates[0][1][1],
				],
				[
					activeAnnotationSquare.geometry.coordinates[0][2][0],
					activeAnnotationSquare.geometry.coordinates[0][2][1],
				],
				[
					activeAnnotationSquare.geometry.coordinates[0][3][0],
					activeAnnotationSquare.geometry.coordinates[0][3][1],
				],
				[
					activeAnnotationSquare.geometry.coordinates[0][0][0],
					activeAnnotationSquare.geometry.coordinates[0][0][1],
				],
			],
		]),
	});
	annotationSquare.setStyle(
		new Style({
			stroke: new Stroke({
				color: 'yellow',
				width: 4,
				lineDash: [20, 10],
			}),
			zIndex: 2,
		})
	);

	const shadowAnnotationSquare = new Feature({
		geometry: annotationSquare.getGeometry().clone(),
	});
	shadowAnnotationSquare.setStyle(
		new Style({
			stroke: new Stroke({
				color: 'rgba(0, 0, 0, 0.4)',
				width: 6,
			}),
			zIndex: 1,
		})
	);

	annotationSquareSource.addFeature(shadowAnnotationSquare);
	annotationSquareSource.addFeature(annotationSquare);

	mapReference.addLayer(annotationSquareLayer);

	let squareCenter = annotationSquare
		.getGeometry()
		.getInteriorPoint()
		.getCoordinates();

	mapReference.getView().animate({
		center: squareCenter,
		//zoom: 21.5,
		duration: 300,
	});
};

/**
 * removes the annotation square from the map
 * @returns {void}
 */
export const removeAnnotationSquare = mapReference => {
	mapReference.removeLayer(annotationSquareLayer);
};
