import { Draw, Select } from 'ol/interaction';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import { pointerMoveListener } from './erasorPointer';
import { containsExtent } from 'ol/extent';
import { Fill, Stroke, Style } from 'ol/style';

import _ from 'lodash';

export const addDrawLayer = ({ map, drawType, colorOptions }) => {
	const source = new VectorSource({ wrapX: false });
	const styleFunction = createStyleFunction(colorOptions);

	const layer = new VectorLayer({
		source: source,
		renderBuffer: 500,
		zIndex: 25,
		name: 'drawLayer',
		style: styleFunction,
	});
	map.addLayer(layer);

	const draw = new Draw({
		source: source,
		type: drawType,
		freehand: false,
	});
	map.addInteraction(draw);

	const select = new Select({
		layers: [layer],
		style: function (feature) {
			// Return the original style of the feature
			return feature.getStyle();
		},
	});
	map.addInteraction(select);
	select.setActive(false);

	// Create a pointermove event listener to change the cursor to a trash can
	const pointerMoveRef = evt => {
		pointerMoveListener(evt, map);
	};

	return { layer, source, draw, select, pointerMoveRef };
};

const createStyleFunction = colorOptions => {
	return feature => {
		// Get the classId from the feature
		const classId = feature.get('classId');

		// Return the correct style based on classId and colorOptions
		return getStyleForClassId(colorOptions[classId]); // replace this with your function to get the style for a classId
	};
};

const getStyleForClassId = color => {
	const { r, g, b } = color || { r: 0, g: 225, b: 255 };

	return new Style({
		fill: new Fill({
			color: 'rgba(0, 0, 0, 0.05)',
		}),
		stroke: new Stroke({
			color: `rgb(${r}, ${g}, ${b})`,
			width: 3,
		}),
	});
};

export const removeDrawLayer = (map, drawRef) => {
	const { layer, draw, select, pointerMoveRef } = drawRef || {};

	if (layer) {
		layer.dispose();
		map.removeLayer(layer);
	}

	if (draw) {
		draw.dispose();
		map.removeInteraction(draw);
	}

	if (select) {
		select.dispose();
		map.removeInteraction(select);
	}

	if (pointerMoveRef) {
		map.un('pointermove', pointerMoveRef);
	}
};

// Check if the feature starts inside the image
export const drawStartInteraction = ({ e, layer, draw, classId }) => {
	const feature = e.feature;

	// Only allow drawing inside the image extent
	const startsInside = isFeatureInsideExtent({ feature, layer });
	if (!startsInside) {
		draw.removeLastPoint();
		e.preventDefault();

		return;
	}

	// To add the correct styling based on class
	feature.set('classId', classId);
};

// Save the added feature to the addedAnnotations array
export const drawEndInteracton = ({
	e,
	layer,
	annotationType,
	tile_name,
	addedAnnotations,
	taskId,
	classId,
}) => {
	const currentImageExtent = getCurrentImageExtent(layer);

	const feature = e.feature;
	const data = {
		annotationType: annotationType.toLowerCase(),
		currentImageExtent,
		feature,
		tile_name,
		addedAnnotations,
		taskId,
		classId,
	};

	addAnnotation(data);
};

const addAnnotation = ({
	annotationType,
	currentImageExtent,
	feature,
	tile_name,
	addedAnnotations,
	taskId,
	classId,
}) => {
	if (!tile_name && !feature) return;

	let newAnnotation = {
		crs_coordinates: false,
		uuid: 'unknown',
		task_uuid: taskId,
		annotation_type: annotationType,
		machine_annotation: false,
		deprecated: false,
		human_input: true,
		classid: classId,
		tile_name,
		ol_uid: feature.ol_uid,
	};

	if (annotationType === 'circle') {
		const center = feature.getGeometry().getCenter();
		const pixelCoordinateReversed = [
			(center[0] - currentImageExtent[0]) /
				(currentImageExtent[2] - currentImageExtent[0]),
			1 -
				(center[1] - currentImageExtent[1]) /
					(currentImageExtent[3] - currentImageExtent[1]),
		];

		newAnnotation = {
			...newAnnotation,
			x_center: pixelCoordinateReversed[0],
			y_center: pixelCoordinateReversed[1],
			radius:
				feature.getGeometry().getRadius() /
				(currentImageExtent[2] - currentImageExtent[0]),
		};
	} else if (annotationType === 'polygon') {
		const coordinates = feature.getGeometry().getCoordinates()[0];
		const normalizedCoordinates = coordinates.map(coord => {
			return [
				(coord[0] - currentImageExtent[0]) /
					(currentImageExtent[2] - currentImageExtent[0]),
				1 -
					(coord[1] - currentImageExtent[1]) /
						(currentImageExtent[3] - currentImageExtent[1]),
			];
		});

		newAnnotation = {
			...newAnnotation,
			coordinates: normalizedCoordinates,
		};
	}

	addedAnnotations.push(newAnnotation);
	console.log('addedAnnotations', addedAnnotations);
};

// Delete a feature from the a source
export const deleteAnnotation = ({ addedAnnotations, feature, source }) => {
	// Remove from addedAnnotations if it exists
	_.remove(addedAnnotations, c => c.ol_uid === feature.ol_uid);
	console.log('addedAnnotations', addedAnnotations);

	// Remove from the source
	source.removeFeature(feature);
};

const isFeatureInsideExtent = ({ feature, layer }) => {
	const currentImageExtent = getCurrentImageExtent(layer);

	const geometry = feature.getGeometry();
	const isInside = containsExtent(currentImageExtent, geometry.getExtent());

	return isInside;
};

const getCurrentImageExtent = layer => {
	const currentImageSource = layer.getSource();
	const currentImageExtent = currentImageSource.getImageExtent();

	return currentImageExtent;
};
