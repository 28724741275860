import {
	ACCESS_TOKEN,
	REFRESH_TOKEN,
	getToken,
} from '@contexts/Token.context.jsx';
import { api, deleteCookie } from './index.js';

export const getUserData = async () => {
	const token = getToken();
	if (!token) return null;
	const { data } = await api.get('/users/me');
	return data;
};

export const updateUser = async ({ name }) => {
	const { data } = await api.post('/users/update', { name });
	return data;
};

export const logoutUserOnServer = async () => {
	const { data } = await api.get('/auth/logout');

	if (data?.status !== 'success') {
		console.error('Error logging out user on the server', res);
	}
};

export const deleteUserCookiesFromBrowser = () => {
	deleteCookie(ACCESS_TOKEN);
	deleteCookie(REFRESH_TOKEN);
};

export const getUserTags = async () => {
	const { data } = await api.get('/users/tags');
	return data;
};

export const getUserStorage = async () => {
	const { data } = await api.get('/users/storage_usage');
	return data;
};

export const getUserSubscription = async subscription_id => {
	if (!subscription_id) {
		return new Error('No subscription id provided');
	}

	const { data } = await api.get(`/subscriptions/${subscription_id}`);
	return data;
};
