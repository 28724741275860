import { api } from './index.js';

export const getAreaBalance = async details => {
	const params = {
		user_uuid: details?.user_uuid ?? null,
	};

	const { data } = await api.get('/area/balance', { params });

	return data;
};

export const getAreaTransactions = async (user_uuid = null) => {
	const params = {
		user_uuid,
	};

	const { data } = await api.get('/area/transactions', { params });

	return data;
};

export const getAreaTransactionStatus = async checkout_ref => {
	const { data } = await api.get(`/area/transaction_status/${checkout_ref}`);

	return data;
};

export const failedToGetAreaTransactionStatus = async checkout_ref => {
	const { data } = await api.get(
		`/area/failed_to_get_transaction_status/${checkout_ref}`
	);

	return data;
};

export const useArea = async details => {
	const { amount, note, user_uuid, project_uuid } = details;

	const body = {
		amount,
		note,
		user_uuid,
		project_uuid: project_uuid ?? null,
	};

	const { data } = await api.post('/area/use', body);

	return data;
};

export const adminUseArea = async details => {
	const { amount, note, user_uuid } = details;

	const body = {
		amount,
		note,
		user_uuid,
	};

	const { data } = await api.post('/area/admin_use', body);

	return data;
};

export const adminAddArea = async details => {
	const { amount, note, user_uuid } = details;
	const body = {
		amount,
		note,
		user_uuid,
	};

	const { data } = await api.post('/area/admin_add', body);

	return data;
};

export const updateProjectAreaTransaction = async project_uuid => {
	const { data } = await api.post(
		`/area/update_project_transaction/${project_uuid}`
	);

	return data;
};
