import styled, {css} from 'styled-components';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';

import Button from 'react-bootstrap/Button';

import { getProducts } from '@api/stripe';
import { useAuth } from '@contexts/User.context';

const commonProductTileStyles = css`
	display: block;
	font-size: 1.5em;
	line-height: 1em;
	margin-bottom: 0.75em;
`;

export const StripeProductsButtonGroup = styled.div`
	width: 100%;
	display: grid;
	grid-template-columns: ${props => props.columns || '1fr 1fr 1fr'};
	gap: 10px;

	.btn {
		background-color: ${p => p.theme.colors.backgroundAccentMuted};
		border: 1px solid ${p => p.theme.colors.green};
		border-radius: 3px;
		text-align: left;
		&:hover {
			background-color: ${p => p.theme.colors.green};
		}
		&[aria-disabled='true'] {
			background-color: ${p => p.theme.colors.backgroundAccentMuted};
			color: ${p => p.theme.colors.textAccent};
			opacity: 0.8;
			pointer-events: none;
			cursor: default;
		}
	}
`;

const BuyText = styled.span`
	font-size: 0.875em;
	color: ${p => p.theme.colors.textAccent};
	transition: color 0.2s ease-in-out;

	.btn:not([aria-disabled='true']):hover & {
		color: #ffffff;
	}
`;
const ProductTile = styled.span`
	${commonProductTileStyles}
`;
const ProductText = styled.span`
	display: block;
	font-size: 1rem;
	line-height: 1em;
	margin-bottom: 0.75em;
	color: ${p => p.theme.colors.textAccent};
	.btn:not([aria-disabled='true']):hover & {
		color: #ffffff;
	}
`;
const ProductDisabled = styled.span`
	display: block;
	font-size: 0.9;
	line-height: 1em;
	margin-bottom: 0.75em;
	color: ${p => p.theme.colors.textColor};
`;

const StripeProductsPresentGrid = styled.div`
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	gap: 2rem;
`;

const ProductPresent = styled.div`
	min-width: 300px;
	max-width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border: 1px solid ${p => p.theme.colors.backgroundAccent};
	border-radius: 0.375rem;
	padding: 1rem;
`;

const ProductPresentTile = styled.span`
	${commonProductTileStyles}
	color: ${props => props.theme.colors.green};
`;

const recurringIntervalSwitch = (p) => {
	switch (p.recurring?.interval) {
		case 'day':
			return 'daily';
		case 'week':
			return 'weekly';
		case 'month':
			return 'monthly';
		case 'year':
			return 'yearly';
		default:
			return null
	}
}

/**
 *
 * Component for displaying available products from Stripe for purchase
 */
export default function StripeProducts({
	stripeLookupKeys = null,
	type = 'button',
}) {

	const { currentUser } = useAuth();

	// Fetch products from Stripe
	const { data, isLoading, error } = useQuery({
		queryKey: [
			'stripe_products',
			stripeLookupKeys ?? stripeLookupKeys?.join('-'),
			currentUser?.uuid,
		],
		queryFn: () => getProducts({lookup_keys: stripeLookupKeys, user_id: currentUser?.id}),
		enabled: !!stripeLookupKeys,
	});

	const sortedProducts = data?.products
		? [...data.products].sort((a, b) => a.unit_amount - b.unit_amount)
		: undefined;


	const renderProducts = (sortedProducts, type) => {
		if (type === 'button') {
			return (
				<StripeProductsButtonGroup aria-label="Available products" role="group">
					{sortedProducts.map(p => (
						<StripeProductButton key={p.id} p={p} />
					))}
				</StripeProductsButtonGroup>
			);
		} else {
			return (
				<StripeProductsPresentGrid>
					{sortedProducts.map(p => (
						<StripeProductPresent key={p.id} p={p} />
					))}
				</StripeProductsPresentGrid>
			);
		}
	}

	if (!stripeLookupKeys)
		return <div className="mt-3">Products could not be loaded...</div>;

	return (
		<section className="mt-3">
			{isLoading && <div>Loading products...</div>}

			{error && (
				<div>Could not load products. Error: {error.message}</div>
			)}

			{!isLoading && !error && sortedProducts && (
				<>
					{sortedProducts?.length === 0 ? (
						<div>No products found</div>
					) : (
						renderProducts(sortedProducts, type)
					)}
				</>
			)}
		</section>
	);
}

export const StripeProductButton = ({p, overwriteUrl = null, basic = false}) => {

	const billedText = p.type === 'recurring' ? recurringIntervalSwitch(p) : 'immediately';

	let url = overwriteUrl ?? `/checkout?price_id=${p.id}&return_path=${window.location.pathname}`;
	if (!overwriteUrl && p.type === 'recurring')
		url = url + `&mode=subscription`;

	return (
		<Button as={Link} to={url} className="d-flex flex-column justify-content-between" disabled={p.custom_is_disabled}>
			<div>
				<BuyText>Billed {billedText}</BuyText>
				<ProductTile>
					{p.product_metadata?.name ??
						'Name missing in metadata'}
				</ProductTile>
				{!basic && p.features?.map((f, i) => (
					<ProductText key={`${p.id}-feature-${i}`}>{f.name}</ProductText>
				))}
				{p.custom_is_disabled && <ProductDisabled>{p.custom_disabled_reason}</ProductDisabled>}
			</div>
			<span>
				{Intl.NumberFormat('en-IN', {
					style: 'currency',
					currency: p.currency,
				}).format(p.unit_amount / 100)}
			</span>
		</Button>
	);
}

const StripeProductPresent = ({p}) => {

	const billedText = p.type === 'recurring' ? recurringIntervalSwitch(p) : null;

	return (
		<ProductPresent>
			<div>
				{billedText && <BuyText>Billed {billedText}</BuyText>}
				<ProductPresentTile>
					{p.product_metadata?.name ??
						'Name missing in metadata'}
				</ProductPresentTile>
				{p.features?.map((f, i) => (
					<ProductText key={`${p.id}-feature-${i}`}>{f.name}</ProductText>
				))}
			</div>
			<span>
				{Intl.NumberFormat('en-IN', {
					style: 'currency',
					currency: p.currency,
				}).format(p.unit_amount / 100)}
			</span>
		</ProductPresent>
	);
};
