import View from "../components/view/View";

const PrivacyPolicy = () => {

    return (
        <View title="Privacy Policy">
            <p>The Privacy Statement is about how this website collects and uses visitor information. The statement contains information that you are entitled to when collecting information from our website, and general information about how we treat personal data.</p>

            <p>The legal owner of the website is the processing officer for the processing of personal data. It is voluntary for those who visit the web sites to provide personal information regarding services such as receiving newsletters and using the sharing and tip services. The treatment basis is the consent of the individual, unless otherwise specified.</p>

            <h2>1. Cookies</h2>
            <p>We use cookies for authentication and payment. We do not use cookies for tracking or analytics. We do not share any information with third parties.</p>
            <table className="table">
                <caption>Biodrones cookie management</caption>
                <thead>
                    <tr>
                        <th>Cookie</th>
                        <th>Domain</th>
                        <th>Description</th>
                        <th>Data processor</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>bd_refresh_token</td>
                        <td>biodroneportal.com</td>
                        <td>Session-ID</td>
                        <td>Biodrone</td>
                    </tr>
                    <tr>
                        <td>bd_access_token</td>
                        <td>biodroneportal.com</td>
                        <td>Session-ID</td>
                        <td>Biodrone</td>
                    </tr>
                </tbody>
            </table>

            <h2>2. Registration, form</h2>
            <p>The website may have a form for registration, contact form or other form. These forms are available to the public to perform the tasks they are supposed to do.</p>

            <p>Registration form is for visitors to sign up or register.</p>

            <p>Contact form is for visitors to easily send a message to the website's contact person.</p>

            <p>We ask for the name of the sender and contact information for this. Personal information we receive is not used for purposes other than responding to the inquiry.</p>

            <p>The form is sent as email via Mailgun as a third party solution. The entire submission will be stored at Mailgun for 24 hours. Between 24 hours and 30 days, only mailheader is stored before the submission is deleted after 30 days. The reason for this storage is to confirm whether emails are sent from the website and forwarded to the correct recipient.</p>

            <p>Once the email is received by the recipient, it is up to the recipient to determine the data processing needs of the email.</p>


			<h2>3. Authentication with AWS Cognito</h2>
			<p>We utilize AWS Cognito, a service provided by Amazon Web Services (AWS), for user authentication on our website. When you create an account or log in using our platform, AWS Cognito may collect and process personal information such as your email address, password, and any other information necessary for authentication purposes.</p>

			<p>AWS Cognito may also collect data related to your interactions with our website for authentication and security purposes, such as device information and IP addresses. This information is used to provide and improve our authentication services, enhance security measures, and ensure compliance with legal requirements.</p>

			<p>Please note that AWS Cognito operates independently and has its own privacy practices. We encourage you to review AWS's privacy policy available at <a href="https://aws.amazon.com/privacy/">https://aws.amazon.com/privacy/</a> to understand how they handle your personal information.</p>

			<p>By using our website and authentication services provided by AWS Cognito, you consent to the collection, processing, and sharing of your personal information as described in this privacy policy and in accordance with AWS's privacy practices. If you have any concerns or questions about the use of AWS Cognito for authentication, please contact us.</p>

			<h2>4. Stripe Integration and Data Usage for Identity Verification</h2>
			<p>We use Stripe for identity document verification. Stripe collects identity document images, facial images, ID numbers and addresses as well as advanced fraud signals and information about the devices that connect to its services. Stripe shares this information with us and also uses this information to operate and improve the services it provides, including for fraud detection. You may also choose to allow Stripe to use your data to improve Stripe’s biometric verification technology. You can learn more about Stripe and read its privacy policy at <a href="https://stripe.com/privacy/">https://stripe.com/privacy/</a>.</p>
			<p>Stripe retains a copy of all the data provided as part of a verification. You may also have consented to allow Stripe to use your data to improve their technology. You can delete your information from Stripe’s servers or revoke your consent by visiting <a href="https://support.stripe.com">https://support.stripe.com</a>.</p>
	</View>
);
}

export default PrivacyPolicy;
