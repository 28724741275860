import { useNavigate } from "react-router-dom"
import Button from 'react-bootstrap/Button';
import {TbDrone} from 'react-icons/tb';

import View from "@components/view/View";

const Missing404 = () => {
    const navigate = useNavigate();

    return (
        <View maxWidth={550} title="404: Lost in the digital forest!">
            <p>Let the drone lead you back to the familiar territory of our home page with a single click.</p>
            <Button onClick={() => navigate('/')} variant="success"><TbDrone /> Go Home</Button>
        </View>
    );
}

export default Missing404;