import styled from 'styled-components';
import {AiFillInfoCircle} from 'react-icons/ai';
import CloseButton from 'react-bootstrap/CloseButton';

import { useProject } from '@contexts/Project.context';

const DialogueBox = styled.div`
    position: fixed;
    right: 5px;
    width: calc(100% - 358px);
    background-color: ${(props) => `rgba(${props.theme.colors.backgroundRgb}, 1)`};
    color: ${(props) => props.theme.colors.textColor};
    z-index: 50;
    padding: 20px;
    top: 79px;

    & h2 {
        text-transform: capitalize;
        font-weight: 600;
        display: flex;
        gap: 10px;
    }

    & p {
        margin-bottom: 0;
    }
`;

const DialogueHeader = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
`;

const Dialogue = () => {
    const { dialogue, dispatch } = useProject();

    return (
        dialogue ? (
            <DialogueBox>
                {dialogue.dismissible ? (
                    <DialogueHeader>
                        <h2><AiFillInfoCircle /> <span>{dialogue.header}</span></h2>
                        <CloseButton
							label='Close'
							variant='white'
							onClick={() => dispatch({ type: 'setDialogue', payload: null })}
                        />
                    </DialogueHeader>
                ) : (
                    <h2><AiFillInfoCircle /> <span>{dialogue.header}</span></h2>
                )}

                <p>{dialogue.body}</p>
            </DialogueBox>
        ) : null
    );
}
export default Dialogue;
