import { useState } from 'react';
import { Range } from 'react-range';
import Input from '@components/form/Input';

const RangeSlider = ({label, step, min, max, values, onChange}) => {

	// If any og the values is NaN, set the value to empty string so we don't break the Range component
	values = values.map(val => isNaN(val) ? '' : val);

	const [inputMinValue, setInputMinValue] = useState(values[0]);
	const [inputMaxValue, setInputMaxValue] = useState(values[1]);

	return (
		<div>
			<p className="mb-1">{label}</p>
			<div className="d-flex justify-content-between text-muted small pb-1">
				<span>{min}</span>
				<span>{max}</span>
			</div>
			<Range
				step={step}
				min={min}
				max={max}
				values={values}
				onChange={(val) => {
					onChange(val);
					setInputMinValue(val[0]);
					setInputMaxValue(val[1]);
				}}
				renderTrack={({ props, children }) => (
					<div
						{...props}
						style={{
							...props.style,
							height: '6px',
							backgroundColor: '#c0bebe',
						}}>
						{children}
					</div>
				)}
				renderThumb={({ props }) => (
					<div
						{...props}
						style={{
							...props.style,
							height: '16px',
							width: '16px',
							borderRadius: '50%',
							backgroundColor: '#198754',
							boxShadow: '0px 2px 6px #1c1c1c',
						}}
					/>
				)}
			/>

			<div className="d-flex justify-content-between gap-5 mt-3">
				<Input
					type="number"
					label="Min"
					min={min}
					max={max}
					value={inputMinValue}
					step={step}
					onChange={(event) => {
						const value = parseFloat(event.target.value);
						setInputMinValue(value);
						if( isNaN(value) || value > values[1] || value === values[1]){
							return;
						}

						onChange([value, values[1]]);
					}}
					isInvalid={isNaN(inputMinValue) || inputMinValue > values[1]}
					minwidth={"120px"}
					noWrap
				/>
				<Input
					type="number"
					label="Max"
					min={min}
					max={max}
					value={inputMaxValue}
					step={step}
					onChange={(event) => {
						const value = parseFloat(event.target.value);
						setInputMaxValue(value);
						if(isNaN(value) || value < values[0] || value === values[0]){
							return;
						}

						onChange([values[0], value]);
					}}
					isInvalid={isNaN(inputMaxValue) || inputMaxValue < values[0]}
					minwidth={"120px"}
					noWrap
				/>
			</div>
		</div>
	);
};

export default RangeSlider;
