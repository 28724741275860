import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Input from '@components/form/Input';

import View from '@components/view/View';

import ArchiveProjectDialog from '@components/map/archive/archiveProjectDialog.component';
import ProjectTagsModal from '@components/project/projectTags/projectTagsModal';
import ProjectTags from '@components/project/projectTags/projectTags.component';

import { getProject, updateProject } from '@utils/api';
import { useAuth } from '@contexts/User.context';

const StyledActionsWrapper = styled.div`
	margin-top: calc(var(--bs-gutter-x) * 2);
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

const EditProject = () => {

	const {tierTrial} = useAuth();

	const { project_uuid } = useParams();
	const navigate = useNavigate();

	const {
		data: project,
		isLoading,
		isError,
		refetch
	  } = useQuery({
		queryKey: ['edit_project', project_uuid],
		queryFn: async () => await getProject(project_uuid),
		enabled: !!project_uuid,
	});

	const [projectName, setProjectName] = useState('');
	const [projectDescription, setProjectDescription] = useState('');

	const [showTagsModal, setShowTagsModal] = useState(false);
	const [showArchiveModal, setShowArchiveModal] = useState(false);

	const [alertMessage, setAlertMessage] = useState(null);

	const mutation = useMutation({
        mutationFn: () => updateProject(project_uuid, projectName, projectDescription),
		onSuccess: () => {
			setTimeout(() => {
				setAlertMessage({
					variant: 'success',
					text: 'Project was updated',
				});
			}, 500);
		},
		onError: () => {
			setTimeout(() => {
				setAlertMessage({
					variant: 'danger',
					text: 'Project was not updated. Try again later.',
				});
			}, 500);
		}
    })

	useEffect(() => {
		if(project){
			setProjectName(project.title);
			setProjectDescription(project.description ?? '');
		}
	}, [project]);


	const handleSubmit = async e => {
		e.preventDefault();
		setAlertMessage(null);


		mutation.mutate();

	};

	return (
		<View maxWidth={550} title={project ? `Edit project details for ${project.title}` : 'Error loading project'}>
			{!isLoading && !isError && (
				<>
					{alertMessage && (
						<Alert variant={alertMessage.variant}>
							{alertMessage.text}
						</Alert>
					)}
					<Form onSubmit={handleSubmit}>
						<Input
							label="Project Name"
							type="text"
							placeholder="Project Name"
							name="projectName"
							value={projectName}
							onChange={e => setProjectName(e.target.value)}
						/>
						<Input
							label="Project Description"
							type="text"
							placeholder="Project description"
							name="projectDescription"
							value={projectDescription}
							onChange={e =>
								setProjectDescription(e.target.value)
							}
						/>

						<div className="mt-3">
							<Form.Label className="me-2">
								Project Tags
							</Form.Label>
							<Button
								variant="outline-light"
								size="lg"
								className="px-1 py-0 border-0"
								type="button"
								onClick={() => {
									setAlertMessage(null);
									setShowTagsModal(true);
								}}>
								<AiOutlineEdit aria-hidden="true" />
								<span className="visually-hidden">Edit</span>
							</Button>
							<div className="fs-5">
								<ProjectTags
									projectID={project_uuid}
									tags={project?.tags ?? []}
									align="flex-start"
									showNoResultText={true}
								/>
							</div>
						</div>

						<StyledActionsWrapper>
							<div>
								<Button
									type="button"
									variant="link"
									className="text-light p-0"
									disabled={tierTrial}
									onClick={() =>
										setShowArchiveModal(true)
									}>
									<AiOutlineDelete
										className="m-1 mb-2"
										aria-hidden="true"
									/>
									Delete project
								</Button>
							</div>

							<div>
								<Button
									type="button"
									variant="secondary"
									className="m-1"
									onClick={() => navigate(-1)}>
									Back
								</Button>
								<Button
									type="submit"
									variant="success"
									className="m-1"
									disabled={mutation.isLoading}>
									{mutation.isLoading ? 'Saving...' : 'Save'}
								</Button>
							</div>
						</StyledActionsWrapper>
					</Form>
				</>
			)}

			{showArchiveModal && (
				<ArchiveProjectDialog
					callBack={(success) => {
						setShowArchiveModal(false);
						if(success) navigate(-1);
					}}
					project={{
						id: project_uuid,
						title: projectName,
					}}
				/>
			)}
			{showTagsModal && (
				<ProjectTagsModal
					show={showTagsModal}
					setShow={setShowTagsModal}
					projectId={project_uuid}
					projectTitle={projectName}
					existingTags={project?.tags ?? []}
					callBack={refetch}
				/>
			)}
		</View>
	);
};

export default EditProject;
