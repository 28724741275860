import { useEffect, useState } from "react";
import { GrFormViewHide, GrFormView, GrFormEdit } from "react-icons/gr";
import styled from "styled-components";
import LabelButton from "./labelButton.component";

const CheckboxWrapper = styled.div`
	display: flex;
	align-items: center;

	&.inactive {
		opacity: 0.5;
	}

	&:hover {
		cursor: pointer;
		color: white;
	}
`;

const Check = styled.span`
	display: block;
	position: relative;

	input[type="checkbox"] {
		appearance: none;
		width: 30px;
		height: 25px;
		margin: 0 10px 0 0;
		border: 0;
		padding: 0;
		cursor: pointer;
	}

	.icon {
		display: block;
		cursor: pointer;
		color: ${(props) => props.theme.colors.textColor};
		position: absolute;
		width: 30px;
		padding-right: 10px;
		height: 25px;
		top: 3px;
		left: 0;
		pointer-events: none;
		path {
		stroke: ${(props) => props.theme.colors.textColor};
	}
}
`;

const StyledLabelButton = styled(LabelButton)`
	svg {
		font-size: 1.2em;
		margin-left: 0.5em;
		opacity: 0;
		transition: opacity 0.2s ease-in-out;
	}

	:hover svg {
		opacity: 0.8;
	}
`;


const Checkbox = ( {label, handleCheck, handleUncheck, handleClick, canEdit = true, defaultState = true, layer = null } ) => {
	const [isChecked, setIsChecked] = useState(defaultState);
	
	const Icon = () => {
		const icon = isChecked ? (
		<GrFormView
			value="col"
			className="icon"
		/>
		) : (
		<GrFormViewHide
			className="icon"
		/>
		);
		return icon;
	};

	useEffect(() => {
		if (layer !== null) {

			// Add an event listener to the layer to update the checkbox when the layer visibility changes
			const handleLayerChange = () => {
				const layerVisibility = layer.getVisible() ?? false;
				if (layerVisibility !== isChecked){
					setIsChecked(layerVisibility);
				}
			};

			layer.on('change:visible', handleLayerChange);

			// Clean up function to remove the event listener when the component unmounts
			return () => {
				layer.un('change:visible', handleLayerChange);
				setIsChecked(defaultState);
			};
		}
	}, [layer]);


  return (
		<CheckboxWrapper 
			className={!isChecked ? "inactive" : ""} 
		>
			<Check
			>
			<input type="checkbox" aria-label={`Toggle ${label} layer view`}
				onChange={() => {
					setIsChecked(p => {
						if (!p) {
							handleCheck();
						} else {
							handleUncheck();
						}
						return !p
					});
				}} />
			{<Icon />}
			</Check>
			<StyledLabelButton label={label} handleClick={handleClick}> {canEdit && <GrFormEdit />}</StyledLabelButton>
		</CheckboxWrapper>
	);
};

export default Checkbox;

