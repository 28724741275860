import Alert from '@components/alert/Alert';

const FootprintAlert = ({warning, error}) => {
    if (!warning && !error) return null;

	const variant = !!warning ? 'warning' : (!!error ? 'danger' : '');

    return (
        <Alert variant={variant} heading={ variant === "warning" ? "Dataset warning" : "Dataset error"}>

			{error && <p>{error}</p>}

			{warning && !error && (
				<>
					<p>{warning}</p>

					<p><small>Despite warnings, you can start your project, but consider their implications first.</small></p>
				</>
			)}
		</Alert>
    );
}
export default FootprintAlert;
