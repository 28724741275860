
import { useQuery } from "@tanstack/react-query";

import Card from "react-bootstrap/Card";

import { getUserSubscription, getUserStorage, getBillingPortalUrl } from "@api";
import { useAuth } from "@contexts/User.context";

import AdminView from "@components/view/AdminView";
import StorageStatus from '@components/storage/StorageStatus';
import Loader from '@components/common/Loader';
import Alert from "@components/alert/Alert";
import StripeProducts, { StripeProductsButtonGroup, StripeProductButton } from "@components/products/StripeProducts";
import ProductsFooter from "@components/products/ProductsFooter";

const StorageSubscription = () => {
	const {
		currentUser,
		subscription,
		subscriptionLoading,
		subscriptionError,
	} = useAuth();

	// Get the user's used storage status
	const {
		data: storage,
		error: userStorageError,
		isLoading: userStorageLoading,
	} = useQuery({
		queryKey: ['userStorage', currentUser?.subscription_id],
		queryFn: getUserStorage,
		enabled: !!currentUser?.subscription_id,
	});

	// Create Stripe billing portal URL
	const {
		data: stripeBillingPortalUrl,
		isLoading: stripeBillingPortalUrlLoading,
		isError: stripeBillingPortalUrlError,
	} = useQuery({
		queryKey: ['billingPortalUrl', currentUser?.uuid],
		queryFn: () => getBillingPortalUrl({subscription_lookup_keys: 'small_storage,medium_storage'}),
		enabled: !!currentUser?.uuid,
	});

	const usedStorage = storage?.used ?? null;
	const totalStorage = subscription?.product_metadata?.value ?? null;
	const billingPortalUrl = stripeBillingPortalUrl?.portal_url ?? null;
	const availableSubscriptions = stripeBillingPortalUrl?.available_subscriptions ?? null;

    return (
        <AdminView title="Storage Subscription">
			<p>Used to store files uploaded when creating a project. You must have a storage subscription to use the portal.</p>

			{(subscriptionLoading || userStorageLoading || stripeBillingPortalUrlLoading) ? (
				<Loader message="Loading your subscription status..." />
			) : (
				<>
					{!subscription || subscription.status !== 'active' ? (
						<>
							<Alert variant="danger">You have no active subscription!</Alert>
							<h2 className="mt-5">Start a subscription</h2>
							<StripeProducts
								stripeLookupKeys={['small_storage', 'medium_storage']}
							/>
						</>
					) : (
						<>
							{totalStorage && (usedStorage !== undefined && usedStorage !== null) && (
								<>
									<p><strong>You have an active monthly storage subscription.</strong></p>
									<Card bg="dark" className="border-0 w-50">
										<Card.Body>
											<Card.Text>
												Your storage status
											</Card.Text>
											<StorageStatus totalStorage={totalStorage} usedStorage={usedStorage} />
										</Card.Body>
									</Card>
									{billingPortalUrl && (
										<p className="mt-4 mb-0">
											<a href={billingPortalUrl} rel="noreferrer">
												Manage your current storage subscription in the Stripe billing portal.
											</a>
										</p>
									)}
								</>
							)}

							{availableSubscriptions && (
								<>
									<h2 className="mt-5">Change your subscription</h2>
									{availableSubscriptions.length > 0 ? (
										<>
										<StripeProductsButtonGroup>
											{availableSubscriptions.map(p => {
												return (
													<StripeProductButton key={p.id} p={p} overwriteUrl={p.billing_portal_url} />
												);
											})}
										</StripeProductsButtonGroup>
										<ProductsFooter />
										</>
									) : (
										<p className="mt-3">No suitable subscriptions available. Contact <a href="mailto:post@biodrone.no">post@biodrone.no</a> for assistance.</p>
									)}

								</>
							)}

							{stripeBillingPortalUrlError && (
								<div className="w-50 mt-4">
									<Alert variant="danger">Could not load subscription management. <br />Please contact <a href="mailto:post@biodrone.no">post@biodrone.no</a> if the problem persists.</Alert>
								</div>
							)}
						</>
					)}
				</>
			)}

			{(subscriptionError || userStorageError) && (
				<Alert variant="danger">Could not get your storage status.</Alert>
			)}

        </AdminView>

    );
};
export default StorageSubscription;
