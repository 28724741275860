import AdminView from '@components/view/AdminView';
import AdminProjects from '@components/admin/projects/Projects';

/**
 * Shows a admin table of projects with various filters
 */
const Projects = () => {

	return (
		<AdminView title="Projects">
			<AdminProjects />
		</AdminView>
	);
};
export default Projects;
