import { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import { StyledModal } from '@components/modal/sharedModalStyles';
import AdvancedSelect from '@components/form/advancedSelect';

import {
	addProjectTags,
	deleteProjectTags,
	getUserTags,
} from '@utils/api';

const ProjectTagsModal = ({
	show = false,
	setShow,
	projectId,
	projectTitle,
	existingTags,
	callBack,
}) => {
	const [projectTags, setProjectTags] = useState(existingTags ?? []); // To keep control of the tags that are added or removed

	const {
		data,  // All the tags that the user has
		isLoading,
	  } = useQuery({
		queryKey: ['user_tags'],
		queryFn: async () => await getUserTags(),
		enabled: !!show,
		retry: false,
	});

	const userTags = data?.tags ?? [];
	const availableTags = userTags?.filter(tag => !projectTags?.includes(tag)) ?? []; // The tags that ar not added to the project yet
	const allowCreate = data?.allowCreate; // If the user is allowed to create new tags

	const mutation = useMutation({
        mutationFn: ({add, tags}) => {
			if(add){
				return addProjectTags(projectId, tags);
			} else {
				return deleteProjectTags(projectId, tags);
			}
        },
		onSuccess: () => { 
			resetStates();
		}
    });

	const saveTags = async e => {
		e.preventDefault();
		let allowClose = true;

		// Extract the tags that are not in the existing tags
		const newTags = projectTags.filter(tag => !existingTags.includes(tag));
		if (newTags?.length > 0) {
			try {
				await mutation.mutateAsync({add: true, tags: newTags});
			} catch (error) {
				console.warn('Could not add new tags to project', error);
				allowClose = false;
			} 
		}

		//Extract the tags removed from existing tags
		const removedTags = existingTags.filter(
			tag => !projectTags.includes(tag)
		);
		if (removedTags?.length > 0) {
			try {
				await mutation.mutateAsync({add: false, tags: removedTags});
			} catch (error) {
				console.warn('Could not delete tags from project', error);
				allowClose = false;
			} 
		}

		if (allowClose) {
			resetStates();
			if (callBack) {
				callBack();
			}
		}
	};

	const resetStates = async () => {
		setProjectTags(null);
		setShow(false);
	};

	if (!show) return null;

	return (
		<StyledModal
			variant="dark"
			show={show}
			centered={true}
			onHide={() => {
				resetStates();
			}}>
			<Modal.Header closeButton closeVariant="white">
				<Modal.Title>
					Edit tags for <em>{projectTitle}</em>
				</Modal.Title>
			</Modal.Header>
			<Form onSubmit={saveTags}>
				<Modal.Body>
					{mutation.isError ? (
						<div className="alert alert-warning">
							<p className="mb-0">Error: {mutation.error?.message ?? 'Unknown'}</p>
						</div>
					) : (
						<div className="mt-3 mb-4">
							{isLoading ? <p>Loading tags...</p> : 
							<AdvancedSelect
								id="project-tags-modal-select"
								label="Project Tags"
								defaultValue={projectTags?.map(
									(tag, index) => {
										return {
											value: `${tag}-${index}`,
											label: tag,
										};
									}
								)}
								options={availableTags?.map((tag, index) => {
									return {
										value: `${tag}-${index}`,
										label: tag,
									};
								})}
								noOptionsMessage={() =>
									allowCreate ? 'Start typing to add a tag' : 'No tags found'
								}
								formatCreateLabel={inputValue =>
									`Click to create new tag "${inputValue}"`
								}
								onChange={e => {
									setProjectTags(e.map(tag => tag.label));
								}}
								isMulti
								creatable={allowCreate}
							/>}
						</div>
					)}
				</Modal.Body>

				<Modal.Footer>
					<Button variant="secondary" onClick={() => resetStates()}>
						Cancel
					</Button>
					<Button
						type="submit"
						variant="success"
						className="m-1"
						disabled={isLoading || mutation.isLoading}>
						Save
					</Button>
				</Modal.Footer>
			</Form>
		</StyledModal>
	);
};

export default ProjectTagsModal;
