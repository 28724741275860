import { useMutation } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { requestPasswordReset } from '@api';

import Loader from '@components/common/Loader';
import Alert from '@components/alert/Alert';
import View from '@components/view/View';
import Input from '@components/form/Input';

export default function RequestPasswordReset() {
	const passwordResetMutation = useMutation({
        mutationFn: (email) => requestPasswordReset(email),
    })

	const handleSubmit = async event => {
		event.preventDefault();

		console.log('requesting password reset');

		const email = event.target.email.value;
		passwordResetMutation.mutate(email);
	}

	return (
		<View maxWidth={550} title="Reset your password">
			{passwordResetMutation?.isLoading ? (
				<Loader />
			) : (
				<>
					{passwordResetMutation?.isError && (
						<Alert variant="danger">
								<p className="mb-0" >
								Could not send password reset email. Did you type the correct email?
								</p>
						</Alert>
					)}

					{passwordResetMutation?.isSuccess ? (
						<Alert variant="success">
							<p>
								{passwordResetMutation?.data?.message
									? <>{passwordResetMutation?.data?.message}</>
									: ''
								}
							</p>
							<p className="mb-0">Check your email and follow the instructions.</p>
						</Alert>
					) : (
						<>
							<Form onSubmit={handleSubmit}>
								<Input
									type="email"
									name="email"
									label="Email address"
									placeholder="Enter email"
									required
								/>

								<Button
									variant="success"
									type="submit"
									>
									Request Password Reset
								</Button>
							</Form>
							<p className="mt-5">
								Remember your old password?{' '}
								<Link to="/login">Log in</Link>
							</p>
						</>
					)}
				</>
			)}
		</View>
	);
}
