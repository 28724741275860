import { useEffect, useMemo, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';

import { useProject } from '@contexts/Project.context';
import { getHexagonFeatures } from '@api';
import Checkbox from '../sidebars/sidebarElements/checkbox.component';
import {
	getHexagonOpacity,
	hexagonStyle,
} from '@utils/map/hexagon.layer';
import { addHexagonInteraction } from '../sidebars/HexagonInfo';

const HexagonLayer = ({
	hexagonColor,
	makeHexagonLayer,
	setModifyHexagonMode,
}) => {
	const {
		mapObject,
		hexagonLayer,
		project,
		taskId,
		hexagonSize,
		dispatch,
	} = useProject();

	const {data} = useQuery({
		queryKey: ["hexagon_features", project.uuid, taskId, hexagonSize],
		queryFn: () => getHexagonFeatures(project.uuid, taskId, hexagonSize),
		enabled: !!project?.uuid && !!taskId && !!mapObject,
		refetchOnWindowFocus: false,
        retry: false,
	});

	const hexagonInteractionAdded = useRef(false);

	const { hexagons } = useMemo(() => {
		if (!data?.hexagon_data) {
			return { hexagons: [] };
		}

		let hexagons = []; // Initialize hexagons as an empty array

		if (!!data?.hexagon_data) {

			let maxCount = 0;

			Object.keys(data.hexagon_data).forEach(key => {
				const hexagon = {...data.hexagon_data[key]};
				hexagon.id = key;

				if (hexagon.metadata.count > maxCount) {
					maxCount = hexagon.metadata.count;
				}

				hexagons.push(hexagon);
			});

			hexagons.map(hexagon => {
				hexagon.metadata.heatIndicator = (
					hexagon.metadata.count / maxCount
				).toFixed(2);
				Object.keys(hexagon.detections).forEach(key => {
					hexagon.detections[key].name = key;
				});

				return hexagon;
			});
		}

		return { hexagons };
	}, [data]);

	useEffect(() => {
		if (hexagons && hexagons.length > 0) {
			const layer = makeHexagonLayer(
				mapObject,
				hexagons,
				hexagonColor
			);

			addHexagonInteraction(layer, mapObject, dispatch, hexagonInteractionAdded);

			dispatch({ type: 'setHexagonLayer', payload: layer});
			dispatch({ type: 'setHexagonInfo', payload: data });
		} else {
			if (mapObject && hexagonLayer) {

				mapObject.removeLayer(hexagonLayer);

				hexagonLayer.dispose();

				if(hexagonInteractionAdded.current){
					mapObject.removeInteraction(hexagonInteractionAdded.current);
					hexagonInteractionAdded.current = false;
				}

				dispatch({ type: 'setHexagonLayer', payload: null });
				dispatch({ type: 'setHexagonInfo', payload: null });
				dispatch({ type: 'setSelectedHexagon', payload: null });
			}
		}
	}, [hexagons]);

	useEffect(() => {
		hexagonLayer
			?.getSource()
			?.getFeatures()
			?.forEach(feature => {
				const heatIndicator =
					feature.getProperties()?.data?.metadata?.heatIndicator;
				const hexagonOpacity = getHexagonOpacity(heatIndicator);
				const style = hexagonStyle(hexagonColor.color, hexagonOpacity);
				feature.setStyle(style);
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hexagonColor?.color]);

	if(!hexagonLayer) return null;

	return (
		<>
			<div id="hexagonLayer">
				<Checkbox
					label={hexagonLayer.get('name')}
					handleClick={setModifyHexagonMode}
					handleCheck={() => hexagonLayer.setVisible(true)}
					handleUncheck={() => hexagonLayer.setVisible(false)}
				/>
			</div>
		</>
	);
};

export default HexagonLayer;
