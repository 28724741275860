import styled from 'styled-components';
import ProgressBar from 'react-bootstrap/ProgressBar';

import { convertArea } from '@utils/helpers';

const TypeColor = styled.span`
    background-color: ${p => p.variant ? `rgb(var(--bs-${p.variant}-rgb))` : `var(--bs-secondary-bg)`};
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: block;
    border: 1px solid rgba(255,255,255,0.1);
`;

const ProgressDescription = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    line-height: 1em;
`;

const ProgressDescriptionItem = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const AreaStatus = ({available, toUse}) => {

    if(!available) return null;

	const variant = available < 0 || (toUse && toUse > available) ? 'danger' : 'warning';

	const progressNow = available < 0 ? 100 : (toUse && (toUse / available) * 100);

    return (
        <div>
            <ProgressBar>
                {progressNow && <ProgressBar now={progressNow} variant={variant} key="1" />}
            </ProgressBar>
            <ProgressDescription className="small">
                {toUse &&
                    <ProgressDescriptionItem>
                        <TypeColor variant="warning" />
                        <span><strong>~ {convertArea(toUse)}</strong> hectares to be used</span>
                    </ProgressDescriptionItem>
                }

                <ProgressDescriptionItem>
                    <TypeColor variant={available < 0 ? 'danger' : null} />
                    <span><strong>{convertArea(available)}</strong> hectares available</span>
                </ProgressDescriptionItem>
            </ProgressDescription>
        </div>
    );

}
export default AreaStatus;
