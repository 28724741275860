import { createContext, useContext, useState } from 'react';
import styled from 'styled-components';
import { Toast, ToastContainer } from 'react-bootstrap';

const StyledToast = styled(Toast)`
    --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 0.95);
    --bs-success-rgb: 5, 27, 17;
    --bs-danger-rgb: 44, 11, 14;

    padding: 1rem;
    
    &.bg-success{
        color: #75b798;
        --bs-toast-border-color: var(--bs-success-border-subtle);
    }
    &.bg-danger{
        color: #ea868f;
        --bs-toast-border-color: var(--bs-danger-border-subtle);
    }
    &.bg-warning{
        color: ${props => props.theme.colors.background};
        .btn-close{
            filter: none;
        }
    }

    .toast-header{
        background: none;
        border: 0;
        color: inherit;
    }

    .toast-body{
        padding-top: 0;
    }
    .btn-close{
        color: inherit;
    }
`;

// Context
const ToastContext = createContext();

// Hook to use the context
const useToast = () => {
    const context = useContext(ToastContext);
    if (!context) {
        throw new Error('useToast must be used within a ToastProvider');
    }
    return context;
};

// Provider for the context
export const ToastProvider = ({ children }) => {
    const [toasts, setToasts] = useState([]);

    const addToast = (options) => {
        const newToast = { options };
        setToasts((prevToasts) => [...prevToasts, newToast]);
    };

    const removeToast = (id) => {
        setToasts((prevToasts) => prevToasts.filter((toast) => toast.options.id !== id));
    };

  return (
    <ToastContext.Provider value={{ addToast, removeToast }}>
        {children}
        <ToastContainer position="top-end" containerPosition="fixed" className="p-3">
            {toasts.map((toast) => (
                <StyledToast
                    key={toast.options.id}
                    onClose={() => removeToast(toast.options.id)}
                    animation={true}
                    delay={10000}
                    autohide
                    {...toast.options}
                >
                    <Toast.Header>
                        <strong className="me-auto">{toast.options.title ?? ''}</strong>
                    </Toast.Header>
                    {toast.options.message && (
                        <Toast.Body>{toast.options.message}</Toast.Body>
                    )}
                </StyledToast>
            ))}
        </ToastContainer>
    </ToastContext.Provider>
  );
};

// Export the context and hooks for use in other components
export { ToastContext, useToast };