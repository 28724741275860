import { intersects } from 'ol/extent';
import GeoJSON from 'ol/format/GeoJSON.js';

export let activeSquareRef = null;
export let annotationClassId = null;

// function that returns true if the point is inside the square using black magic
export const isPointInsideSquare = (point, square) => {
	const squareCoordinates = square.geometry.coordinates[0];
	const x = point[0];
	const y = point[1];

	const x1 = squareCoordinates[0][0];
	const y1 = squareCoordinates[0][1];
	const x2 = squareCoordinates[1][0];
	const y2 = squareCoordinates[1][1];
	const x3 = squareCoordinates[2][0];
	const y3 = squareCoordinates[2][1];
	const x4 = squareCoordinates[3][0];
	const y4 = squareCoordinates[3][1];

	const d1 = (x - x1) * (y2 - y1) - (y - y1) * (x2 - x1);
	const d2 = (x - x2) * (y3 - y2) - (y - y2) * (x3 - x2);
	const d3 = (x - x3) * (y4 - y3) - (y - y3) * (x4 - x3);
	const d4 = (x - x4) * (y1 - y4) - (y - y4) * (x1 - x4);

	const hasNeg = d1 < 0 || d2 < 0 || d3 < 0 || d4 < 0;
	const hasPos = d1 > 0 || d2 > 0 || d3 > 0 || d4 > 0;

	return !(hasNeg && hasPos);
};

// Function that returns true if the point is overlapping the square
// @TODO: This could propably replace isPointInsideSquare
export const isPointOverlappingSquare = (point, square) => {
	// Convert square to feature
	const squareFeature = new GeoJSON().readFeature(square);

	// Get bounding boxes of geometries
	const extent1 = point.getGeometry()?.getExtent();
	const extent2 = squareFeature.getGeometry()?.getExtent();

	// Check if bounding boxes intersect
	const overlapping = extent1 && extent2 && intersects(extent1, extent2);
	return overlapping;
};

export const setSquareBoundary = activeSquare => {
	if (activeSquare !== undefined) {
		activeSquareRef = activeSquare;
	}
	activeSquareRef = activeSquare;
};

export const addAnnotationId = classId => {
	annotationClassId = classId;
};
